import { Heading, HStack, Icon, Pressable } from '@gluestack-ui/themed';
import { Icons, useAuth } from '@tonic/central-specialties-ui-themed';

export const LogoutButton = () => {
  const { logout } = useAuth();

  return (
    <Pressable
      w="$full"
      mb="$5"
      onPress={logout}
      sx={{ ':hover': { bg: '$gray200' } }}
    >
      <HStack w="$full" h="$16" space="md" px="$10" alignItems="center">
        <Icon as={Icons.LogOut} size="xl" />
        <Heading size="xl">Log Out</Heading>
      </HStack>
    </Pressable>
  );
};
