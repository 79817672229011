import logoAndName from '../../assets/brand/csi-logo-name-horizontal.svg';
import { LogoutButton } from './LogoutButton.tsx';
import { NavLink } from 'react-router-dom';
import {
  Heading,
  HStack,
  Icon,
  Pressable,
  useToken,
  VStack,
} from '@gluestack-ui/themed';
import { FeatureCategory } from '@tonic/central-specialties-utils';
import {
  IconComponent,
  Icons,
  IndicatorDot,
  Spinner,
  useSectionsAvailable,
} from '@tonic/central-specialties-ui-themed';
import {
  notificationHookMap,
  UseNotificationsHookData,
} from './notificationHooks.ts';

type NavItemAppearance = {
  label: string;
  icon: IconComponent;
};

const sectionNavStyleMap: {
  [Key in FeatureCategory | 'Profile']: NavItemAppearance;
} = {
  Repair: {
    label: 'Repair requests',
    icon: Icons.Tool,
  },
  Parts: {
    label: 'Part requests',
    icon: Icons.Hexagon,
  },
  Profile: {
    label: 'My profile',
    icon: Icons.User,
  },
  Time: {
    label: 'Time cards',
    icon: Icons.Clock,
  },
  Manage: {
    label: 'Manage',
    icon: Icons.Layers,
  },
  Users: {
    label: 'Users',
    icon: Icons.Users,
  },
  Truck: {
    label: 'Truck requests',
    icon: Icons.Truck,
  },
  Schedule: {
    label: 'Schedule',
    icon: Icons.Calendar,
  },
};

export const SidebarNav = () => {
  const sectionsAvailable = useSectionsAvailable();
  const activeItemColor = useToken('colors', 'primary100');

  return (
    <VStack
      h="$full"
      w="$70"
      bg="$gray100"
      alignItems="center"
      justifyContent="space-between"
      space="4xl"
      pt="$8"
    >
      <VStack space="4xl" alignItems="center">
        <img src={logoAndName} alt="Central Specialties, Inc." width="90%" />
        <VStack w="$full">
          {sectionsAvailable.map((sectionName) => (
            <NavItem
              key={sectionName + '_NavItem'}
              path={sectionName}
              {...sectionNavStyleMap[sectionName]}
              activeColor={activeItemColor}
              useItemNotificationsHook={
                sectionName in notificationHookMap
                  ? notificationHookMap[sectionName]
                  : () => []
              }
            />
          ))}
          <NavItem
            path="Profile"
            activeColor={activeItemColor}
            {...sectionNavStyleMap.Profile}
          />
        </VStack>
      </VStack>
      <LogoutButton />
    </VStack>
  );
};

interface NavItemProps extends NavItemAppearance {
  path: FeatureCategory | 'Profile';
  activeColor: string;
  useItemNotificationsHook?: () => UseNotificationsHookData;
}

const NavItem = ({
  path,
  activeColor,
  icon,
  label,
  useItemNotificationsHook = () => ({ notifications: [] }),
}: NavItemProps) => {
  const { notifications, isPending: notificationsPending } =
    useItemNotificationsHook();

  return (
    <Pressable w="$full" sx={{ ':hover': { bg: '$gray200' } }}>
      <NavLink
        to={path}
        style={({ isActive }) => ({
          width: '100%',
          textDecoration: 'none',
          userSelect: 'none',
          background: isActive ? activeColor : 'transparent',
        })}
        draggable={false}
      >
        <HStack
          w="$full"
          h="$18"
          space="md"
          py="$3"
          pl="$6"
          pr="$2"
          alignItems="center"
          justifyContent="space-between"
        >
          <HStack h="$18" space="md" alignItems="center">
            <Icon size="xl" as={icon} />
            <Heading size="xl">{label}</Heading>
          </HStack>

          <HStack>
            {notificationsPending === true && <Spinner />}
            {!notificationsPending &&
              !!notifications &&
              notifications.map((dotProps, index) => (
                <IndicatorDot
                  key={'notificationDot:' + index}
                  size="xl"
                  {...dotProps}
                />
              ))}
          </HStack>
        </HStack>
      </NavLink>
    </Pressable>
  );
};
