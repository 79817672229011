import React from 'react';
import {
  Box,
  ButtonAsync,
  Center,
  useAlert,
} from '@tonic/central-specialties-ui-themed';
import { useMutation } from '@tanstack/react-query';
import { DB } from '@tonic/central-specialties-utils';
import {
  AlertDialog,
  AlertDialogBackdrop,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  Button,
  ButtonText,
  Text,
} from '@gluestack-ui/themed';

interface ArchiveJobNumbersDialogProps {
  isOpen: boolean;
  onClose: () => void;
  jobNumber: string;
  onSuccess?: () => void;
  toggleArchive: boolean;
}

export const ArchiveJobNumbersDialog = ({
  isOpen,
  onClose,
  jobNumber,
  onSuccess,
  toggleArchive,
}: ArchiveJobNumbersDialogProps) => {
  const { alert } = useAlert();

  const {
    mutateAsync: archiveSelectedJobNumber,
    isPending: archiveSelectedJobNumberPending,
  } = useMutation({
    mutationFn: () =>
      DB.POST('/jobs/{id}/archive-status', {
        params: {
          path: {
            id: jobNumber,
          },
        },
        body: {
          isArchived: toggleArchive,
        },
      }).then(({ response }) => {
        if (response.ok) {
          alert({
            status: 'success',
            message: `Success! Ticket ${toggleArchive ? 'archived' : 'unarchived'}`,
            dismissible: true,
            timeout: 5000,
          });
          onSuccess && onSuccess();
        } else {
          alert({
            status: 'error',
            message: 'Something went wrong, could not archive this ticket',
            timeout: 10000,
          });
        }
        onClose();
      }),
  });

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} size="sm">
      <AlertDialogBackdrop />
      <AlertDialogContent py="$6" gap="$8">
        <AlertDialogBody>
          <Center w="$full">
            <Box w="$48">
              <Text bold size="lg" textAlign="center">
                {`Are you sure you want to ${toggleArchive ? 'archive' : 'unarchive'} this ticket?`}
              </Text>
            </Box>
          </Center>
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="space-evenly" gap="$4">
          <Button size="lg" variant="outline" onPress={onClose} w={150}>
            <ButtonText>Cancel</ButtonText>
          </Button>
          <ButtonAsync
            size="lg"
            pending={archiveSelectedJobNumberPending}
            onPress={archiveSelectedJobNumber}
            w={150}
          >
            <ButtonText>{`${toggleArchive ? 'Archive' : 'Unarchive'}`}</ButtonText>
          </ButtonAsync>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
