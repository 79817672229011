import { Center, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import {
  Card,
  type CardProps,
  Icons,
} from '@tonic/central-specialties-ui-themed';
import {
  decimalHoursToTimeString,
  Schemas,
} from '@tonic/central-specialties-utils';
import { TimeCard } from '../../routes/root/TimeCardsPage/TimeCardsPage.tsx';

export type TimeCardDaySummary = Schemas['TimeCardUserDaySummary'];

interface TimeCardCardProps extends CardProps {
  data: TimeCard;
}

const roundToNextQuarterHour = (hour: number): number => {
  const quarterHour = 0.25;
  const quarters = Math.ceil(hour / quarterHour);

  return quarters * quarterHour;
};

export const TimeCardCard = ({ data, ...cardProps }: TimeCardCardProps) => {
  const roundTotalHours = roundToNextQuarterHour(data.hours);
  const hoursString = decimalHoursToTimeString(roundTotalHours, false);

  return (
    <Card w="$full" {...cardProps}>
      <HStack flex={1} w="$full">
        <VStack flex={1} space="xs">
          <Text size="xl" color="$textNormal" fontWeight="500">
            {data.fullName}
          </Text>
          <Text size="xl" color="$textNormal">
            {hoursString} hrs
          </Text>
        </VStack>
        {data.hasMessages && (
          <Center>
            <Icon as={Icons.MessageCircle} size="xl" color="$textSubtle" />
          </Center>
        )}
      </HStack>
    </Card>
  );
};
