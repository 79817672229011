import { Spinner, VStack } from '@tonic/central-specialties-ui-themed';
import logoAndName from '../../assets/brand/csi-logo-name-horizontal.svg';

export const LoadingLogoAndSpinner = () => (
  <VStack space="4xl">
    <img
      src={logoAndName as string}
      alt="Central Specialties, Inc."
      style={{ maxWidth: '90%' }}
      width={320}
    />
    <Spinner size={60} />
  </VStack>
);
