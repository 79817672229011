import React from 'react';
import {
  ButtonAsync,
  Center,
  Icons,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import {
  Button,
  ButtonIcon,
  ButtonText,
  HStack,
  Icon,
  Text,
} from '@gluestack-ui/themed';
import { UserForm } from './UserForm.tsx';
import { Schemas } from '@tonic/central-specialties-utils';

interface DisabledUserFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  goBack: () => void;
  disabledUser: Schemas['UserModel'];
  onReinstateSuccess?: () => void;
}

export const DisabledUserFormModal = ({
  isOpen,
  onClose,
  goBack,
  disabledUser,
  onReinstateSuccess,
}: DisabledUserFormModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBackdrop />
      <ModalContent
        borderRadius={0}
        maxHeight="80%"
        w="$96"
        width="60%"
        maxWidth={1000}
        minWidth={500}
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
          pr="$6"
        >
          <HStack
            w="$full"
            h="$full"
            justifyContent="space-between"
            alignItems="center"
          >
            <HStack flex={1}>
              <Button gap="$2" onPress={goBack}>
                <ButtonIcon as={Icons.ChevronLeft} />
                <ButtonText>Back</ButtonText>
              </Button>
            </HStack>
            <Center flex={1}>
              <Text color="$white" bold size="lg">
                {disabledUser.fullName}
              </Text>
            </Center>
            <HStack flex={1} justifyContent="flex-end">
              <ModalCloseButton
                w="$8"
                justifyContent="center"
                alignItems="center"
              >
                <Icon color="$white" size="xl" as={Icons.X} />
              </ModalCloseButton>
            </HStack>
          </HStack>
        </ModalHeader>
        <UserForm
          user={disabledUser}
          onSuccess={onReinstateSuccess}
          formProps={{
            Container: ({ children }) => (
              <ModalBody w="$full">
                <Center flex={1} px="$32" py="$10">
                  <VStack w="$full" maxWidth={600} minWidth={300} space="xl">
                    {children}
                  </VStack>
                </Center>
              </ModalBody>
            ),
            SubmitButton: ({ submit, pending, disabled }) => (
              <ModalFooter bg="$white" hardShadow={2}>
                <HStack w="$full" space="2xl" justifyContent="flex-end">
                  <Button size="lg" w="$32" variant="outline" onPress={onClose}>
                    <ButtonText>Cancel</ButtonText>
                  </Button>
                  <ButtonAsync
                    size="lg"
                    w="$32"
                    variant="solid"
                    onPress={submit}
                    pending={pending}
                    isDisabled={disabled}
                  >
                    <ButtonText>Reinstate user</ButtonText>
                  </ButtonAsync>
                </HStack>
              </ModalFooter>
            ),
          }}
        />
        {/*<ModalBody*/}
        {/*  w="$full"*/}
        {/*  showsHorizontalScrollIndicator={false}*/}
        {/*  showsVerticalScrollIndicator={false}*/}
        {/*  // alignItems="center"*/}
        {/*>*/}
        {/*  <Center flex={1} px="$32" py="$10">*/}
        {/*    <UserForm*/}
        {/*      // TODO: Undo this once we have disabled users to test*/}
        {/*      user={{ ...disabledUser, isDisabled: true }}*/}
        {/*      formProps={{ submitButtonText: 'Reinstate user' }}*/}
        {/*    />*/}
        {/*  </Center>*/}
        {/*</ModalBody>*/}
      </ModalContent>
    </Modal>
  );
};
