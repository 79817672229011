export const extractImageDataFromUrl = async (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    // Fetch the image as a blob from the blob URL
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Could not get image data from URL');
        }
        return response.blob();
      })
      .then((blob) => {
        // Use FileReader to read the blob as a base64-encoded string
        const reader = new FileReader();
        reader.onloadend = () => {
          // Extract the base64 data from the result
          const result = reader.result as string;
          const base64data = result.split(',')[1]; // Remove the "data:image/jpeg;base64," part
          resolve(base64data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
