import React, { useEffect } from 'react';
import { VStack } from '@tonic/central-specialties-ui-themed';
import { GoogleMap, Marker } from '@react-google-maps/api';

export type LatLong = {
  lat: number;
  lng: number;
};

export interface MapViewProps {
  center: LatLong;
  markers?: LatLong[];
  zoom?: number;
  onLoad?: (map: google.maps.Map) => void;
  id?: string;
  containerStyles?: React.ComponentProps<typeof VStack>;
  setLatLong?: (latLong: LatLong) => void;
  readonly?: boolean;
}

export const MapView = ({
  center,
  markers = [],
  zoom = 7,
  onLoad,
  id = 'address',
  containerStyles,
  setLatLong,
  readonly,
}: MapViewProps) => {
  const mapRef = React.useRef<google.maps.Map>(null);

  useEffect(() => {
    if (mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) =>
        bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng)),
      );
      mapRef.current.fitBounds(bounds);
      mapRef.current.setOptions({
        streetViewControl: false,
        controlSize: 25,
        gestureHandling: 'cooperative',
        zoom: 9,
      });
      if (onLoad) {
        onLoad(mapRef.current);
      }
    }
  }, [center, markers, onLoad]);

  return (
    <GoogleMap
      center={center}
      onLoad={(map) => {
        mapRef.current = map;
      }}
      onDblClick={(e) => {
        if (setLatLong) {
          setLatLong({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        }
      }}
      mapContainerStyle={{
        height: 250,
        ...containerStyles,
      }}
      id={id}
      zoom={zoom}
    >
      {markers.map((marker, index) => (
        <Marker
          draggable={!readonly}
          key={`${marker.lat}${marker.lng}${index}`}
          onDragEnd={(e) => {
            if (readonly) return;
            if (setLatLong) {
              setLatLong({ lat: e.latLng.lat(), lng: e.latLng.lng() });
            }
          }}
          position={marker}
        />
      ))}
    </GoogleMap>
  );
};
