import {
  ButtonAsync,
  Center,
  Checkbox,
  CheckboxIndicator,
  CheckboxIcon,
  CheckIcon,
  CheckboxLabel,
  DB,
  Icons,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Schemas,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { Button, ButtonText, HStack, Icon, Text } from '@gluestack-ui/themed';
import { TimeCardEntryForm } from './TimeCardEntryForm.tsx';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { SubmitDayModal } from './SubmitDayModal.tsx';

export interface NewTimeCardEntryModalProps {
  isOpen: boolean;
  onClose: () => void;
  targetDate: Date;
  refetch?: () => void;
}

export const NewTimeCardEntryModal = ({
  isOpen,
  onClose,
  targetDate,
  refetch,
}: NewTimeCardEntryModalProps) => {
  const [isAdminSubmitting, setIsAdminSubmitting] = useState(false);
  const [showAdditionalQuestions, setShowAdditionalQuestions] = useState(false);
  const [newEntryJob, setNewEntryJob] = useState<Schemas['JobResponse']>(null);
  const [newEntryJobId, setNewEntryJobId] = useState<string>('');
  const [newEntryEmployeeId, setNewEntryEmployee] = useState<string>('');
  const handleIsAdminSubmit = useCallback(() => {
    setIsAdminSubmitting((prev: boolean) => !prev);
  }, [setIsAdminSubmitting]);

  const handleSubmitModal = useCallback(() => {
    setShowAdditionalQuestions(false);
  }, []);

  const handleShowAdditionalQuestions = useCallback(() => {
    setShowAdditionalQuestions((prev) => !prev);
  }, [setShowAdditionalQuestions]);

  useEffect(() => {
    if (!newEntryJobId) {
      return;
    }

    DB.GET('/jobs/{id}', {
      params: { path: { id: newEntryJobId } },
    }).then((res: { data: Schemas['JobResponse'] }) => {
      setNewEntryJob(res.data);
    });
  }, [newEntryJobId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBackdrop />
      <ModalContent
        borderRadius={0}
        maxHeight="80%"
        w="$96"
        width="60%"
        maxWidth={1000}
        minWidth={500}
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
          pl="$10"
          pr="$6"
        >
          <Text color="$white" size="lg">
            New time entry - {moment(targetDate).format('MMMM DD, YYYY')}
          </Text>
          <ModalCloseButton w="$8" justifyContent="center" alignItems="center">
            <Icon color="$white" size="xl" as={Icons.X} />
          </ModalCloseButton>
        </ModalHeader>
        <TimeCardEntryForm
          targetDate={targetDate}
          isAdminSubmitting={isAdminSubmitting}
          setNewEntryJob={setNewEntryJobId}
          setNewEntryEmployee={setNewEntryEmployee}
          onSuccess={() => {
            refetch && refetch();
            onClose();
          }}
          formProps={{
            Container: ({ children }) => (
              <ModalBody w="$full">
                <Center flex={1} px="$32" py="$10">
                  <VStack w="$full" maxWidth={600} minWidth={300} space="xl">
                    {children}
                  </VStack>
                </Center>
              </ModalBody>
            ),
            SubmitButton: ({ submit, pending, disabled }) => (
              <ModalFooter bg="$white" hardShadow="2">
                <HStack w="$full" space="2xl" justifyContent="flex-end">
                  <Checkbox
                    key={`supervisor_approval_checkbox`}
                    isChecked={isAdminSubmitting}
                    defaultIsChecked={isAdminSubmitting}
                    value={isAdminSubmitting ? 'Yes' : 'No'}
                    size="lg"
                    aria-label={'Admin Submitting Time checkbox'}
                    onChange={handleIsAdminSubmit}
                  >
                    <CheckboxIndicator mr="$2">
                      <CheckboxIcon as={CheckIcon} />
                    </CheckboxIndicator>
                    <CheckboxLabel>Admin Submitting Time</CheckboxLabel>
                  </Checkbox>
                  <Button size="lg" w="$32" variant="outline" onPress={onClose}>
                    <ButtonText>Cancel</ButtonText>
                  </Button>
                  <ButtonAsync
                    size="lg"
                    w="$32"
                    variant="solid"
                    onPress={
                      isAdminSubmitting ? handleShowAdditionalQuestions : submit
                    }
                    pending={pending}
                    isDisabled={disabled}
                  >
                    <ButtonText>Submit</ButtonText>
                  </ButtonAsync>
                </HStack>
                {showAdditionalQuestions && (
                  <SubmitDayModal
                    isOpen={showAdditionalQuestions}
                    employeeId={newEntryEmployeeId}
                    date={targetDate?.toISOString() ?? ''}
                    onClose={handleSubmitModal}
                    onSuccess={async () => {
                      await submit();
                    }}
                    isRainDay={true}
                    job={newEntryJob}
                    disabled={!newEntryJob}
                    refetch={refetch}
                  />
                )}
              </ModalFooter>
            ),
          }}
        />
      </ModalContent>
    </Modal>
  );
};
