import ReactNativeCalendarStrip from 'react-native-calendar-strip';
import { Icon, useToken } from '@gluestack-ui/themed';
import { Icons, VStack } from '@tonic/central-specialties-ui-themed';
import {
  CustomCalendarStripDay,
  IDayComponentProps,
} from './CustomCalendarStripDay/CustomCalendarStripDay.tsx';
import { Moment } from 'moment/moment.js';
import moment from 'moment';

interface DateSelectStripProps {
  date: Moment;
  onDateSelected: (date: Moment) => void;
  dateCounts: Record<string, number>;
  updateDateRange: (start: Moment, end: Moment) => void;
}

export const DateSelectStrip = ({
  date,
  onDateSelected,
  dateCounts = {},
  updateDateRange,
}: DateSelectStripProps) => {
  const primaryRed700 = useToken('colors', 'primary700');
  const calendarKey = Object.values(dateCounts).join('');
  return (
    <VStack w="$full">
      <ReactNativeCalendarStrip
        key={calendarKey}
        onDateSelected={onDateSelected}
        onWeekChanged={updateDateRange}
        selectedDate={date}
        headerText={date.format('dddd, MMMM DD')}
        style={[{ height: 165 }]} // prevent shift caused by vanishing when key changes
        // innerStyle={[]}
        calendarHeaderStyle={{
          fontFamily: 'Arial',
          fontSize: 20,
          fontWeight: '700',
          backgroundColor: primaryRed700,
          width: '100%',
          // height: 60,
          padding: 18,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          // position: 'relative',
          borderRadius: 5,
        }}
        calendarAnimation={{ type: 'parallel', duration: 200 }}
        calendarHeaderPosition={'below'}
        // calendarHeaderFormat={'dddd, MMMM DD'}
        shouldAllowFontScaling={false}
        leftSelector={<Icon mr="$5" as={Icons.ChevronLeft} />}
        rightSelector={<Icon ml="$5" as={Icons.ChevronRight} />}
        dayContainerStyle={{
          // prevent shifting on select
          borderTopColor: 'transparent',
          borderTopWidth: 2,
        }}
        highlightDateContainerStyle={{
          borderTopColor: primaryRed700,
          borderColor: primaryRed700,
          borderWidth: 2,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomWidth: 0,
        }}
        dayComponent={(props: IDayComponentProps) => {
          const stripDay = props.date.toISOString();
          const numUncompleted =
            dateCounts[moment(stripDay).format('YYYY-MM-DD')];
          const loading = numUncompleted === undefined;
          const { key, ...restProps } = props;
          return (
            <CustomCalendarStripDay
              key={key ?? stripDay}
              numUncompleted={numUncompleted || 0}
              loading={loading}
              {...restProps}
            />
          );
        }}
        // scrollable
        // scrollerPaging
        // maxDayComponentSize={110}
        // minDayComponentSize={110}
        // dayComponentHeight={110}
        startingDate={date.clone().startOf('week')}
        useIsoWeekday={false}
      ></ReactNativeCalendarStrip>
    </VStack>
  );
};
