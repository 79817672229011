import { useQuery } from '@tanstack/react-query';
import { DB, Schemas } from '@tonic/central-specialties-utils';

interface UseFormEquipmentOptionsData {
  equipmentOptions: Schemas['EquipmentItemResponse'][] | null;
  isPending: boolean;
  error: Error | null;
}

export const useFormEquipmentOptions = (): UseFormEquipmentOptionsData => {
  const {
    data: equipmentOptions,
    isPending,
    error,
  } = useQuery({
    queryKey: ['equipmentOptions'],
    queryFn: async () =>
      DB.GET('/equipment').then(
        (res: { data: Schemas['EquipmentListResponse'] }) => res.data.equipment,
      ),
  });

  return {
    equipmentOptions,
    isPending,
    error,
  };
};
