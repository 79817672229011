import React from 'react';
import { Input, InputField, InputIcon, InputSlot } from '@gluestack-ui/themed';
import { Icons } from '@tonic/central-specialties-ui-themed';

interface SearchBarProps extends Partial<React.ComponentProps<typeof Input>> {
  onChangeText: (text: string) => void;
  value?: string;
  inputFieldProps?: Partial<React.ComponentProps<typeof InputField>>;
  inputSlotProps?: Partial<React.ComponentProps<typeof InputSlot>>;
  inputIconProps?: Partial<React.ComponentProps<typeof InputIcon>>;
}

export const SearchBar = ({
  onChangeText,
  value,
  inputFieldProps,
  inputSlotProps,
  inputIconProps,
  ...inputProps
}: SearchBarProps) => {
  return (
    <Input
      size="sm"
      sx={{ borderWidth: 1, borderColor: '$gray300' }}
      {...inputProps}
    >
      <InputField
        onChangeText={onChangeText}
        value={value}
        placeholder="Search..."
        sx={{ borderWidth: 0 }}
        {...inputFieldProps}
      />
      <InputSlot bg="$white" pr="$1.5" {...inputSlotProps}>
        <InputIcon
          as={Icons.Search}
          size="lg"
          color="$textNormal"
          {...inputIconProps}
        />
      </InputSlot>
    </Input>
  );
};
