import React from 'react';
import {
  ButtonAsync,
  Center,
  Icons,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { Button, ButtonText, HStack, Icon, Text } from '@gluestack-ui/themed';
import { UserForm } from './UserForm.tsx';

interface NewUserFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const NewUserFormModal = ({
  isOpen,
  onClose,
  onSuccess,
}: NewUserFormModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBackdrop />
      <ModalContent
        borderRadius={0}
        maxHeight="80%"
        w="$96"
        width="60%"
        maxWidth={1000}
        minWidth={500}
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
          pl="$10"
          pr="$6"
        >
          <Text color="$white" size="lg">
            New User
          </Text>
          <ModalCloseButton w="$8" justifyContent="center" alignItems="center">
            <Icon color="$white" size="xl" as={Icons.X} />
          </ModalCloseButton>
        </ModalHeader>
        <UserForm
          onSuccess={() => {
            onSuccess && onSuccess();
            onClose();
          }}
          formProps={{
            Container: ({ children }) => (
              <ModalBody w="$full">
                <Center flex={1} px="$32" py="$10">
                  <VStack w="$full" maxWidth={600} minWidth={300} space="xl">
                    {children}
                  </VStack>
                </Center>
              </ModalBody>
            ),
            SubmitButton: ({ submit, pending, disabled }) => (
              <ModalFooter bg="$white" hardShadow="2">
                <HStack w="$full" space="2xl" justifyContent="flex-end">
                  <Button size="lg" w="$32" variant="outline" onPress={onClose}>
                    <ButtonText>Cancel</ButtonText>
                  </Button>
                  <ButtonAsync
                    size="lg"
                    w="$32"
                    variant="solid"
                    onPress={submit}
                    pending={pending}
                    isDisabled={disabled}
                  >
                    <ButtonText>Add user</ButtonText>
                  </ButtonAsync>
                </HStack>
              </ModalFooter>
            ),
          }}
        />
      </ModalContent>
    </Modal>
  );
};
