import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import * as ReactRouterDom from 'react-router-dom';
import {
  CentralSpecialtiesUIProvider,
  RoutingLibProvider,
  UserProvider,
  AlertContextProvider,
} from '@tonic/central-specialties-ui-themed';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AlertWrapper } from './components/general/AlertWrapper.tsx';
import { DB } from '@tonic/central-specialties-utils';

const queryClient = new QueryClient();

// Definitely not monkey-patching global... nothing to see here
global.process = {
  env: {
    SERVER_ENV: import.meta.env['DEV'] ? 'development' : 'production',
  },
};

if (!import.meta.env['DEV']) {
  DB.setBaseUrl('https://central-specialties-prod.azurewebsites.net/');
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactRouterDom.BrowserRouter>
        <RoutingLibProvider
          // @ts-expect-error - react-router-dom doesn't line up with react-router exactly, but the parts we need do
          reactRouterLib={ReactRouterDom}
        >
          <UserProvider localStorageManager={localStorage}>
            <AlertContextProvider>
              <CentralSpecialtiesUIProvider>
                <AlertWrapper>
                  <App />
                </AlertWrapper>
              </CentralSpecialtiesUIProvider>
            </AlertContextProvider>
          </UserProvider>
        </RoutingLibProvider>
      </ReactRouterDom.BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);
