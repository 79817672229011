import React from 'react';
import { Center, VStack } from '@tonic/central-specialties-ui-themed';
import { PageToolBar } from './PageToolBar.tsx';

interface PageWrapperProps {
  ToolbarContent: React.ReactNode;
}

export const PageWrapper = ({
  children,
  ToolbarContent,
}: React.PropsWithChildren<PageWrapperProps>) => {
  return (
    <VStack w="$full" flex={1}>
      <PageToolBar>{ToolbarContent}</PageToolBar>
      <Center flex={1}>{children}</Center>
    </VStack>
  );
};
