import React, { useCallback, useMemo, useState } from 'react';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import {
  Button,
  ButtonIcon,
  ButtonText,
  Center,
  HStack,
  Icon,
  ScrollView,
  Text,
} from '@gluestack-ui/themed';
import { LoadingLogoAndSpinner } from '../../../components/general/LoadingLogoAndSpinner.tsx';
import { Icons } from '@tonic/central-specialties-ui-themed';
import { PageWrapper } from '../../../components/general/PageWrapper.tsx';
import { EntityCardColumnsView } from '../../../components/general/EntityCardExplorer/EntityCardColumnsView/EntityCardColumnsView.tsx';
import { PartsRequestCard } from '../../../components/general/cards/PartsRequestCard.tsx';
import { PartsRequestSummaryModal } from './PartsRequestSummaryModal/PartsRequestSummaryModal.tsx';
import { PartsRequestFormModal } from '../../../components/general/Form/specific/PartsRequestForm/PartsRequestFormModal.tsx';
import { ArchivedPartsRequestsModal } from './ArchivedPartsRequestsModal/ArchivedPartsRequestsModal.tsx';
import { useQuery } from '@tanstack/react-query';
import {
  FilterButton,
  FiltersData,
} from '../../../components/general/filtering/FilterButton.tsx';
import { downloadObjectsAsCSV } from '../../../utils/downloadObjectsAsCSV.ts';

const priorityOrder = ['Critical', 'High', 'Medium', 'Low', 'Winter'];

const filtersData: FiltersData<Schemas['PartRequestTicketResponse']> = {
  'Job Number': {
    path: ['job', 'number'],
    formatOptions: 'noNull',
  },
  'Parts assignee': {
    path: ['assignedTo', 'fullName'],
    formatOptions: 'noNull',
  },
  'Equipment Type': {
    path: ['equipment', 'description'],
  },
  'Equipment Number': {
    path: ['equipment', 'number'],
    formatOptions: 'noNull',
  },
  Employee: {
    path: ['createdBy', 'fullName'],
  },
  'Request #': {
    path: ['requestNumber'],
    formatOptions: 'noNull',
  },
  Status: {
    path: ['status'],
  },
  Priority: {
    path: ['priority'],
    sortOptions: 'nosort',
  },
};

export const PartsPage = () => {
  const [selectedPartsRequest, setSelectedPartsRequest] = useState<
    Schemas['PartRequestTicketResponse'] | null
  >(null);
  const [showPartsRequestFormModal, setShowPartsRequestFormModal] =
    useState(false);
  const [showArchivedTicketsModal, setShowArchivedTicketsModal] =
    useState(false);
  const [filteredPartsRequests, setFilteredPartsRequests] = useState<
    Schemas['PartRequestTicketResponse'][]
  >([]);

  const {
    data: partsRequestsResponse,
    isPending: partsRequestsPending,
    error: partsRequestsError,
    refetch: refetchPartsRequests,
  } = useQuery({
    queryKey: ['partsRequests'],
    queryFn: async () =>
      await DB.GET('/part-requests').then(
        (res: { data: Schemas['PartRequestTicketListResponse'] }) =>
          res.data.partRequests,
      ),
  });

  const { Active: partsRequests, Archived: archivedPartsRequests } =
    useMemo(() => {
      if (partsRequestsResponse) {
        // sort data by priority first, then createdAt
        const data = [...partsRequestsResponse];
        data.sort((a, b) => {
          const aPriority = priorityOrder.indexOf(a.priority);
          const bPriority = priorityOrder.indexOf(b.priority);
          if (aPriority < bPriority) {
            return -1;
          }
          if (aPriority > bPriority) {
            return 1;
          }
          if (aPriority === bPriority) {
            return a.createdAt > b.createdAt ? -1 : 1;
          }
        });
        return data.reduce(
          (acc, val) => {
            if (val.status === 'Archived') {
              acc.Archived.push(val);
            } else {
              acc.Active.push(val);
            }
            return acc;
          },
          { Archived: [], Active: [] },
        );
      }
      return { Active: [], Archived: [] };
    }, [partsRequestsResponse]);

  const exportToJSON = useCallback(() => {
    const name = 'PartsRequests_' + new Date().toISOString();
    downloadObjectsAsCSV(partsRequests, name);
  }, [partsRequests]);

  if (partsRequestsPending) {
    return (
      <Center w="$full" h="$full">
        <LoadingLogoAndSpinner />
      </Center>
    );
  }

  if (partsRequestsError) {
    return (
      <Center w="$full" h="$full" gap="$6">
        <Icon as={Icons.AlertCircle} color="$error500" w="$16" h="$16" />
        <Text size="lg" color="$error500">
          There was an error retrieving parts requests.
        </Text>
      </Center>
    );
  }

  return (
    <PageWrapper
      ToolbarContent={
        <HStack flex={1} justifyContent="space-between">
          <HStack space="xl" alignItems="center">
            <Button
              size="lg"
              onPress={() => setShowPartsRequestFormModal(true)}
            >
              <ButtonIcon as={Icons.Plus} />
              <ButtonText>Create Ticket</ButtonText>
            </Button>
            <Button size="lg" variant="outline" onPress={exportToJSON}>
              <ButtonIcon as={Icons.Download} />
              <ButtonText>Export</ButtonText>
            </Button>
          </HStack>

          <HStack space="xl" alignItems="center">
            <Button
              size="lg"
              variant="ghost"
              onPress={() => setShowArchivedTicketsModal(true)}
            >
              <ButtonIcon as={Icons.Archive} />
              <ButtonText>Archived Tickets</ButtonText>
            </Button>

            <FilterButton
              data={partsRequests}
              setFilteredData={setFilteredPartsRequests}
              filtersData={filtersData}
            />
          </HStack>
        </HStack>
      }
    >
      <HStack w="$full" h="$full" px="$5" pt="$4">
        <ScrollView
          horizontal
          flex={1}
          h="$full"
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'space-between',
          }}
          pr="$3"
        >
          <EntityCardColumnsView
            data={filteredPartsRequests}
            groupByPath="status"
            columnGroupings={[
              { value: 'New', indicatorDotVariant: 'neutral' },
              {
                value: 'Assigned',
                label: 'Assigned/In Progress',
                indicatorDotVariant: 'neutral',
              },
              { value: 'Ordered', indicatorDotVariant: 'neutral' },
              {
                value: 'PartiallyFulfilled',
                label: 'Partially Filled',
                indicatorDotVariant: 'neutral',
              },
              {
                value: 'Delivered',
                label: 'Fulfilled',
                indicatorDotVariant: 'neutral',
              },
            ]}
            CardComponent={({
              data,
            }: {
              data: Schemas['PartRequestResponse'];
            }) => (
              <PartsRequestCard
                partsRequest={data}
                onPress={(pr) => setSelectedPartsRequest(pr)}
              />
            )}
            columnContainerProps={{ minWidth: '$70' }}
          />
        </ScrollView>
      </HStack>

      {!selectedPartsRequest && showArchivedTicketsModal && (
        <ArchivedPartsRequestsModal
          isOpen={showArchivedTicketsModal}
          onClose={() => setShowArchivedTicketsModal(false)}
          onSelectTicket={(ticket) => {
            setShowPartsRequestFormModal(true);
            setSelectedPartsRequest(ticket);
          }}
          // TODO: Filter only archived once we have some
          partsRequests={archivedPartsRequests}
        />
      )}

      {showPartsRequestFormModal && (
        <PartsRequestFormModal
          isOpen={showPartsRequestFormModal}
          onClose={() => {
            setShowArchivedTicketsModal(false);
            setShowPartsRequestFormModal(false);
            setSelectedPartsRequest(null);
          }}
          partsRequest={selectedPartsRequest}
          refetch={refetchPartsRequests}
        />
      )}

      {!!selectedPartsRequest && !showPartsRequestFormModal && (
        <PartsRequestSummaryModal
          partsRequest={selectedPartsRequest}
          isOpen={true}
          onClose={() => setSelectedPartsRequest(null)}
          onPressEdit={() => setShowPartsRequestFormModal(true)}
          refetch={refetchPartsRequests}
        />
      )}
    </PageWrapper>
  );
};
