import React from 'react';
import { HStack } from '@gluestack-ui/themed';

interface PageToolBarProps extends React.ComponentProps<typeof HStack> {}

export const PageToolBar = ({ children, ...hStackProps }: PageToolBarProps) => {
  return (
    <HStack
      justifyContent="space-between"
      alignItems="center"
      w="$full"
      h="$20"
      bg="$gray100"
      px="$6"
      py="$4"
      {...hStackProps}
    >
      {children}
    </HStack>
  );
};
