import React, { useCallback, useEffect, useMemo } from 'react';
import { Form, FormFieldsObject } from '../../Form.tsx';
import { EquipmentModel } from '../../../../../routes/root/ManagePage/ManagePage.tsx';
import { DB, type Schemas } from '@tonic/central-specialties-utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Center,
  Spinner,
  useAlert,
} from '@tonic/central-specialties-ui-themed';
import { Text } from '@gluestack-ui/themed';

interface EquipmentNumberFormProps {
  equipmentNumberData?: EquipmentModel;
  onSuccess?: () => void;
  formProps?: Partial<React.ComponentProps<typeof Form>>;
}

export const EquipmentNumberForm = ({
  equipmentNumberData,
  onSuccess,
  formProps,
}: EquipmentNumberFormProps) => {
  const { alert } = useAlert();
  const mode: 'create' | 'edit' = equipmentNumberData ? 'edit' : 'create';

  ///-----------------------------------------------------------------------
  // FETCH RELEVANT DATA NOT PRESENT IN ENTITY (if edit mode)
  ///-----------------------------------------------------------------------

  const {
    data: associatedLaborCodes,
    isPending: associatedLaborCodesPending,
    error: associatedLaborCodesFetchError,
    refetch: refetchAssociatedLaborCodes,
  } = useQuery({
    queryKey: ['associatedLaborCodes', equipmentNumberData?.id],
    queryFn: () =>
      DB.GET(`/equipment/{equipmentId}/labor-codes`, {
        params: { path: { equipmentId: equipmentNumberData?.id } },
      }).then(
        (res: { data: Schemas['JobCodesByTypeResponse'] }) =>
          res.data.laborCodes,
      ),
    enabled: !!equipmentNumberData?.id,
  });
  useEffect(() => {
    console.log('associatedLaborCodes', associatedLaborCodes);
  }, [associatedLaborCodes]);

  ///-----------------------------------------------------------------------
  // FETCH FORM OPTIONS
  ///-----------------------------------------------------------------------

  // Labor codes
  const {
    data: laborCodeOptions,
    isPending: laborCodeOptionsPending,
    error: laborCodeOptionsFetchError,
  } = useQuery({
    queryKey: ['laborCodes'],
    queryFn: () =>
      DB.GET('/job-codes').then(
        (res: { data: Schemas['JobCodesByTypeResponse'] }) =>
          res.data.laborCodes,
      ),
  });

  ///-----------------------------------------------------------------------
  // MUTATION REQUESTS
  ///-----------------------------------------------------------------------

  // CREATE
  const { mutateAsync: createEquipmentNumber } = useMutation({
    mutationFn: (data: Schemas['EquipmentRequest']) =>
      DB.POST('/equipment', { body: data }),
  });

  // UPDATE BASE ENTITY
  const { mutateAsync: updateEquipmentNumber } = useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: string;
      data: Schemas['EquipmentRequest'];
    }) => DB.PUT('/equipment/{id}', { params: { path: { id } }, body: data }),
  });

  // SET/UPDATE ASSOCIATED LABOR CODES
  const { mutateAsync: updateAssociatedLaborCodes } = useMutation({
    mutationFn: ({
      equipmentId,
      laborCodeIds,
    }: {
      equipmentId: string;
      laborCodeIds: string[];
    }) =>
      DB.POST('/equipment/{equipmentId}/labor-codes', {
        params: {
          path: {
            equipmentId,
          },
        },
        body: {
          laborCodeIds,
        },
      }),
  });

  const formFields: FormFieldsObject = useMemo(
    () => ({
      equipmentNumber: {
        type: 'Text',
        dataFieldKey: 'equipmentNumber',
        label: 'Equipment number',
        required: true,
      },
      description: {
        type: 'Text',
        dataFieldKey: 'description',
        label: 'Equipment description',
        required: true,
        validator: (value: string) =>
          value.length < 3 ? 'Description must be at least 3 characters' : null,
      },
      laborCodes: {
        type: 'CheckboxGroup',
        dataFieldKey: 'laborCodes',
        label: 'Labor codes',
        options:
          !!laborCodeOptions && laborCodeOptions?.length
            ? Object.fromEntries(
                laborCodeOptions.map(
                  (lcObj: Schemas['JobCodeItemResponse']) => [
                    `${lcObj.code} - ${lcObj.description}`,
                    lcObj.id,
                  ],
                ),
              )
            : [],
        selectedLabelModifier: (label: string) => label.split(' - ')[0],
        checkboxGroupProps: {
          flexDirection: 'column',
          flexWrap: 'nowrap',
          maxHeight: '51%',
          paddingBottom: 8,
        },
      },
      notes: {
        dataFieldKey: 'notes',
        type: 'Text',
        label: 'Notes',
        multiline: true,
      },
    }),
    [laborCodeOptions],
  );

  const defaultValues: Schemas['EquipmentRequest'] = useMemo(
    () => ({
      equipmentNumber: equipmentNumberData?.number || '',
      description: equipmentNumberData?.description || '',
      laborCodes: associatedLaborCodes
        ? associatedLaborCodes.map((lc) => lc.id)
        : [],
      notes: equipmentNumberData?.notes || '',
    }),
    [equipmentNumberData, associatedLaborCodes],
  );

  const checkCanSubmit = (formData: typeof defaultValues): boolean =>
    Object.entries(formData).some(
      ([key, value]) => value !== defaultValues[key],
    );

  const submit = useCallback(
    async (formData: Schemas['EquipmentRequest']) => {
      // labor codes are updated separately
      const { laborCodes, equipmentNumber, description, notes } = formData;

      const payload: Schemas['EquipmentRequest'] = {
        number: equipmentNumber,
        description,
        notes,
      };

      try {
        // creating a new equpimentNumber
        if (mode === 'create') {
          const res = await createEquipmentNumber(payload);
          if (res.error) {
            throw res.error;
          }
          const newEquipmentNumber = res.data;
          if (laborCodes.length > 0) {
            await updateAssociatedLaborCodes({
              equipmentId: newEquipmentNumber.id,
              laborCodeIds: laborCodes,
            });
            refetchAssociatedLaborCodes();
          }
        }
        // Updating an existing equipment number
        else {
          const res = await updateEquipmentNumber({
            id: equipmentNumberData!.id,
            data: payload,
          });
          if (res.error) {
            throw res.error;
          }
          const existingAssociatedLaborCodeIds: string[] =
            associatedLaborCodes.map((lc) => lc.id);
          const needsToUpdateLaborCodes =
            laborCodes.length !== associatedLaborCodes.length ||
            !laborCodes.every((lcId: string) =>
              existingAssociatedLaborCodeIds.includes(lcId),
            );
          console.log(' needsToUpdateLaborCodes: ', needsToUpdateLaborCodes);
          if (needsToUpdateLaborCodes) {
            console.log('calling update with', {
              id: equipmentNumberData!.id,
              laborCodes,
            });
            const res = await updateAssociatedLaborCodes({
              equipmentId: equipmentNumberData!.id,
              laborCodeIds: laborCodes,
            });
            if (res.error) {
              throw res.error;
            }
            console.log('update labor codes res: ', res);
            refetchAssociatedLaborCodes();
          }
        }
        alert({
          status: 'success',
          message: `Equipment number ${mode === 'edit' ? 'updated' : 'created'} successfully.`,
          timeout: 5000,
        });
        onSuccess && onSuccess();
      } catch (e) {
        alert({
          status: 'error',
          message: `Error ${mode === 'edit' ? 'updating' : 'creating'} equipment number.`,
          timeout: 10000,
        });
      }
    },
    [
      updateEquipmentNumber,
      createEquipmentNumber,
      associatedLaborCodes,
      updateAssociatedLaborCodes,
      alert,
      onSuccess,
      equipmentNumberData,
      refetchAssociatedLaborCodes,
    ],
  );

  if (
    laborCodeOptionsPending ||
    (mode === 'edit' && associatedLaborCodesPending)
  ) {
    return (
      <Center h="$full" minHeight="$96" flex={1}>
        <Spinner />
      </Center>
    );
  }

  if (
    laborCodeOptionsFetchError ||
    (mode === 'edit' && associatedLaborCodesFetchError)
  ) {
    return (
      <Center>
        <Text color="$errorText">
          Error loading labor codes. Please try again later.
        </Text>
      </Center>
    );
  }
  return (
    <Form
      fields={formFields}
      formData={defaultValues}
      onSubmit={submit}
      checkCanSubmit={checkCanSubmit}
      submitButtonText={mode === 'edit' ? 'Save changes' : 'Create'}
      {...formProps}
    />
  );
};
