import { Card, CardProps, VStack } from '@tonic/central-specialties-ui-themed';
import { Text } from '@gluestack-ui/themed';
import React from 'react';
import { UserModel } from '../../../routes/root/UsersPage/UsersPage.tsx';

interface UserCardProps extends CardProps {
  user: UserModel;
  onPress: () => void;
  isSelected: boolean;
}

export const UserCard = ({
  user,
  onPress,
  isSelected = false,
  ...cardProps
}: UserCardProps) => {
  return (
    <Card
      onPress={onPress}
      borderWidth={2}
      hardShadow="1"
      minWidth="$72"
      borderColor={isSelected ? '$primary600' : 'transparent'}
      {...cardProps}
    >
      <VStack gap="$0.5" flex={1}>
        <Text bold>{user.fullName}</Text>
        <Text>{user.employeeNumber}</Text>
      </VStack>
    </Card>
  );
};
