import {
  ArrayDataUtility,
  DataStructureBase,
  DeepDataValue,
  DeepPath,
} from '@tonic/central-specialties-utils';
import {
  Center,
  Text,
  IndicatorDotVariant,
} from '@tonic/central-specialties-ui-themed';
import React from 'react';
import { Divider, HStack } from '@gluestack-ui/themed';
import {
  EntityCardColumn,
  EntityCardColumnProps,
} from './EntityCardColumn/EntityCardColumn.tsx';

interface EntityGroupData<
  T extends DataStructureBase,
  Path extends DeepPath<T> = DeepPath<T>,
> {
  label?: string;
  value: DeepDataValue<T, { path: Path }>;
  indicatorDotVariant?: IndicatorDotVariant;
}

interface ManualEntityGroupData<T extends DataStructureBase> {
  label: string;
  data: T[];
  indicatorDotVariant?: IndicatorDotVariant;
}

export const isManualEntityGroupData = <T extends DataStructureBase>(
  data: EntityGroupData<T> | ManualEntityGroupData<T>,
): data is ManualEntityGroupData<T> => {
  return 'data' in data;
};

export interface EntityCardColumnsViewProps<T extends DataStructureBase> {
  data: T[];
  groupByPath: DeepPath<T>;
  columnGroupings: (EntityGroupData<T> | ManualEntityGroupData<T>)[];
  CardComponent: (props: { data: T }) => React.ReactElement;
  containerProps?: React.ComponentProps<typeof HStack>;
  columnContainerProps?: EntityCardColumnProps<T>['containerProps'];
  searchByPaths?: DeepPath<T>[];
}

export const EntityCardColumnsView = <T extends DataStructureBase>({
  data,
  groupByPath,
  columnGroupings,
  CardComponent,
  containerProps,
  columnContainerProps,
  searchByPaths,
}: EntityCardColumnsViewProps<T>) => {
  const dataArrayUtil = new ArrayDataUtility(data);
  const groupedData = dataArrayUtil.groupBy(groupByPath);

  if (
    data.length === 0 &&
    columnGroupings.every((group) => !('data' in group) || !group.data.length)
  ) {
    return (
      <Center w="$full" h="$full">
        <Text size="lg" subtle>
          No items to show
        </Text>
      </Center>
    );
  }

  return (
    <HStack h="$full" flex={1} space="lg" {...containerProps}>
      {columnGroupings.map((groupingData, index) => (
        <React.Fragment
          key={
            (groupingData?.value || '') +
            (groupingData?.label || '') +
            index +
            '_EntityCardColumn'
          }
        >
          {index > 0 && <Divider orientation="vertical" subtle />}
          <EntityCardColumn
            items={
              isManualEntityGroupData(groupingData)
                ? groupingData.data
                : groupedData[groupingData.value]
            }
            categoryName={groupingData?.label || groupingData?.value}
            CardComponent={CardComponent}
            indicatorVariant={groupingData?.indicatorDotVariant}
            containerProps={columnContainerProps}
            // HeaderLeftComponent={() => <Text>Test</Text>}
            // HeaderRightComponent={() => <Text>Test</Text>}
            // searchByPaths={[['employee', 'fullName']]}
            searchByPaths={searchByPaths}
          />
        </React.Fragment>
      ))}
    </HStack>
  );
};
