import { DB, Schemas } from '@tonic/central-specialties-utils';
import {
  AlertDialog,
  AlertDialogBackdrop,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  Button,
  ButtonText,
  ScrollView,
  Text,
  VStack,
} from '@gluestack-ui/themed';
import {
  Box,
  ButtonAsync,
  Center,
  Spinner,
  useAlert,
} from '@tonic/central-specialties-ui-themed';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';

interface UnlockUserTimeEntryDialogProps {
  employee: Schemas['UserModel'];
  isOpen: boolean;
  onClose: () => void;
}

export const UnlockUserTimeEntryDialog = ({
  employee,
  isOpen,
  onClose,
}: UnlockUserTimeEntryDialogProps) => {
  const { alert } = useAlert();
  const {
    mutateAsync: unlockUserTimecardEntry,
    isPending: unlockRequestPending,
  } = useMutation({
    mutationFn: async (employeeId: string) =>
      DB.POST('/time-card-entries/employee/{employeeId}/unlock', {
        params: {
          path: {
            employeeId,
          },
        },
      })
        .then(({ error }) => {
          if (error) {
            throw error;
          }
          alert({
            status: 'success',
            message: 'Success! Unlocked time entry for this user.',
            timeout: 5000,
          });
          onClose();
        })
        .catch((error) => {
          alert({
            status: 'error',
            message: 'Failed to unlock time entry for this user.',
            timeout: 10000,
          });
          console.error(error);
        }),
  });

  const {
    data: missingDates,
    isPending: missingDatesPending,
    error: missingDatesError,
  } = useQuery({
    queryKey: ['missing-dates', employee.id],
    queryFn: () =>
      DB.GET('/time-card-entries/employee/{employeeId}/missing-dates', {
        params: { path: { employeeId: employee.id } },
      }).then(
        (res: { data: Schemas['MissingDatesResponse'] }) =>
          res.data.missingDates,
      ),
  });

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} size="md">
      <AlertDialogBackdrop />
      <AlertDialogContent py="$6" gap="$4">
        <AlertDialogBody>
          <Center w="$full">
            <Box w="$96" gap="$4">
              <Text bold size="lg" textAlign="center">
                Do you want to unlock time entry for <br />
                {employee.fullName}?
              </Text>
              <Box w="$full">
                {missingDatesPending ? (
                  <Center w="$full">
                    <Spinner />
                  </Center>
                ) : (
                  <>
                    {missingDatesError ? (
                      <Text size="lg" color="$error500" textAlign="center">
                        Failed to fetch missing dates.
                      </Text>
                    ) : missingDates?.length > 0 ? (
                      <Box gap="$3">
                        <Text size="lg">
                          This employee is missing entries for the following
                          dates:
                        </Text>
                        <ScrollView horizontal maxHeight="$48">
                          <VStack
                            flexWrap="wrap"
                            maxHeight="$48"
                            columnGap="$4"
                          >
                            {missingDates.map((date: string) => (
                              <Text key={date}>
                                {moment(date).format('MM/DD/YYYY')}
                              </Text>
                            ))}
                          </VStack>
                        </ScrollView>
                      </Box>
                    ) : (
                      <Text textAlign="center">No missing dates found.</Text>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Center>
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="space-evenly" gap="$4">
          <Button size="lg" variant="outline" onPress={onClose} w={150}>
            <ButtonText>Cancel</ButtonText>
          </Button>
          <ButtonAsync
            size="lg"
            pending={unlockRequestPending}
            onPress={() => unlockUserTimecardEntry(employee.id)}
            w={150}
          >
            <ButtonText>Unlock</ButtonText>
          </ButtonAsync>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
