import React, { useState } from 'react';
import { FormFieldData } from '../../FormField.types.ts';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionTrigger,
  Box,
  ButtonAsync,
  ButtonGroup,
  Center,
  Icons,
  Popover,
  PopoverBackdrop,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Text,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import {
  Button,
  ButtonIcon,
  ButtonText,
  HStack,
  Icon,
  Pressable,
} from '@gluestack-ui/themed';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import { FormFieldControl } from '../../FormFieldControl.tsx';
import { useMutation } from '@tanstack/react-query';
import { toSentenceCase } from 'js-convert-case';

export interface TruckUpdatesFormFieldData {}

export const TruckUpdatesFormField = ({
  value,
  onChange,
  readonly,
}: FormFieldData<'TruckUpdates'>) => {
  const [showSendInfoPopoverFor, setShowSendInfoPopoverFor] = useState<
    string | null
  >(null);
  const [infoSentFor, setInfoSentFor] = useState<Set<string>>(new Set());
  const [truckIds, setTruckIds] = useState<string[]>([]);

  const closeSendMessagePopover = () => setShowSendInfoPopoverFor(null);
  const openSendMessagePopover = (truck: Schemas['TruckResponse']) => () => {
    setShowSendInfoPopoverFor(truck.id);
  };
  const isTruckSelectedForMessageSend = (truck: Schemas['TruckResponse']) =>
    showSendInfoPopoverFor === truck.id;

  const removeCompany = (truckIndex: number, companyIndex: number) => {
    const newValue = [...value];
    newValue[truckIndex].assignedCompanies.splice(companyIndex, 1);
    onChange(newValue);
  };

  return (
    <VStack gap="$12">
      {value.map((truckGroup, groupIndex) => (
        <VStack key={'truckGroup' + groupIndex} gap="$4">
          <HStack w="$full" justifyContent="space-between" alignItems="center">
            <VStack>
              <Text size="lg" bold>
                Truck {groupIndex + 1}
              </Text>
              <Text color="$textSubtle">
                {truckGroup.materialType ? `${truckGroup.materialType} - ` : ''}
                {!truckGroup?.type ? '' : toSentenceCase(truckGroup.type)}
                {truckGroup.numberOfTrucks > 1
                  ? ` x ${truckGroup.numberOfTrucks}`
                  : ''}
              </Text>
            </VStack>
            <HStack alignItems="center">
              {!!truckGroup?.status && (
                <Text>Status: {truckGroup.status || 'Not set'}</Text>
              )}
              {truckGroup.assignedCompanies.length > 0 &&
                truckGroup.assignedCompanies.every(
                  (company) => !!company?.companyName && !!company?.phoneNumber,
                ) && (
                  <SendInfoMessageButton
                    alreadySent={infoSentFor.has(truckGroup.id)}
                    isOpen={isTruckSelectedForMessageSend(truckGroup)}
                    truck={truckGroup}
                    onOpen={openSendMessagePopover(truckGroup)}
                    onClose={closeSendMessagePopover}
                    onSuccess={() => {
                      setInfoSentFor((prev) => {
                        prev.add(truckGroup.id);
                        return prev;
                      });
                      closeSendMessagePopover();
                    }}
                  />
                )}
            </HStack>
          </HStack>

          <Accordion
            type="multiple"
            variant="unfilled"
            isCollapsible
            value={truckIds}
            onValueChange={(item) => setTruckIds(item)}
          >
            {truckGroup && truckGroup.assignedCompanies?.length > 0 ? (
              truckGroup.assignedCompanies.map(
                (company: Schemas['TruckingCompanyResponse'], companyIndex) => (
                  <AccordionItem
                    key={`truckGroup${groupIndex}Company${companyIndex}${company}`}
                    value={`truckGroup${groupIndex}Company${companyIndex}`}
                    borderColor="$gray400"
                    borderBottomWidth={2}
                  >
                    <AccordionHeader px={0}>
                      <AccordionTrigger p={0}>
                        {({ isExpanded }) => {
                          return (
                            <HStack
                              w="$full"
                              py="$3"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <HStack flex={1} gap="$4" alignItems="center">
                                {!readonly && (
                                  <Pressable
                                    onPress={() =>
                                      removeCompany(groupIndex, companyIndex)
                                    }
                                  >
                                    <ButtonIcon
                                      size="md"
                                      as={Icons.MinusCircle}
                                      color="$textNormal"
                                    />
                                  </Pressable>
                                )}
                                <Text bold textAlign="left">
                                  {company.companyName ||
                                    `Company ${companyIndex + 1}`}
                                  {company.numberOfTrucks > 0
                                    ? `  x ${company.numberOfTrucks}`
                                    : ''}
                                </Text>
                              </HStack>
                              <HStack justifyContent="space-between">
                                <HStack>
                                  <Text subtle underline>
                                    {isExpanded
                                      ? 'Close details'
                                      : 'Open details'}
                                  </Text>
                                  <AccordionIcon
                                    color="$textSubtle"
                                    as={
                                      isExpanded
                                        ? Icons.ChevronUp
                                        : Icons.ChevronDown
                                    }
                                    ml="$3"
                                  />
                                </HStack>
                              </HStack>
                            </HStack>
                          );
                        }}
                      </AccordionTrigger>
                    </AccordionHeader>
                    <AccordionContent px={0} pt="$3">
                      <TruckingCompanyUpdatesFieldGroup
                        truckingCompany={company}
                        index={groupIndex}
                        onChange={(company) => {
                          const newValue = [...value];
                          newValue[groupIndex].assignedCompanies[companyIndex] =
                            company;
                          onChange(newValue);
                        }}
                        readonly={readonly}
                      />
                    </AccordionContent>
                  </AccordionItem>
                ),
              )
            ) : (
              <Text ml="$0.5">No companies assigned</Text>
            )}
          </Accordion>
        </VStack>
      ))}
    </VStack>
  );
};

interface TruckUpdatesFieldGroupProps {
  truckingCompany: Schemas['TruckingCompanyResponse'];
  index: number;
  onChange: (company: Schemas['TruckingCompanyResponse']) => void;
  readonly?: boolean;
}

// PART Can be 'New', 'Ordered', 'Delivered'

const TruckingCompanyUpdatesFieldGroup = ({
  truckingCompany,
  readonly = false,
  onChange,
}: TruckUpdatesFieldGroupProps) => {
  return (
    <VStack gap="$4">
      <FormFieldControl
        type="Text"
        label="Trucking company"
        value={truckingCompany?.companyName || ''}
        onChange={(companyName) =>
          onChange({ ...truckingCompany, companyName })
        }
        readonly={readonly}
        placeholder="Enter trucking company"
      />
      <FormFieldControl
        type="Text"
        label="Number of trucks"
        value={truckingCompany?.numberOfTrucks}
        onChange={(rawNumberOfTrucks: string) => {
          const numberOfTrucks = parseInt(
            rawNumberOfTrucks.replace(/[^0-9]/g, ''),
          );
          onChange({ ...truckingCompany, numberOfTrucks });
        }}
        placeholder="Enter number of trucks"
        readonly={readonly}
      />
      <FormFieldControl
        type="Text"
        label="Phone number"
        value={truckingCompany?.phoneNumber}
        onChange={(phoneNumber) =>
          onChange({ ...truckingCompany, phoneNumber })
        }
        placeholder="Enter phone number"
        readonly={readonly}
      />
      <FormFieldControl
        type="Text"
        label="Ton capacity"
        value={truckingCompany?.tonCapacity}
        onChange={(rawTonCapacity) => {
          const tonCapacity = rawTonCapacity.replace(/[^0-9.]/g, '');
          onChange({ ...truckingCompany, tonCapacity });
        }}
        placeholder="Enter ton capacity"
        readonly={readonly}
      />
      <FormFieldControl
        type="Radio"
        label="DBE"
        options={{ Yes: true, No: false }}
        value={truckingCompany?.dbe || false}
        onChange={(dbe) => onChange({ ...truckingCompany, dbe })}
        readonly={readonly}
        radioContainerProps={{ flexDirection: 'row', gap: '$4' }}
      />
      <FormFieldControl
        type="Text"
        label="Hourly rate"
        value={truckingCompany?.hourlyRate || ''}
        onChange={(rawHourlyRate) => {
          const hourlyRate = rawHourlyRate.replace(/[^0-9.]/g, '');
          onChange({ ...truckingCompany, hourlyRate });
        }}
        placeholder="$ Enter hourly rate"
        readonly={readonly}
      />
    </VStack>
  );
};

interface SendInfoMessageButtonProps {
  isOpen: boolean;
  truck: Schemas['TruckResponse'];
  onOpen: (truck: Schemas['TruckResponse']) => void;
  onClose: () => void;
  onSuccess: () => void;
  alreadySent: boolean;
}

const SendInfoMessageButton = ({
  isOpen,
  truck,
  onOpen,
  onClose,
  onSuccess,
  alreadySent,
}: SendInfoMessageButtonProps) => {
  // TODO: Handle error states & figure out what to use as the ID since it isn't provided on the entity in response
  const { mutateAsync: sendTruckInfoMessage, isPending } = useMutation({
    mutationFn: async (truckId: string) =>
      DB.POST('/trucking-requests/truck/{truckId}/send-info', {
        params: { path: { truckId } },
      }),
  });

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      placement="top"
      size="full"
      trigger={(triggerProps) => {
        return (
          <Pressable {...(!alreadySent ? triggerProps : {})}>
            <HStack gap="$1">
              <Text>{alreadySent ? 'Info sent' : 'Send Info'}</Text>
              <Icon as={alreadySent ? Icons.Check : Icons.MessageSquare} />
            </HStack>
          </Pressable>
        );
      }}
    >
      <PopoverBackdrop />
      <PopoverContent w="$96" py="$4" px="$2">
        <PopoverHeader justifyContent="center">
          <Text bold size="lg">
            Send Message
          </Text>
        </PopoverHeader>
        <PopoverBody px="$12">
          <Center w="$full">
            <Box w="$64">
              <Text size="lg" textAlign="center">
                You are about to send a message to the phone number entered with
                the location and start time for this truck.
              </Text>
            </Box>
          </Center>
        </PopoverBody>
        <PopoverFooter borderWidth={0}>
          <ButtonGroup space="md" w="$full">
            <Button
              flex={1}
              size="lg"
              minWidth="auto"
              variant="outline"
              action="secondary"
              onPress={onClose}
            >
              <ButtonText>Back</ButtonText>
            </Button>
            <ButtonAsync
              pending={isPending}
              flex={1}
              size="lg"
              minWidth="auto"
              onPress={() => {
                sendTruckInfoMessage(truck.id).then(() => {
                  onSuccess();
                  onClose();
                });
              }}
            >
              <ButtonText>Send</ButtonText>
            </ButtonAsync>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
