import {
  DataStructureBase,
  deepObjectValues,
  DeepPath,
  getDeepValue,
  Primitive,
} from '@tonic/central-specialties-utils';
import { useEffect, useState } from 'react';

export const useSearchFilter = <T extends DataStructureBase>(
  /**
   * The data to be filtered
   */
  data: T[],
  /**
   * String value to search by
   */
  searchValue?: string | null = '',
  /**
   * Paths of T to search by. If 'all' is passed, all paths of T will be searched. A DeepPath
   * can be a string (a root level key of T) or an array of strings (a path to an arbitrarily
   * nested value of T)
   *
   * WARNING: Will not react to changes in searchByPaths, only the initial value will be used.
   * Can update if that becomes necessary
   */
  searchByPathsPassed: DeepPath<T> | 'all' = 'all',
): T[] => {
  const [searchFilteredItems, setSearchFilteredItems] = useState<T[]>([]);
  // To prevent infinite rerender cycle when array is passed directly
  const [searchByPaths] = useState(searchByPathsPassed);

  useEffect(() => {
    if (searchByPaths && searchValue && data) {
      const filtered = data.filter((item) => {
        const searchableValues =
          searchByPaths === 'all'
            ? deepObjectValues(item)
            : searchByPaths.map((path) => getDeepValue(item, path));
        return searchableValues.some(
          (value: Primitive) =>
            !!value &&
            value.toString().toLowerCase().includes(searchValue.toLowerCase()),
        );
      });
      setSearchFilteredItems([...filtered]);
    } else {
      setSearchFilteredItems(data);
    }
  }, [searchValue, searchByPaths, data]);

  return searchFilteredItems;
};
