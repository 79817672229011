import React, { useCallback, useEffect } from 'react';
import { FormFieldData } from '../../FormField.types.ts';
import { Divider, Icons, VStack } from '@tonic/central-specialties-ui-themed';
import { Schemas } from '@tonic/central-specialties-utils';
import {
  Button,
  ButtonIcon,
  ButtonText,
  HStack,
  Pressable,
  Text,
} from '@gluestack-ui/themed';
import { FormFieldControl } from '../../FormFieldControl.tsx';

export interface TrucksFormFieldData {}

export const TrucksFormField = ({
  value,
  onChange,
  readonly,
}: FormFieldData<'TruckRequests'>) => {
  const addNewTruck = useCallback(() => {
    if (onChange) {
      onChange([
        ...value,
        {
          type: '',
          numberOfTrucks: 1,
          assignedCompanies: [],
          materialType: '',
          companyName: '',
          driverName: '',
          phoneNumber: '',
          tonCapacity: '',
          dbe: false,
          hourlyRate: 0,
          // create a random temp-id to keep track of admin-side edits
          id: String(Math.random()),
        },
      ]);
    }
  }, [value, onChange]);

  useEffect(() => {
    if (value.length === 0) {
      addNewTruck();
    }
  }, [value]);

  return (
    <VStack gap="$6">
      {value &&
        value.map((truck, index) => (
          <React.Fragment key={`truckField:${index}`}>
            {index === 0 ? <Divider subtle size="md" /> : null}
            <TruckFieldGroup
              truck={truck}
              index={index}
              readonly={readonly}
              addNewTruck={addNewTruck}
              removeTruck={
                value.length > 1
                  ? () => {
                      const newValue = [...value];
                      newValue.splice(index, 1);
                      onChange(newValue);
                    }
                  : undefined
              }
              onChange={(truck) => {
                const newValue = [...value];
                newValue[index] = truck;
                onChange(newValue);
              }}
            />
            <Divider subtle size="md" />
          </React.Fragment>
        ))}
    </VStack>
  );
};

const addBlankCompany = (
  truck: Schemas['TruckResponse'],
): Schemas['TruckResponse'] => ({
  ...truck,
  assignedCompanies: [
    ...truck.assignedCompanies,
    {
      companyName: '',
      phoneNumber: '',
      tonCapacity: '',
      numberOfTrucks: 0,
      dbe: false,
      hourlyRate: 0,
    },
  ],
});

interface TruckFieldGroupProps {
  truck?: Schemas['TruckingRequestResponse'];
  index?: number;
  readonly?: boolean;
  removeTruck?: () => void;
  addNewTruck?: () => void;
  onChange: (truck: Schemas['TruckingRequestResponse']) => void;
  isDisabled: boolean;
}

const TruckFieldGroup = ({
  truck,
  index,
  readonly = false,
  removeTruck,
  addNewTruck,
  onChange,
}: TruckFieldGroupProps) => {
  return (
    <VStack w="$full" gap="$4">
      {!readonly && (
        <VStack>
          <HStack justifyContent="space-between" alignItems="center">
            <HStack gap="$3" alignItems="center">
              {!!removeTruck && (
                <Pressable onPress={removeTruck}>
                  <ButtonIcon
                    size="md"
                    as={Icons.MinusCircle}
                    color="$textNormal"
                  />
                </Pressable>
              )}
              {!!addNewTruck && (
                <Pressable onPress={addNewTruck}>
                  <ButtonIcon
                    size="md"
                    as={Icons.PlusCircle}
                    color="$textNormal"
                  />
                </Pressable>
              )}
              <Text size="lg" bold>
                Truck {index + 1}
              </Text>
            </HStack>
            {!readonly && (
              <Button
                variant="outline"
                size="sm"
                sx={{
                  borderRadius: '$full',
                  w: '$32',
                  maxWidth: '$32',
                  minWidth: '$32',
                  px: 0,
                  py: 0,
                }}
                onPress={() => {
                  onChange(addBlankCompany(truck));
                }}
              >
                <ButtonIcon as={Icons.PlusCircle} size="sm" />
                <ButtonText>Add Company</ButtonText>
              </Button>
            )}
          </HStack>
        </VStack>
      )}
      <VStack gap="$5" flex={1}>
        <FormFieldControl
          type="Select"
          label="Truck type"
          placeholder="Select truck type"
          options={{
            'Belly Dump': 'BellyDump',
            'Live Bottom': 'LiveBottom',
            'Side Dump': 'SideDump',
            'Dump Truck': 'DumpTruck',
            'End Dump': 'EndDump',
          }}
          readonly={readonly}
          value={truck.type}
          onChange={(type) => {
            onChange({ ...truck, type });
          }}
        />
        <FormFieldControl
          type="Text"
          label="Material type"
          placeholder="Enter material type"
          readonly={readonly}
          value={truck.materialType}
          onChange={(materialType) => onChange({ ...truck, materialType })}
        />
        <FormFieldControl
          type="Text"
          label="Number of trucks"
          placeholder="Enter nmber of trucks"
          readonly={readonly}
          value={truck.numberOfTrucks}
          onChange={(numberOfTrucks) => {
            const numsOnly = numberOfTrucks.replace(/\D/g, '');
            onChange({ ...truck, numberOfTrucks: numsOnly });
          }}
        />
      </VStack>
    </VStack>
  );
};
