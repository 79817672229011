import { InfoItem, InfoItemProps } from './InfoItem.tsx';
import React from 'react';
import { VStack } from '@tonic/central-specialties-ui-themed';

export interface InfoListProps extends React.ComponentProps<typeof VStack> {
  items: InfoItemProps[];
}

export const InfoList = ({ items, ...vStackProps }: InfoListProps) => (
  <VStack width="$64" maxWidth="$full" space="3xl" {...vStackProps}>
    {items.map((itemProps) => (
      <InfoItem key={itemProps.label + '_infoItem'} {...itemProps} />
    ))}
  </VStack>
);
