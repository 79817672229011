import { Input, InputField, Text } from '@gluestack-ui/themed';
import { Textarea, TextareaInput } from '@tonic/central-specialties-ui-themed';
import React from 'react';
import { FormFieldData } from '../FormField.types.ts';

export interface SharedTextFormFieldData {
  readonly?: boolean;
  placeholder?: string;
}

export interface SinglelineTextFormFieldData extends SharedTextFormFieldData {
  multiline?: false | undefined;
  inputProps?: Partial<React.ComponentProps<typeof Input>>;
  inputFieldProps?: Partial<React.ComponentProps<typeof InputField>>;
}

export interface MultilineTextFormFieldData extends SharedTextFormFieldData {
  multiline: true;
  inputProps?: Partial<React.ComponentProps<typeof Textarea>>;
  inputFieldProps?: Partial<React.ComponentProps<typeof TextareaInput>>;
}

export type ReadonlyTextFormFieldData = (
  | SinglelineTextFormFieldData
  | MultilineTextFormFieldData
) & {
  /**
   * If true, will render an InfoItem instead of a gluestack input
   */
  readonly: true;
  /**
   * Determines what text is displayed in the readonly state
   */
  populate: (formData: Record<string, unknown>) => string;
};

export type DynamicTextFormFieldData = (
  | ReadonlyTextFormFieldData
  | SinglelineTextFormFieldData
  | MultilineTextFormFieldData
) & {
  /**
   * If true, will render an InfoItem instead of a gluestack input
   */
  enableDynamicForm: true;
  /**
   * Determines what text is displayed in the updated state (ex. JobId should populate initial address)
   */
  dynamicUpdate: (formData: Record<string, unknown>) => string;
};

export type TextFormFieldData =
  | SinglelineTextFormFieldData
  | MultilineTextFormFieldData
  | ReadonlyTextFormFieldData
  | DynamicTextFormFieldData;

export const TextFormField = ({
  disabled,
  value,
  onChange,
  readonly,
  multiline,
  placeholder,
  size = 'md',
  inputProps,
  inputFieldProps,
}: FormFieldData<'Text'>) => {
  if (readonly) {
    return (
      <Text size={size} pl="$0.5">
        {value || 'Not specified'}
      </Text>
    );
  }

  if (multiline) {
    return (
      <Textarea size={size} isDisabled={disabled} {...inputProps}>
        <TextareaInput
          value={value}
          onChangeText={onChange}
          placeholder={placeholder}
          {...inputFieldProps}
        />
      </Textarea>
    );
  }

  return (
    <Input size={size} isDisabled={disabled} {...inputProps}>
      <InputField
        value={value}
        onChangeText={onChange}
        placeholder={placeholder}
        {...inputFieldProps}
      />
    </Input>
  );
};
