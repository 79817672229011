import React, { useState } from 'react';
import {
  Center,
  Icons,
  Modal,
  ModalBackdrop,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { DB } from '@tonic/central-specialties-utils';
import {
  Button,
  ButtonIcon,
  ButtonText,
  HStack,
  Icon,
  Text,
} from '@gluestack-ui/themed';
import { useMutation } from '@tanstack/react-query';
import { downloadCSVFile } from '../../../../../utils/downloadObjectsAsCSV';

interface ImportNewUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

type Base64String = string;

export const ImportNewUserModal = ({
  isOpen,
  onClose,
  onSuccess,
}: ImportNewUserModalProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>('');

  const fileToBase64 = (file: File): Promise<Base64String> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader?.result?.toString()?.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });

  const importCSV = useMutation({
    mutationFn: async (base64String: string) => {
      const response = await DB.POST('/users/import', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          fileData: base64String,
        },
        parseAs: 'text',
      });
      if (!response?.response?.ok) {
        throw new Error('Network response to upload users was not ok');
      }

      return response?.response?.ok;
    },
  });

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files[0]) {
      setFile(files[0]);
      setUploadStatus(files[0].name);
    }
  };

  const uploadFile = async () => {
    if (!file) {
      setUploadStatus('No file selected');
      return;
    }

    try {
      const base64Result = await fileToBase64(file);
      const response = await importCSV.mutateAsync(base64Result);

      if (response) {
        setUploadStatus('File uploaded successfully');
        onSuccess && onSuccess();
      } else {
        setUploadStatus('Failed to upload file');
      }
    } catch (error) {
      console.error('Error uploading file: ', error);
      setUploadStatus('Failed to upload file');
    }
  };

  const downloadFile = async () => {
    try {
      const response = await DB.GET('/users/export', {
        parseAs: 'text',
      });
      if (!response.data) {
        throw new Error('Network response was not ok');
      }
      downloadCSVFile(response.data, 'users.csv');
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBackdrop />
      <ModalContent
        borderRadius={0}
        maxHeight="80%"
        w="$96"
        width="60%"
        maxWidth={800}
        minWidth={500}
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
          pl="$10"
          pr="$6"
        >
          <HStack flex={1} justifyContent="space-between" alignItems="center">
            <Text color="$white" size="lg">
              Import users data
            </Text>
            <ModalCloseButton
              w="$8"
              justifyContent="center"
              alignItems="center"
            >
              <Icon color="$white" size="xl" as={Icons.X} />
            </ModalCloseButton>
          </HStack>
        </ModalHeader>
        <VStack space="lg" h="$full" mt="$4">
          <Center>
            <Text color="$black">
              {' '}
              Please use Select File below to choose a file to upload with the
              Import Users Data button. Or download the current Users Data list.
            </Text>
            <Text bold size="lg" color="$black">
              {`Upload Status of file: ${uploadStatus}`}
            </Text>
          </Center>
          <Center flexDirection="row" mb="$4">
            <label
              style={{
                flex: 1,
                flexDirection: 'row',
                backgroundColor: '#9D0101',
                color: '#FFFFFF',
                alignContent: 'center',
                justifyContent: 'center',
                borderRadius: 4,
                marginRight: 16,
                paddingLeft: 32,
                paddingRight: 0,
                gap: 8,
                height: 48,
                maxWidth: 120,
                cursor: 'pointer',
                fontSize: 16,
              }}
            >
              Select File
              <input
                type="file"
                accept=".csv"
                onChange={selectFile}
                style={{ display: 'none' }}
              />
            </label>
            <Button size="lg" onPress={uploadFile} gap="$2" isDisabled={!file}>
              <ButtonIcon as={Icons.Upload} />
              <ButtonText>Import Users Data</ButtonText>
            </Button>
            <Button size="lg" onPress={downloadFile} gap="$2" ml={'$2'}>
              <ButtonIcon as={Icons.Download} />
              <ButtonText>Download Users Data</ButtonText>
            </Button>
          </Center>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
