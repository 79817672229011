import React, { useState } from 'react';
import { Schemas } from '@tonic/central-specialties-utils';
import {
  Box,
  Center,
  Icons,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from '@tonic/central-specialties-ui-themed';
import { HStack, Icon, Text } from '@gluestack-ui/themed';
import { UserCard } from '../../../../components/general/cards/UserCard.tsx';
import { SearchBar } from '../../../../components/general/SearchBar.tsx';
import { useSearchFilter } from '../../../../hooks/useSearchFilter.ts';
import { DisabledUserFormModal } from '../../../../components/general/Form/specific/UserForm/DisabledUserFormModal.tsx';

interface DisabledUsersModalProps {
  isOpen: boolean;
  onClose: () => void;
  disabledUsers: Schemas['UserModel'][];
  refetch?: () => void;
}

export const DisabledUsersModal = ({
  isOpen,
  onClose,
  disabledUsers,
  refetch,
}: DisabledUsersModalProps) => {
  const [selectedUser, setSelectedUser] = useState<Schemas['UserModel'] | null>(
    null,
  );
  const [searchValue, setSearchValue] = useState('');
  const searchFilteredUsers = useSearchFilter(disabledUsers, searchValue, [
    'fullName',
    'email',
    'employeeNumber',
  ]);

  // TODO: when going back from a selected user, search State remains, but search value doesn't show, make sure it does

  if (selectedUser) {
    return (
      <DisabledUserFormModal
        isOpen={isOpen}
        onClose={onClose}
        goBack={() => setSelectedUser(null)}
        disabledUser={selectedUser}
        onReinstateSuccess={() => {
          refetch && refetch();
          onClose();
        }}
      />
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBackdrop />
      <ModalContent
        borderRadius={0}
        maxHeight="90%"
        maxWidth="90%"
        minWidth={500}
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
          pl="$10"
          pr="$6"
        >
          <Text color="$white" size="lg">
            Disabled users
          </Text>
          <HStack space="lg" alignItems="center" gap="$6">
            <Center w="$76">
              <SearchBar onChangeText={setSearchValue} />
            </Center>
            <Center>
              <ModalCloseButton
                w="$8"
                justifyContent="center"
                alignItems="center"
              >
                <Icon color="$white" size="xl" as={Icons.X} />
              </ModalCloseButton>
            </Center>
          </HStack>
        </ModalHeader>

        <ModalBody
          w="$full"
          p="$4"
          my="$2"
          flex={1}
          alignItems="space-between"
          justifyContent="space-between"
        >
          <HStack w="$full" flexWrap="wrap">
            {searchFilteredUsers && searchFilteredUsers?.length === 0 && (
              <Center w="$full" h="$full">
                <Text size="lg">
                  {searchValue
                    ? 'No results match search'
                    : 'No disabled users to show'}
                </Text>
              </Center>
            )}
            {searchFilteredUsers &&
              searchFilteredUsers?.length > 0 &&
              searchFilteredUsers.map((disabledUser) => (
                <Box key={disabledUser.id} w="$1/3" py="$1.5" px="$2.5">
                  <UserCard
                    user={disabledUser}
                    onPress={() => setSelectedUser(disabledUser)}
                    isSelected={false}
                    flex={1}
                  />
                </Box>
              ))}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
