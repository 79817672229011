import React, { useState } from 'react';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import {
  Box,
  ButtonAsync,
  Center,
  Divider,
  Icons,
  Modal,
  ModalBackdrop,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  useAlert,
  VStack,
  MessagesForEntity,
} from '@tonic/central-specialties-ui-themed';
import {
  AlertDialog,
  AlertDialogBackdrop,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  Button,
  ButtonText,
  HStack,
  Icon,
  ScrollView,
} from '@gluestack-ui/themed';
import moment from 'moment';
import { useMutation } from '@tanstack/react-query';
import { PartsRequestForm } from '../../../../components/general/Form/specific/PartsRequestForm/PartsRequestForm.tsx';

interface PartsRequestSummaryModalProps {
  partsRequest: Schemas['RepairRequestResponse'];
  isOpen: boolean;
  onClose: () => void;
  onPressEdit?: (data: Schemas['RepairRequestResponse']) => void;
  refetch?: () => void;
}

export const PartsRequestSummaryModal = ({
  partsRequest,
  isOpen,
  onClose,
  onPressEdit,
  refetch,
}: PartsRequestSummaryModalProps) => {
  const [showArchiveRequestModal, setShowArchiveRequestModal] = useState(false);

  if (showArchiveRequestModal) {
    return (
      <ArchivePartsRequestDialog
        partsRequest={partsRequest}
        isOpen={true}
        onClose={() => setShowArchiveRequestModal(false)}
        onSuccess={() => {
          refetch && refetch();
          onClose();
        }}
      />
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBackdrop />
      <ModalContent
        borderRadius={0}
        bg="$gray100"
        maxWidth="90%"
        maxHeight="80%"
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
        >
          <HStack
            h="$full"
            alignItems="center"
            flex={1}
            justifyContent="space-between"
          >
            <HStack alignItems="center" space="md">
              <Text size="xl" color="$white" bold>
                Parts Request -{' '}
                {partsRequest.requestNumber || '(number pending)'}
              </Text>
            </HStack>
            <HStack alignItems="center" gap="$2">
              <Text color="$white" pr="$8">
                Last updated:{' '}
                {moment(partsRequest.updatedAt)
                  .utc(true)
                  .tz('America/Chicago')
                  .format('h:mma M/D/YY')}
              </Text>
              <Button
                w="$12"
                minWidth="auto"
                onPress={() => setShowArchiveRequestModal(true)}
              >
                <Icon color="$white" as={Icons.Archive} />
              </Button>
              {!!onPressEdit && (
                <Button
                  w="$12"
                  minWidth="auto"
                  onPress={() => onPressEdit(partsRequest)}
                >
                  <Icon color="$white" as={Icons.Edit} />
                </Button>
              )}
              <ModalCloseButton justifyContent="center" alignItems="center">
                <Icon color="$white" size="xl" as={Icons.X} />
              </ModalCloseButton>
            </HStack>
          </HStack>
        </ModalHeader>
        <HStack flex={1} pt="$8" px="$6" gap="$8">
          <VStack flex={2} h="$full">
            <ScrollView h="$full" w="$full">
              <PartsRequestForm
                partsRequestId={partsRequest?.id}
                isAdminMode
                isReadonlyMode
              />
            </ScrollView>
          </VStack>
          <Divider orientation="vertical" subtle />
          <VStack flex={1} pb="$6">
            <MessagesForEntity
              entityKind="part-requests"
              entityId={partsRequest.id}
            />
          </VStack>
        </HStack>
      </ModalContent>
    </Modal>
  );
};

interface ArchivePartsRequestDialogProps {
  isOpen: boolean;
  onClose: () => void;
  partsRequest: Schemas['PartRequestTicketResponse'];
  onSuccess?: () => void;
}

const ArchivePartsRequestDialog = ({
  isOpen,
  onClose,
  partsRequest,
  onSuccess,
}: ArchivePartsRequestDialogProps) => {
  const { alert } = useAlert();

  // TODO: Test this once there are more, or once un-archive functionality is in place
  const {
    mutateAsync: archivePartsRequest,
    isPending: archivePartsRequestPending,
  } = useMutation({
    mutationFn: (id: string) =>
      DB.POST('/part-requests/{id}/status', {
        params: {
          path: {
            id,
          },
        },
        body: {
          status: 'Archived',
        },
      }).then(({ response }) => {
        if (response.ok) {
          alert({
            status: 'success',
            message: 'Success! Ticket archived.',
            dismissible: true,
            timeout: 5000,
          });
          onSuccess && onSuccess();
        } else {
          alert({
            status: 'error',
            message: 'Something went wrong, could not archive this ticket',
            timeout: 10000,
          });
        }
        onClose();
      }),
  });

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} size="sm">
      <AlertDialogBackdrop />
      <AlertDialogContent py="$6" gap="$8">
        <AlertDialogBody>
          <Center w="$full">
            <Box w="$48">
              <Text bold size="lg" textAlign="center">
                Are you sure you want to archive this ticket?
              </Text>
            </Box>
          </Center>
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="space-evenly" gap="$4">
          <Button size="lg" variant="outline" onPress={onClose} w={150}>
            <ButtonText>Cancel</ButtonText>
          </Button>
          <ButtonAsync
            size="lg"
            pending={archivePartsRequestPending}
            onPress={() => archivePartsRequest(partsRequest.id)}
            w={150}
          >
            <ButtonText>Archive</ButtonText>
          </ButtonAsync>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
