import moment from 'moment';
import { DB, FeatureCategory, Schemas } from '@tonic/central-specialties-utils';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { IndicatorDot } from '@tonic/central-specialties-ui-themed';

export interface UseNotificationsHookData {
  notifications: React.ComponentProps<typeof IndicatorDot>[];
  isPending?: boolean;
}

const timeframe = {
  startDate: moment().startOf('week').utc().toDate().toISOString(),
  endDate: moment().endOf('week').utc().toDate().toISOString(),
};
export const notificationHookMap: {
  [Key in FeatureCategory]: () => UseNotificationsHookData;
} = {
  /**
   * Notifications for the repair requests
   * Only one status (neutral) counting all 'New' repair requests
   */
  Repair: () => {
    const { data, isPending } = useQuery({
      queryKey: ['repairRequests'],
      queryFn: () =>
        DB.GET('/repair-requests').then(
          (res: { data: Schemas['RepairRequestResponse'][] }) => res.data,
        ),
    });

    const notifications = useMemo(() => {
      if (!data) return [];
      const count = data.filter((repReq) => repReq.status === 'New').length;
      return [
        {
          variant: 'neutral',
          count,
        },
      ];
    }, [data]);

    return { notifications, isPending };
  },

  /**
   * Notifications for the parts requests
   * Only one status (neutral) counting all 'New' parts requests
   */
  Parts: () => {
    const { data, isPending } = useQuery({
      queryKey: ['partsRequests'],
      queryFn: async () =>
        await DB.GET('/part-requests').then(
          (res: { data: Schemas['PartRequestTicketListResponse'] }) =>
            res.data.partRequests,
        ),
    });

    const notifications = useMemo(() => {
      if (!data) return [];
      const count = data.filter((partReq) => partReq.status === 'New').length;
      return [
        {
          variant: 'neutral',
          count,
        },
      ];
    }, [data]);

    return { notifications, isPending };
  },

  /**
   * Notifications for the Time Cards
   * Uses the same timeframe as the Time Cards page default
   * 3 status notifications
   *   - green: 'Submitted'
   *   - yellow: 'Denied'
   *   - red: 'Missing'
   */
  Time: () => {
    const { data, isPending } = useQuery({
      queryKey: [
        'timecards',
        timeframe.startDate,
        timeframe.endDate,
        'daySummaries',
      ],
      queryFn: () =>
        DB.GET('/time-card-entries/payroll-admin', {
          params: {
            query: {
              startTime: timeframe.startDate,
              endTime: timeframe.endDate,
            },
          },
        }).then((v: { data: Schemas['TimeCardUserDaySummaryList'] }) => {
          return v.data.daySummaries;
        }) as Schemas['TimeCardUserDaySummary'][],
    });

    const statusVariantMap = {
      Submitted: 'success',
      Denied: 'warning',
      Missing: 'error',
    };

    const notifications = useMemo(() => {
      if (!data) return [];
      const countsByStatus = data.reduce(
        (acc, daySummary) => {
          if (
            daySummary.status === 'Missing' ||
            (daySummary.timeCardEntryCount > 0 &&
              ['Submitted', 'Denied'].includes(daySummary.status))
          ) {
            acc[daySummary.status] += 1;
          }
          return acc;
        },
        { Submitted: 0, Denied: 0, Missing: 0 },
      );
      // instead of entries to enforce order
      return ['Submitted', 'Denied', 'Missing'].map((status) => ({
        variant: statusVariantMap[status],
        count: countsByStatus[status],
      }));
    }, [data]);

    return { notifications, isPending };
  },

  /**
   * Notifications for the Trucking requests
   * Only one status (neutral) counting all 'New' trucking requests
   */
  Truck: () => {
    const { data, isPending } = useQuery({
      queryKey: ['truckRequests'],
      queryFn: () =>
        DB.GET('/trucking-requests').then(
          (res: { data: Schemas['TruckingRequestListResponse'] }) =>
            res.data.truckingRequests,
        ),
    });

    const notifications = useMemo(() => {
      if (!data) return [];
      const count = data.filter((truckReq) => truckReq.status === 'New').length;
      return [
        {
          variant: 'neutral',
          count,
        },
      ];
    }, [data]);

    return { notifications, isPending };
  },
};
