import { Card, VStack } from '@tonic/central-specialties-ui-themed';
import { HStack, Text } from '@gluestack-ui/themed';
import { FormFieldData } from '../FormField.types.ts';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { decimalHoursToTimeString } from '@tonic/central-specialties-utils';

export interface TimeframeFormFieldData {}

export const TimeframeFormField = ({
  value,
  onChange,
  disabled,
  invalid,
}: FormFieldData<'Timeframe'>) => {
  return (
    <TimeframeInput
      value={value}
      onChange={onChange}
      invalid={invalid}
      disabled={disabled}
    />
  );
};

export interface TimeframeData {
  startTime: string;
  endTime: string;
}

export interface TimeframeInputProps {
  value: TimeframeData;
  onChange: (value: TimeframeData) => void;
  invalid: boolean;
  disabled?: boolean;
  timezone?: string;
}

export const TimeframeInput = ({
  value,
  onChange,
  invalid,
  disabled,
  timezone = 'CST',
}: TimeframeInputProps) => {
  const [timeBetween, setTimeBetween] = useState(0);
  const [startTime, setStartTime] = useState(value?.startTime || '');
  const [endTime, setEndTime] = useState(value?.endTime || '');

  useEffect(() => {
    if (startTime && endTime) {
      const start = moment(startTime, 'HH:mm');
      const end = moment(endTime, 'HH:mm');
      const duration = moment.duration(end.diff(start));
      setTimeBetween(duration.asHours());
    }

    onChange({ startTime, endTime });
  }, [startTime, endTime]);

  return (
    <Card
      hardShadow="1"
      bg="$gray100"
      borderColor="$error500"
      borderWidth={invalid ? 2 : 0}
      opacity={disabled ? 0.5 : 1}
    >
      <VStack w="$full" alignItems="center">
        <HStack w="$full" justifyContent="flex-end">
          {timezone && (
            <Text bold subtle>
              {timezone}
            </Text>
          )}
        </HStack>
        <VStack px="$12" gap="$4" alignItems="center">
          <HStack gap="$10">
            <TimeInput
              value={startTime}
              onChange={setStartTime}
              label="Start time"
              invalid={invalid}
              disabled={disabled}
            />
            <TimeInput
              value={endTime}
              onChange={setEndTime}
              label="End time"
              invalid={invalid}
              disabled={disabled}
            />
          </HStack>
          <Text bold>Total hours: {decimalHoursToTimeString(timeBetween)}</Text>
        </VStack>
      </VStack>
    </Card>
  );
};

export interface TimeInputProps {
  value: string;
  onChange: (val: string) => void;
  label?: string;
  invalid?: boolean;
  disabled?: boolean;
}

export const TimeInput = ({
  value,
  onChange,
  label,
  invalid,
  disabled,
}: TimeInputProps) => {
  return (
    <VStack alignItems="center" gap="$2">
      {!!label && <Text subtle>{label}</Text>}
      <input
        type="time"
        value={value?.toString()}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        style={{
          border: `1px solid ${invalid ? 'red' : 'black'}`,
          borderRadius: 4,
          padding: 10,
        }}
        disabled={!!disabled}
      />
    </VStack>
  );
};
