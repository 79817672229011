import {
  Button,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  HStack,
  Icon,
  Text,
} from '@gluestack-ui/themed';
import { Icons } from '@tonic/central-specialties-ui-themed';

export interface InfoItemProps {
  label: string;
  value: string | number;
  onEditClick?: () => void;
  size?: 'sm' | 'md' | 'lg';
}

export const InfoItem = ({
  label,
  value,
  onEditClick,
  size = 'lg',
}: InfoItemProps) => {
  return (
    <FormControl size={size}>
      <FormControlLabel>
        <FormControlLabelText>{label}</FormControlLabelText>
      </FormControlLabel>
      <HStack w="$full" justifyContent="space-between" alignItems="center">
        <Text size={size}>{value}</Text>
        {onEditClick && (
          <Button variant="link" onPress={onEditClick}>
            <Icon as={Icons.Edit3} />
          </Button>
        )}
      </HStack>
    </FormControl>
  );
};
