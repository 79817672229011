import { Divider, VStack } from '@tonic/central-specialties-ui-themed';
import logoAndName from '../../assets/brand/csi-logo-name-horizontal.svg';
import { Outlet } from 'react-router-dom';

export const AuthPage = () => {
  return (
    <VStack alignItems="center" width={786} maxWidth={'100%'} space="4xl">
      <img
        src={logoAndName as string}
        alt="Central Specialties, Inc."
        style={{ maxWidth: '90%' }}
        width={623}
      />
      <Divider />
      <Outlet />
    </VStack>
  );
};
