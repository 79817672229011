import React, { useMemo, useState } from 'react';
import { Schemas } from '@tonic/central-specialties-utils';
import {
  Box,
  Center,
  Icons,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from '@tonic/central-specialties-ui-themed';
import { HStack, Icon, Text } from '@gluestack-ui/themed';
import { ArchiveJobSummaryCard } from './ArchivedJobSummaryCard';
import { ArchiveJobNumbersDialog } from './ArchivedJobNumbersDialog';

interface ArchivedJobsModalProps {
  isOpen: boolean;
  onClose: () => void;
  jobRequests: Schemas['JobRequestTicketResponse'][];
  refetchJobs?: () => void;
  refetchArchivedJobs?: () => void;
}

export const ArchivedJobsModal = ({
  isOpen,
  onClose,
  jobRequests,
  refetchJobs,
  refetchArchivedJobs,
}: ArchivedJobsModalProps) => {
  const [showArchiveJobNumberDialog, setShowArchiveJobNumberDialog] =
    useState(false);
  const [selectedArchivedJobNumber, setSelectedArchivedJobNumber] =
    useState('');

  const sortedJobRequests = useMemo(() => {
    const sorted = [...jobRequests];
    sorted.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    });
    return sorted;
  }, [jobRequests]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBackdrop />
      <ModalContent
        borderRadius={0}
        maxHeight="90%"
        maxWidth="90%"
        minWidth={500}
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
          pl="$10"
          pr="$6"
        >
          <Text color="$white" size="lg">
            Archived Tickets
          </Text>
          <HStack space="lg" alignItems="center" gap="$6">
            <Center>
              <ModalCloseButton
                w="$8"
                justifyContent="center"
                alignItems="center"
              >
                <Icon color="$white" size="xl" as={Icons.X} />
              </ModalCloseButton>
            </Center>
          </HStack>
        </ModalHeader>

        <ModalBody
          w="$full"
          p="$4"
          my="$2"
          flex={1}
          justifyContent="space-between"
          minHeight="$40"
        >
          <HStack w="$full" flexWrap="wrap">
            {sortedJobRequests.length === 0 ? (
              <Center w="$full" h="$full">
                <Text size="lg">No archived job numbers to show</Text>
              </Center>
            ) : (
              sortedJobRequests.map((jobRequests) => (
                <Box key={jobRequests.id} w="$1/3" py="$1.5" px="$2.5">
                  <ArchiveJobSummaryCard
                    jobRequest={jobRequests}
                    cardProps={{
                      flex: 1,
                    }}
                    onPress={() => {
                      setShowArchiveJobNumberDialog(true);
                      setSelectedArchivedJobNumber(jobRequests.id);
                    }}
                  />
                </Box>
              ))
            )}
          </HStack>
        </ModalBody>
      </ModalContent>
      {showArchiveJobNumberDialog && (
        <ArchiveJobNumbersDialog
          jobNumber={selectedArchivedJobNumber}
          isOpen={true}
          onClose={() => setShowArchiveJobNumberDialog(false)}
          onSuccess={() => {
            refetchJobs && refetchJobs();
            refetchArchivedJobs && refetchArchivedJobs();
            setShowArchiveJobNumberDialog(false);
            setSelectedArchivedJobNumber('');
          }}
          toggleArchive={false}
        />
      )}
    </Modal>
  );
};
