import React, { useEffect } from 'react';
import { FormFieldData } from '../FormField.types.ts';
import { Center, Icons, VStack } from '@tonic/central-specialties-ui-themed';
import {
  Button,
  ButtonIcon,
  ButtonText,
  HStack,
  Input,
  InputField,
  InputSlot,
} from '@gluestack-ui/themed';

export interface CustomStringsFormFieldData {
  addButtonText?: string;
  placeholder?: string;
}

/// This allows the user to add an arbitrary number of custom strings (e.g. the additional questions field for job numbers)

export const CustomStringsFormField = ({
  value,
  onChange,
  addButtonText,
  disabled,
  invalid,
  placeholder,
  size = 'md',
}: FormFieldData<'CustomStrings'>) => {
  useEffect(() => {
    if (!Array.isArray(value)) {
      console.error(
        'value passed to CustomStringsFormField must be an array. Did you pass it with form values?',
      );
    }
  }, [value]);

  // make sure there is at least one empty question to edit
  useEffect(() => {
    if (value.length === 0) {
      onChange(['']);
    }
  }, [value, onChange]);

  return (
    <VStack flex={1} maxWidth="$full" gap="$1.5">
      {value.map((val, index) => (
        <Input
          key={index}
          size={size}
          isInvalid={invalid}
          isDisabled={disabled}
          gap="$2"
        >
          <InputField
            size={size}
            value={val}
            placeholder={placeholder}
            onChangeText={(newTextVal) => {
              const newValue = [...value];
              newValue[index] = newTextVal;
              onChange(newValue);
            }}
          />
          <InputSlot pr="$2">
            <Center>
              <Button
                onPress={() => {
                  const newValue = [...value];
                  newValue.splice(index, 1);
                  onChange(newValue);
                }}
                isDisabled={value.length === 1}
                xs={{
                  ':disabled': {
                    opacity: 0.5,
                  },
                }}
                bg="transparent"
                minWidth="auto"
                p="$0.5"
                height="auto"
              >
                <ButtonIcon
                  size="lg"
                  color="$textNormal"
                  as={Icons.MinusCircle}
                />
              </Button>
            </Center>
          </InputSlot>
        </Input>
      ))}

      <HStack>
        <Button
          bg="transparent"
          minWidth="auto"
          sx={{
            ':disabled': {
              opacity: 0.5,
            },
          }}
          // Add button is disabled if there are already empties
          isDisabled={!value.every((v) => v)}
          onPress={() => {
            onChange([...value, '']);
          }}
          px="$0"
        >
          <ButtonIcon size="lg" as={Icons.PlusCircle} color="$textNormal" />
          {addButtonText && (
            <ButtonText color="$textNormal">{addButtonText}</ButtonText>
          )}
        </Button>
      </HStack>
    </VStack>
  );
};
