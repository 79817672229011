import React, { useMemo } from 'react';
import { Schemas } from '@tonic/central-specialties-utils';
import {
  Box,
  Center,
  Icons,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from '@tonic/central-specialties-ui-themed';
import { HStack, Icon, Text } from '@gluestack-ui/themed';
import { TruckRequestCard } from '../../../../components/general/cards/TruckRequestCard.tsx';

interface ArchivedTruckRequestsModalProps {
  isOpen: boolean;
  onClose: () => void;
  truckRequests: Schemas['TruckingRequestResponse'][];
  onSelectTicket: (ticket: Schemas['TruckingRequestResponse']) => void;
}

export const ArchivedTruckRequestsModal = ({
  isOpen,
  onClose,
  truckRequests,
  onSelectTicket,
}: ArchivedTruckRequestsModalProps) => {
  // sort trucking requests by updatedAt, with newest requests at the top
  const sortedTruckRequests = useMemo(() => {
    const sorted = [...truckRequests];
    sorted.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    });
    return sorted;
  }, [truckRequests]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBackdrop />
      <ModalContent
        borderRadius={0}
        maxHeight="90%"
        maxWidth="90%"
        minWidth={500}
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
          pl="$10"
          pr="$6"
        >
          <Text color="$white" size="lg">
            Archived Tickets
          </Text>
          <HStack space="lg" alignItems="center" gap="$6">
            {/*<Center w="$76">*/}
            {/*    <SearchBar onChangeText={setSearchValue} />*/}
            {/*</Center>*/}
            <Center>
              <ModalCloseButton
                w="$8"
                justifyContent="center"
                alignItems="center"
              >
                <Icon color="$white" size="xl" as={Icons.X} />
              </ModalCloseButton>
            </Center>
          </HStack>
        </ModalHeader>

        <ModalBody
          w="$full"
          p="$4"
          my="$2"
          flex={1}
          justifyContent="space-between"
          minHeight="$40"
        >
          <HStack w="$full" flexWrap="wrap">
            {sortedTruckRequests.length === 0 ? (
              <Center w="$full" h="$full">
                <Text size="lg">No archived truck requests to show</Text>
              </Center>
            ) : (
              sortedTruckRequests.map((truckRequest) => (
                <Box key={truckRequest.id} w="$1/3" py="$1.5" px="$2.5">
                  <TruckRequestCard
                    truckRequest={truckRequest}
                    // TODO: REMOVE THIS WORKAROUND LATER
                    onPress={() =>
                      onSelectTicket({ ...truckRequest, status: 'Archived' })
                    }
                    cardProps={{
                      flex: 1,
                    }}
                  />
                </Box>
              ))
            )}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
