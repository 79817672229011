import React, { useMemo } from 'react';
import { FormFieldData } from '../FormField.types.ts';
import {
  ChevronDownIcon,
  Select,
  SelectBackdrop,
  SelectContent,
  SelectDragIndicator,
  SelectDragIndicatorWrapper,
  SelectIcon,
  SelectInput,
  SelectItem,
  SelectPortal,
  SelectTrigger,
  Text,
} from '@tonic/central-specialties-ui-themed';

export interface MultiSelectFormFieldData {
  options: Record<string, string | number | boolean> | string[];
  placeholder?: string;
  /**
   * Use this to remove extra information listed with the item in the dropdown from the final displayed selection value
   * e.g. To turn item with label '3983 - Some description' into '3983' after selection
   */
  selectedLabelModifier?: (value: string) => string;
}

export const MultiSelectFormField = ({
  value,
  onChange,
  options: inputOptions,
  readonly,
  placeholder,
  selectedLabelModifier = (label) => label,
  size = 'md',
}: FormFieldData<'MultiSelect'>) => {
  const options = useMemo(() => {
    if (Array.isArray(inputOptions)) {
      return Object.fromEntries(inputOptions.map((val) => [val, val]));
    }
    return inputOptions;
  }, [inputOptions]);

  const selectedLabels = useMemo(() => {
    return Object.entries(options)
      .filter(([_, val]) => value.includes(val))
      .map(([label]) => label);
  }, [value, options]);

  const valueLabel = useMemo(() => {
    return selectedLabels
      .map((label) => selectedLabelModifier(label))
      .join(', ');
  }, [selectedLabels, selectedLabelModifier]);

  const onValueChange = (newValue: string) => {
    // Handle case where empty option is selected
    if (!newValue) {
      return;
    }
    const index = value.indexOf(newValue);
    if (index === -1) {
      onChange([...value, newValue]);
    } else {
      onChange(value.filter((_, i) => i !== index));
    }
  };

  if (readonly) {
    return <Text>{valueLabel}</Text>;
  }

  return (
    <Select
      size={size}
      // value={value.join(', ')}
      // selectedValue={value || undefined}
      // selectedValues={value}
      value={''}
      selectedValue={''}
      initialLabel={valueLabel || undefined}
      selectedLabel={valueLabel}
      onValueChange={onValueChange}
      // multiple
    >
      <SelectTrigger size={size} variant="outline">
        <SelectInput
          size={size}
          placeholder={placeholder}
          value={valueLabel}
          multiple
        />
        <SelectIcon as={ChevronDownIcon} mr="$3" />
      </SelectTrigger>
      <SelectPortal multiple>
        <SelectBackdrop />
        <SelectContent>
          <SelectDragIndicatorWrapper>
            <SelectDragIndicator />
          </SelectDragIndicatorWrapper>
          {Object.entries(options).map(([label, optionValue]) => {
            const isSelected = value.includes(optionValue);
            return (
              <SelectItem
                key={label + 'selectOption'}
                label={label}
                value={optionValue}
                isSelected={isSelected}
              />
            );
          })}
          <SelectItem value="" label="" isSelected />
        </SelectContent>
      </SelectPortal>
    </Select>
  );
};
