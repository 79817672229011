import React from 'react';
import {
  AddressFormFieldData,
  DateRangeData,
  DateRangeFormFieldData,
  TimeframeData,
  TimeframeFormFieldData,
  TextFormFieldData,
  RadioFormFieldData,
  CheckboxGroupFormFieldData,
  SelectFormFieldData,
  CheckboxFormFieldData,
  CustomStringsFormFieldData,
  ImagesFormFieldData,
  PartsFormFieldData,
  PartsUpdatesFormFieldData,
  TrucksFormFieldData,
  TruckUpdatesFormFieldData,
  MultiSelectFormFieldData,
  LocationData,
} from './specific';
import { FormControl } from '@gluestack-ui/themed';
import { Schemas } from '@tonic/central-specialties-utils';

export type FormFieldType =
  | 'SectionLabel'
  | 'Text'
  | 'Checkbox'
  | 'CheckboxGroup'
  | 'Radio'
  | 'Select'
  | 'MultiSelect'
  | 'Timeframe'
  | 'Address'
  | 'CustomStrings'
  | 'Images'
  | 'PartRequests'
  | 'PartsUpdates'
  | 'TruckRequests'
  | 'TruckUpdates'
  | 'DateRange';

export type FormFieldValue<T extends FormFieldType> = T extends 'Text'
  ? string
  : T extends 'Checkbox'
    ? boolean
    : T extends 'CheckboxGroup' | 'CustomStrings' | 'MultiSelect'
      ? string[]
      : T extends 'Radio' | 'Select'
        ? string
        : T extends 'Address'
          ? LocationData
          : T extends 'Timeframe'
            ? TimeframeData
            : T extends 'Images'
              ? Schemas['ImageModel'][]
              : T extends 'PartRequests' | 'PartsUpdates'
                ? Schemas['PartRequestResponse'][]
                : T extends 'TruckRequests' | 'TruckUpdates'
                  ? Schemas['TruckingRequestResponse']
                  : T extends 'DateRange'
                    ? DateRangeData
                    : never;

export type FormFieldValueType = string | boolean | string[] | TimeframeData;
export type FormFieldTypeOf<T extends FormFieldValueType> = T extends string
  ? 'Text' | 'Radio' | 'Address' | 'Select'
  : T extends boolean
    ? 'Checkbox'
    : T extends string[]
      ? 'CheckboxGroup' | 'CustomStrings' | 'MultiSelect'
      : T extends TimeframeData
        ? 'Timeframe'
        : T extends Schemas['ImageModel'][]
          ? 'Images'
          : T extends Schemas['PartRequestResponse'][]
            ? 'PartRequests' | 'PartsUpdates'
            : T extends Schemas['TruckingRequestResponse']
              ? 'TruckRequests' | 'TruckUpdates'
              : T extends DateRangeData
                ? 'DateRange'
                : never;

export const isFormFieldDeclaration = (
  val: any,
): val is FormFieldData<FormFieldType> => {
  return 'type' in val && 'dataFieldKey' in val;
};

// interface CustomFormFieldData {
//   type: 'Custom',
//   Element: ReactElement;
// }

interface SharedFormFieldData<T extends FormFieldType> {
  type: T;
  /**
   * The key(s) in the form data object that this field is responsible for
   */
  dataFieldKey: string | string[];
  label: string;
  value: FormFieldValue<T>;
  onChange?: (
    value: FormFieldValue<T>,
    formDate: Record<string, any>,
    setFormData: (data: Record<string, any>) => void,
  ) => void;
  /**
   * Take the value for that field and return an error string to display below the field if the value is invalid
   */
  validator?: (
    val: FormFieldValue<T>,
    formData?: Record<string, any>,
  ) => string | null;
  // TODO: Refactor TimeInput so that it doesn't have to handle the splitting and recombining of the targetDate internally
  /**
   * Take the direct form data values the input handles and make any changes needed before the data is submitted.
   * If passed, it will be run before the form data is submitted to the onSubmit handler
   */
  preSubmitTransform?: (val: FormFieldValue<T>) => FormFieldValue<T>;
  /**
   * If true, the field will be styled as required and if submit is pressed whe the field is nullish, the field
   * will be styled as invalid and an error message will display
   *
   * WARNING: if the field is hidden, required will not be enforced!
   */
  required?: boolean;
  /**
   * If true, it will be marked as required, but the formManager won't block submission automatically, instead it's up to you to validate with validator (e.g. for admin forms that don't require required fields on admin side if no changes were made to admin side)
   */
  softRequired?: boolean;
  disabled?: boolean | ((data: Record<string, any>) => boolean);
  helperText?: string;
  readonly?: boolean;
  enableDynamicForm?: boolean;
  /**
   * If true, errorText will show and the field will be styled as invalid
   */
  invalid?: boolean;
  errorText?: string;
  /**
   * If true, the field itself (not the surrounding elements) will be replaced with a spinner
   */
  pending?: boolean;
  /**
   * Function that takes all the form data and returns whether this field should be hidden
   *
   * WARNING: If hidden, required will not be enforced!
   */
  hidden?: (formData: Record<string, FormFieldValue<FormFieldType>>) => boolean;
  size?: 'sm' | 'md' | 'lg';
  // nullable?: boolean;
  labelRightElement?: FormFieldData<FormFieldType> | React.ReactElement;
  formControlProps?: Partial<React.ComponentProps<typeof FormControl>>;
}

type SpecificFormFieldProps<T extends FormFieldType> = T extends 'Text'
  ? TextFormFieldData
  : T extends 'Checkbox'
    ? CheckboxFormFieldData
    : T extends 'CheckboxGroup'
      ? CheckboxGroupFormFieldData
      : T extends 'Radio'
        ? RadioFormFieldData
        : T extends 'Select'
          ? SelectFormFieldData
          : T extends 'Timeframe'
            ? TimeframeFormFieldData
            : T extends 'Address'
              ? AddressFormFieldData
              : T extends 'CustomStrings'
                ? CustomStringsFormFieldData
                : T extends 'Images'
                  ? ImagesFormFieldData
                  : T extends 'PartRequests'
                    ? PartsFormFieldData
                    : T extends 'PartsUpdates'
                      ? PartsUpdatesFormFieldData
                      : T extends 'TruckRequests'
                        ? TrucksFormFieldData
                        : T extends 'TruckUpdates'
                          ? TruckUpdatesFormFieldData
                          : T extends 'DateRange'
                            ? DateRangeFormFieldData
                            : T extends 'MultiSelect'
                              ? MultiSelectFormFieldData
                              : never;

export type FormFieldData<T extends FormFieldType = FormFieldType> =
  SpecificFormFieldProps<T> & SharedFormFieldData<T>;
