import { Schemas } from '@tonic/central-specialties-utils';
import React from 'react';
import {
  Card,
  ColorToken,
  Icons,
  IndicatorDot,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { HStack, Icon, Text } from '@gluestack-ui/themed';
import moment from 'moment';

interface PartsRequestCardProps {
  partsRequest: Schemas['PartRequestTicketResponse'];
  onPress: (data: Schemas['PartRequestTicketResponse']) => void;
  cardProps?: React.ComponentProps<typeof Card>;
}

export const PartsRequestCard = ({
  partsRequest,
  onPress,
  cardProps,
}: PartsRequestCardProps) => {
  return (
    <Card onPress={() => onPress(partsRequest)} {...cardProps}>
      <HStack alignItems="center" justifyContent="center" flex={1} gap="$4">
        <VStack flex={1} gap="$0.5" justifyContent="space-between">
          <VStack gap="$0.5">
            <Text bold>
              Request {partsRequest.requestNumber || '(number pending)'}
            </Text>
            {!!partsRequest?.workOrderNumber && (
              <Text>Purchase order: {partsRequest.purchaseOrderNumber}</Text>
            )}
            {!!partsRequest?.assignedTo?.fullName && (
              <Text>
                Assigned: {partsRequest.assignedTo.fullName} -{' '}
                {partsRequest.assignedTo.employeeNumber}
              </Text>
            )}
            <Text>Equipment number: {partsRequest.equipment.number}</Text>
            <Text>Equipment name: {partsRequest.equipment.description}</Text>
            <Text>Submitter: {partsRequest?.createdBy?.fullName}</Text>
            <Text>
              Requested on:{' '}
              {moment(partsRequest.createdAt)
                .utc(true)
                .tz('America/Chicago')
                .format('M/D/YY')}
            </Text>
          </VStack>
          <HStack gap="$1" alignItems="center">
            <IndicatorDot
              color={`$priority${partsRequest.priority}` as ColorToken}
              size="sm"
            />
            <Text>{partsRequest.priority}</Text>
          </HStack>
        </VStack>
        {partsRequest.hasMessages && (
          <Icon as={Icons.MessageCircle} size="xl" color="$textSubtle" />
        )}
      </HStack>
    </Card>
  );
};
