import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import { Form, FormFieldsObject, FormProps } from '../../Form.tsx';
import { Divider, HStack, Icon } from '@gluestack-ui/themed';
import { useFormJobOptions } from '../../hooks/useFormJobOptions.ts';
import { useFormEquipmentOptions } from '../../hooks/useFormEquipmentOptions.ts';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getLatLongFromAddress } from '../../../../../utils/getLatLongFromAddress.ts';
import { extractImageDataFromUrl } from '../../../../../utils/extractImageDataFromUrl.ts';
import {
  Center,
  Icons,
  Spinner,
  Text,
  useAlert,
} from '@tonic/central-specialties-ui-themed';
import moment from 'moment';

interface PartsRequestFormProps {
  partsRequestId?: string;
  isReadonlyMode?: boolean;
  /**
   * If true, the form will show an additional form group to the right of the main form group,
   * allowing assignment and a few other fields.
   */
  isAdminMode?: boolean;
  onSuccess?: () => void;
  formProps?: Partial<FormProps>;
}

// Simple rapper to fetch a passed item by ID, since entity details in list responses are being removed
export const PartsRequestForm = ({
  partsRequestId,
  ...restProps
}: PartsRequestFormProps) => {
  const { alert } = useAlert();
  const {
    data: partsRequest,
    isPending,
    error,
  } = useQuery({
    queryKey: ['partsRequest', partsRequestId],
    queryFn: async () =>
      DB.GET('/part-requests/{id}', {
        params: { path: { id: partsRequestId } },
      }).then(
        (res: { data: Schemas['PartRequestTicketResponse'] }) => res.data,
      ),
    enabled: !!partsRequestId,
  });

  if (isPending && partsRequestId !== undefined) {
    return (
      <Center h="$full" minHeight="$96" flex={1}>
        <Spinner />
      </Center>
    );
  }

  if (error) {
    alert({
      message: 'There was an error fetching the parts request.',
      status: 'error',
      timeout: 10000,
    });
    return (
      <Center w="$full" h="$full" gap="$6">
        <Icon as={Icons.AlertCircle} color="$error500" w="$16" h="$16" />
        <Text size="lg" color="$error500">
          There was an error retrieving parts request {partsRequestId}
        </Text>
      </Center>
    );
  }

  return <PartsRequestFormInner partsRequest={partsRequest} {...restProps} />;
};

interface PartsRequestFormInnerProps extends PartsRequestFormProps {
  partsRequest?: Schemas['PartRequestTicketResponse'];
}

export const PartsRequestFormInner = ({
  partsRequest,
  isReadonlyMode,
  isAdminMode = false,
  onSuccess,
  formProps,
}: PartsRequestFormInnerProps) => {
  const { alert } = useAlert();
  const [defaults, setDefaults] = useState<
    Schemas['TimeCardEntryRequest'] | null
  >(null);
  const [enableDynamicFormAddress, setEnableDynamicFormAddress] =
    useState(true);

  const mode = useMemo<'create' | 'edit' | 'archive'>(() => {
    switch (true) {
      case partsRequest && partsRequest.status === 'Archived':
        return 'archive';
      case partsRequest && 'status' in partsRequest:
        return 'edit';
      default:
        return 'create';
    }
  }, [partsRequest]);

  ///-----------------------------------------------------------------------
  // FORM OPTIONS FETCHING
  ///-----------------------------------------------------------------------

  const {
    allJobs,
    jobOptions,
    isPending: jobOptionsPending,
    error: jobOptionsFetchError,
  } = useFormJobOptions();
  const {
    equipmentOptions,
    isPending: equipmentOptionsPending,
    error: equipmentOptionsFetchError,
  } = useFormEquipmentOptions();
  const {
    data: potentialAssignees,
    isPending: potentialAssigneesPending,
    error: potentialAssigneesFetchError,
  } = useQuery({
    queryKey: ['partsRequestAssignees'],
    queryFn: async () =>
      DB.GET('/users/by-role/{roleName}', {
        params: {
          path: {
            roleName: 'PartsTeam',
          },
        },
      }).then((res: { data: Schemas['UserModel'][] }) => res.data),
    enabled: isAdminMode,
  });

  ///-----------------------------------------------------------------------
  // HANDLE OPTION FETCH ERRORS
  ///-----------------------------------------------------------------------
  useEffect(() => {
    if (
      !isReadonlyMode &&
      (jobOptionsFetchError ||
        equipmentOptionsFetchError ||
        (isAdminMode && potentialAssigneesFetchError))
    ) {
      alert({
        message:
          'There was an error fetching the job, equipment, or assignee options.',
        status: 'error',
        timeout: 10000,
      });
    }
  }, [
    jobOptionsFetchError,
    equipmentOptionsFetchError,
    potentialAssigneesFetchError,
    isAdminMode,
    isReadonlyMode,
    alert,
  ]);

  ///-----------------------------------------------------------------------
  // MUTATION REQUESTS
  ///-----------------------------------------------------------------------

  // CREATE
  const { mutateAsync: createPartsRequest } = useMutation({
    mutationFn: (data: Schemas['PartRequestTicketRequest']) =>
      DB.POST('/part-requests', { body: data }),
  });

  // UPDATE
  const { mutateAsync: updatePartsRequest } = useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: string;
      data: Schemas['PartRequestTicketRequest'];
    }) =>
      DB.PUT('/part-requests/{id}', { params: { path: { id } }, body: data }),
  });

  // ASSIGN TICKET
  const { mutateAsync: assignTicket } = useMutation({
    mutationFn: ({ id, userId }: { id: string; userId: string }) =>
      DB.POST('/part-requests/{id}/assign', {
        params: {
          path: {
            id,
          },
        },
        body: {
          userId,
        },
      }),
  });

  // UPDATE TICKET STATUS
  const { mutateAsync: updatePartsRequestStatus } = useMutation({
    mutationFn: ({
      id,
      status,
    }: {
      id: string;
      status: Schemas['PartRequestStatus'];
    }) =>
      DB.POST('/part-requests/{id}/status', {
        params: { path: { id } },
        body: { status },
      }),
  });

  // UPDATE PART STATUS
  const { mutateAsync: updatePartStatus } = useMutation({
    mutationFn: ({
      partId,
      ticketId,
      status,
    }: {
      partId: string;
      ticketId: string;
      status: Schemas['PartRequestStatus'];
    }) =>
      DB.POST('/part-requests/{id}/part/{partId}/status', {
        params: {
          path: {
            id: ticketId,
            partId,
          },
        },
        body: {
          status,
        },
      }),
  });

  // UPLOAD IMAGES
  const { mutateAsync: uploadImages } = useMutation({
    mutationFn: async (images: Schemas['ImageModel'][]): Promise<string[]> => {
      const newOnly = images.filter((img) => img?.isNew);
      const existing = images.filter((img) => !img?.isNew);
      for (const img of newOnly) {
        const imageData = await extractImageDataFromUrl(img.url);
        const { data: newImgData, response: newImgResponse } = await DB.POST(
          '/part-requests/image',
          {
            body: { imageData },
          },
        );

        if (newImgResponse.ok) {
          existing.push(newImgData);
        }
      }
      return existing.map((img) => img.id);
    },
  });

  // SUBMIT
  const submit = useCallback(
    async (data: typeof defaults) => {
      const {
        jobId,
        equipmentNumber,
        priority,
        address,
        additionalNotes,

        // admin fields
        parts: partsWithRawImages,
        partsTeamNotes,
        // used separately:
        status,
        assignedTo,
      } = data;

      let { latitude, longitude } = data;

      // If lat and long aren't specified directly, try to get them from the address
      if (latitude === 0 && longitude === 0) {
        const { latitude: latFromAddress, longitude: longFromAddress } =
          await getLatLongFromAddress(address);
        latitude = latFromAddress;
        longitude = longFromAddress;
      }

      const equipmentId = equipmentOptions
        ? equipmentOptions.find(
            (eq: Schemas['EquipmentModel']) => eq.number === equipmentNumber,
          )?.id
        : '';

      if (!equipmentId) {
        alert({
          status: 'error',
          message: `Equipment number "${equipmentNumber}" not found`,
          timeout: 10000,
        });
        return;
      }

      // upload images for each part and replace their images with imageIds
      const parts: Schemas['PartRequestResponse'][] = await Promise.all(
        partsWithRawImages.map(async (part: Schemas['PartRequestResponse']) => {
          const imageIds = await uploadImages(part.images);
          return {
            partNumber: part.partNumber,
            purchaseOrderNumber: part.purchaseOrderNumber,
            deliveryNotes: part.deliveryNotes,
            status: part.status,
            imageIds,
            id: part?.id, // if it's an existing part, this indicates that it should be updated
          } as Schemas['PartRequestRequest'];
        }),
      );

      const payload = {
        jobId,
        equipmentId,
        priority,
        address,
        latitude,
        longitude,
        additionalNotes,
        parts,
        partsTeamNotes,
      };

      const adminModeUpdates = async (
        newPartsRequest: Schemas['PartRequestTicketResponse'],
      ) => {
        if (!isAdminMode || mode === 'archive') {
          return;
        }
        const needsToUpdateTicketStatus =
          (mode === 'create' && !!status && status !== 'New') ||
          (mode === 'edit' && !!status && status !== partsRequest!.status);

        const needsToUpdateAssignedTo =
          (mode === 'create' && !!assignedTo) ||
          (mode === 'edit' &&
            !!assignedTo &&
            assignedTo !== defaults?.assignedTo);

        // if a status was specified, update it
        if (needsToUpdateTicketStatus) {
          try {
            const { error } = await updatePartsRequestStatus({
              id: newPartsRequest.id,
              status,
            });
            if (error) {
              throw error;
            }
          } catch (e) {
            alert({
              status: 'error',
              message:
                'Failed to update ticket status. Open the new ticket and try editing again',
              timeout: 10000,
            });
          }
        }

        // if assignment was made upon creation, update it
        if (needsToUpdateAssignedTo) {
          try {
            const { error } = await assignTicket({
              id: newPartsRequest.id,
              userId: assignedTo,
            });
            if (error) {
              throw error;
            }
          } catch (e) {
            alert({
              status: 'error',
              message:
                'Failed to assign ticket. Open the new ticket and try editing again',
              timeout: 10000,
            });
          }
        }

        // Update individual part statuses if specified
        for (const partEntity of newPartsRequest.parts) {
          const specifiedStatus = parts.find((part) => {
            console.log({ part, partEntity });
            return (
              partEntity.partNumber.toString() === part.partNumber.toString()
            );
          }).status;
          if (!specifiedStatus || specifiedStatus === partEntity.status) {
            continue;
          }

          try {
            const { error } = await updatePartStatus({
              partId: partEntity.id,
              ticketId: newPartsRequest.id,
              status: specifiedStatus,
            });
            if (error) {
              throw error;
            }
          } catch (e) {
            alert({
              status: 'error',
              message: `Failed to update part status for part ${partEntity.partNumber}. Open the new ticket and try editing again`,
              timeout: 10000,
            });
          }
        }
      };

      switch (mode) {
        case 'create':
          try {
            const { data: newPartsRequest, response } =
              await createPartsRequest(payload);
            if (!response.ok) {
              throw new Error('Failed to create parts request');
            }
            if (isAdminMode) {
              await adminModeUpdates(newPartsRequest);
            }
            alert({
              status: 'success',
              message: 'Success! Ticket created',
              timeout: 5000,
            });
            onSuccess && onSuccess();
          } catch (e) {
            alert({
              status: 'error',
              message: 'Failed to created parts request',
              timeout: 10000,
            });
          }
          break;

        case 'edit':
          try {
            const { data: updatedPartsRequest, response } =
              await updatePartsRequest({
                id: partsRequest!.id,
                data: payload,
              });
            if (!response.ok) {
              throw new Error('Failed to update parts request');
            }
            if (isAdminMode) {
              await adminModeUpdates(updatedPartsRequest);
            }
            alert({
              status: 'success',
              message: 'Success! Changes saved.',
              timeout: 5000,
            });
            onSuccess && onSuccess();
          } catch (e) {
            alert({
              status: 'error',
              message: 'Failed to update parts request',
              timeout: 10000,
            });
          }
          break;

        case 'archive':
          try {
            const { error } = await updatePartsRequestStatus({
              id: partsRequest!.id,
              status: 'New',
            });
            if (error) {
              throw error;
            }
            alert({
              status: 'success',
              message: 'Parts request unarchived',
              timeout: 5000,
            });
            onSuccess && onSuccess();
          } catch (e) {
            alert({
              status: 'error',
              message: 'Failed to unarchive parts request',
              timeout: 10000,
            });
          }
          break;
        default:
          console.error(
            'Something is wrong with the "mode" in PartsRequestForm',
          );
          break;
      }
    },
    [
      mode,
      defaults,
      uploadImages,
      createPartsRequest,
      isAdminMode,
      alert,
      onSuccess,
      updatePartsRequest,
      partsRequest,
      assignTicket,
      equipmentOptions,
      updatePartStatus,
    ],
  );

  ///-----------------------------------------------------------------------
  // SET DEFAULTS
  ///-----------------------------------------------------------------------
  useEffect(() => {
    const primaryFieldDefaults = {
      jobId: partsRequest?.job?.id || '',
      equipmentNumber: partsRequest?.equipment?.number || '',
      parts: partsRequest?.parts || [],
      priority: partsRequest?.priority || '',
      address: partsRequest?.address || '',
      latitude: partsRequest?.latitude || 0,
      longitude: partsRequest?.longitude || 0,
      additionalNotes: partsRequest?.additionalNotes || '',
    };

    const adminFieldDefaults = {
      assignedTo: partsRequest?.assignedTo?.id || '',
      status: partsRequest?.status || '',
      createdAt: partsRequest?.createdAt
        ? moment(partsRequest.createdAt)
            .utc(true)
            .tz('America/Chicago')
            .format('h:mma M/D/YY')
        : '',
      reporter: partsRequest?.createdBy
        ? `${partsRequest.createdBy.fullName} - ${partsRequest.createdBy.employeeNumber}`
        : '',
      partsTeamNotes: partsRequest?.partsTeamNotes || '',
    };

    setDefaults(
      isAdminMode
        ? { ...primaryFieldDefaults, ...adminFieldDefaults }
        : primaryFieldDefaults,
    );
  }, [partsRequest, isAdminMode]);

  const formFields = useMemo(() => {
    const primaryFields: FormFieldsObject = {
      jobNumber: {
        type: 'Select',
        dataFieldKey: 'jobId',
        label: 'Job number',
        placeholder: 'Select job number',
        options: jobOptions,
        required: true,
        onChange: () => {
          setEnableDynamicFormAddress(true);
        },
      },

      jobName: {
        type: 'Text',
        dataFieldKey: 'jobName',
        readonly: true,
        populate: (data: any) => {
          if (!allJobs) {
            return 'No description';
          }
          return (
            allJobs.find((j: Schemas['JobCodeModel']) => j.id === data.jobId)
              ?.description || 'No description'
          );
        },
        label: 'Job name',
      },

      parts: {
        type: 'PartRequests',
        dataFieldKey: 'parts',
        label: 'Parts',
        required: true,
        size: 'lg',
        validator: (value) => {
          if (value.some((part) => !part.partNumber)) {
            return 'All parts must have a part number';
          }
          // CSPEC-331 remove part number validation
          const isValid = value;
          if (!isValid) {
            return 'Part numbers is not a valid entry.';
          }
        },
      },

      equipmentNumber: {
        type: 'Text',
        dataFieldKey: 'equipmentNumber',
        label: 'Equipment number',
        placeholder: 'Enter equipment number',
        required: true,
      },

      equipmentDescription: {
        type: 'Text',
        dataFieldKey: 'equipmentDescription',
        label: 'Equipment description',
        populate: (formData: any) =>
          (equipmentOptions &&
            equipmentOptions.find(
              (opt: Schemas['JobCodeModel']) =>
                opt.number === formData.equipmentNumber,
            )?.description) ||
          'No description',
        readonly: true,
      },

      priority: {
        type: 'Select',
        dataFieldKey: 'priority',
        label: 'Priority rating',
        options: {
          'Critical - Job is halted': 'Critical',
          'High - Issue significantly hinders progress': 'High',
          'Medium - Issue hinders progress': 'Medium',
          'Low - Low priority': 'Low',
        },
        required: true,
        placeholder: 'Select priority',
      },

      additionalNotes: {
        type: 'Text',
        label: 'Additional notes',
        multiline: true,
        dataFieldKey: 'additionalNotes',
      },

      deliveryLocation: {
        type: 'Address',
        dataFieldKey: ['address', 'latitude', 'longitude'],
        label: 'Delivery location',
        dynamicUpdate: (data: any) => {
          if (!allJobs || isReadonlyMode) {
            return null;
          }

          if (
            data &&
            data?.createdAt &&
            data?.jobId === partsRequest?.job?.id
          ) {
            setEnableDynamicFormAddress(false);
            return null;
          }

          const jobAddress =
            allJobs.find((j: Schemas['JobCodeModel']) => j.id === data.jobId)
              ?.address || '';
          setEnableDynamicFormAddress(false);
          return jobAddress;
        },
        enableDynamicForm: enableDynamicFormAddress,
        required: true,
      },
    };

    const adminFields: FormFieldsObject = {
      partsAssignee: {
        type: 'Select',
        label: 'Parts Assignee',
        dataFieldKey: 'assignedTo',
        options: Object.fromEntries(
          (potentialAssignees || []).map((partsPerson) => [
            `${partsPerson.fullName} - ${partsPerson.employeeNumber}`,
            partsPerson.id,
          ]),
        ),
        placeholder: 'Assign Ticket',
        isSuperSelect: true,
      },

      status: {
        type: 'Select',
        label: 'Ticket Status',
        dataFieldKey: 'status',
        options: [
          'New',
          'Assigned',
          'PartiallyFulfilled',
          'Ordered',
          'Delivered',
          'Archived',
        ],
        placeholder: 'Status',
        isSuperSelect: true,
      },

      partsUpdates: {
        type: 'PartsUpdates',
        label: 'Requested parts',
        dataFieldKey: 'parts',
        size: 'lg',
        formControlProps: {
          mt: '$10',
        },
      },

      _ticketDetailsSectionLabel: {
        type: 'SectionLabel',
        label: 'Ticket Details',
        size: 'lg',
        readonly: true,
        formControlProps: {
          mt: '$10',
        },
      },

      timeSubmitted: {
        type: 'Text',
        label: 'Time Submitted',
        dataFieldKey: 'createdAt',
        readonly: true,
      },

      reporter: {
        type: 'Text',
        label: 'Reporter',
        dataFieldKey: 'reporter',
        readonly: true,
      },

      partsTeamNotes: {
        type: 'Text',
        label: 'Parts team notes',
        dataFieldKey: 'partsTeamNotes',
        multiline: true,
        placeholder: 'Enter notes',
      },
    };
    return isAdminMode ? [primaryFields, adminFields] : primaryFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaults,
    allJobs,
    isAdminMode,
    jobOptions,
    equipmentOptions,
    potentialAssignees,
    enableDynamicFormAddress,
  ]);

  if (
    (!!partsRequest && !defaults) ||
    equipmentOptionsPending ||
    jobOptionsPending ||
    (isAdminMode && potentialAssigneesPending)
  ) {
    return (
      <Center w="$full" h="$full">
        <Spinner />
      </Center>
    );
  }

  return (
    <Form
      formData={defaults}
      fields={formFields}
      readonly={!!isReadonlyMode}
      onSubmit={submit}
      Container={({ children }) => (
        <HStack flex={1} h="$full" gap="$8">
          {children}
        </HStack>
      )}
      Divider={() => <Divider orientation="vertical" subtle />}
      {...formProps}
    />
  );
};
