import { HStack } from '@gluestack-ui/themed';
import { SidebarNav } from '../../components/navigation/SidebarNav.tsx';
import { Outlet } from 'react-router-dom';
import { Center } from '@tonic/central-specialties-ui-themed';

export const AdminPanel = () => {
  return (
    <HStack w="$full" h="$full" bg="$white">
      <SidebarNav />
      <Center flex={1}>
        <Outlet />
      </Center>
    </HStack>
  );
};
