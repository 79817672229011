import React, { useEffect, useMemo, useState } from 'react';
import { PageWrapper } from '../../../components/general/PageWrapper.tsx';
import {
  AlertDialog,
  AlertDialogBackdrop,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  Button,
  ButtonIcon,
  ButtonText,
  HStack,
  ScrollView,
  Text,
} from '@gluestack-ui/themed';
import {
  Box,
  ButtonAsync,
  Center,
  Divider,
  Icons,
  Spinner,
  useAlert,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import { EntityCardColumn } from '../../../components/general/EntityCardExplorer/EntityCardColumnsView/EntityCardColumn/EntityCardColumn.tsx';
import { UserForm } from '../../../components/general/Form/specific/UserForm/UserForm.tsx';
import { NewUserFormModal } from '../../../components/general/Form/specific/UserForm/NewUserFormModal.tsx';
import { UserCard } from '../../../components/general/cards/UserCard.tsx';
import { DisabledUsersModal } from './DisabledUsersModal/DisabledUsersModal.tsx';
import { ImportNewUserModal } from '../../../components/general/Form/specific/UserForm/ImportNewUserModal.tsx';

export type UserModel = Schemas['UserModel'];

interface UsersPageProps {}

export const UsersPage = (_props: UsersPageProps) => {
  const [focusedUser, setFocusedUser] = useState<UserModel | null>(null);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showDisabledUsersModal, setShowDisabledUsersModal] = useState(false);
  const [showImportNewUserModal, setShowImportNewUserModal] = useState(false);

  const {
    data: splitUsers,
    isPending: usersPending,
    refetch: refetchUsers,
  } = useQuery({
    queryKey: ['users'],
    queryFn: async () =>
      await DB.GET('/users').then((res: { data: UserModel[] }) =>
        res.data.reduce(
          (acc, user) => {
            if (user.isDisabled) {
              acc.Disabled.push(user);
            } else {
              acc.Active.push(user);
            }
            return acc;
          },
          { Active: [], Disabled: [] },
        ),
      ),
  });

  const { Active: users, Disabled: disabledUsers } = useMemo(() => {
    if (splitUsers) {
      return splitUsers;
    }
    return { Active: [], Disabled: [] };
  }, [splitUsers]);

  // Ensure only one modal is open at a time
  useEffect(() => {
    if (showNewUserModal) {
      setShowDisabledUsersModal(false);
      setShowImportNewUserModal(false);
    } else if (showDisabledUsersModal) {
      setShowNewUserModal(false);
      setShowImportNewUserModal(false);
    } else if (showImportNewUserModal) {
      setShowDisabledUsersModal(false);
      setShowNewUserModal(false);
    }
  }, [showNewUserModal, showDisabledUsersModal, showImportNewUserModal]);

  return (
    <PageWrapper
      ToolbarContent={
        <HStack w="$full" justifyContent="flex-end" gap="$12">
          <Button
            variant="ghost"
            size="lg"
            w="$40"
            $hover={{ _icon: { color: '$primary800' } }}
            onPress={() => setShowDisabledUsersModal(true)}
          >
            <ButtonIcon color="$primary600" as={Icons.Archive} />
            <ButtonText>Disabled Users</ButtonText>
          </Button>
          <Button size="lg" w="$40" onPress={() => setShowNewUserModal(true)}>
            <ButtonIcon as={Icons.Plus} />
            <ButtonText>New User</ButtonText>
          </Button>
          <Button
            size="lg"
            onPress={() => setShowImportNewUserModal(true)}
            gap="$2"
          >
            <ButtonIcon as={Icons.Upload} />
            <ButtonText>Import Users Data</ButtonText>
          </Button>
        </HStack>
      }
    >
      <HStack flex={1} w="$full">
        <VStack flex={1} h="$full" alignItems="center" maxWidth={500} pt="$4">
          <EntityCardColumn
            containerProps={{
              maxWidth: 300,
            }}
            searchByPaths={['fullName', 'employeeNumber']}
            items={users}
            pending={usersPending}
            categoryName="Users"
            CardComponent={({ data: user }) => (
              <UserCard
                user={user}
                onPress={() => setFocusedUser(user)}
                isSelected={!!focusedUser && focusedUser.id === user.id}
              />
            )}
          />
        </VStack>
        <Divider subtle orientation="vertical" />
        <VStack flex={1}>
          <UserDetailsSection
            user={focusedUser}
            key={focusedUser?.id}
            onUpdate={refetchUsers}
            onDisableSuccess={() => {
              refetchUsers();
              setFocusedUser(null);
            }}
          />
        </VStack>
      </HStack>

      <NewUserFormModal
        isOpen={showNewUserModal}
        onClose={() => setShowNewUserModal(false)}
        onSuccess={refetchUsers}
      />

      <ImportNewUserModal
        isOpen={showImportNewUserModal}
        onClose={() => setShowImportNewUserModal(false)}
        onSuccess={refetchUsers}
      />

      {/* Additional wrapping condition so it always opens in fresh state */}
      {showDisabledUsersModal && (
        <DisabledUsersModal
          isOpen={showDisabledUsersModal}
          onClose={() => setShowDisabledUsersModal(false)}
          disabledUsers={disabledUsers}
          refetch={refetchUsers}
        />
      )}
    </PageWrapper>
  );
};

interface UserDetailsSectionProps {
  user: UserModel | null;
  onUpdate?: () => void;
  onDisableSuccess?: () => void;
}

export const UserDetailsSection = ({
  user,
  onUpdate,
  onDisableSuccess,
}: UserDetailsSectionProps) => {
  const [showDisableUserDialog, setShowDisableUserDialog] = useState(false);

  const {
    data: userWithRoles,
    isPending: userWithRolesPending,
    refetch: refetchUserWithRoles,
    error: getUserWithRolesError,
  } = useQuery({
    queryKey: ['userWithRoles', user?.id || 'NO_USER'],
    queryFn: () =>
      DB.GET('/users/{userId}', {
        params: {
          path: {
            userId: user?.id || 'NO_USER',
          },
        },
      }).then((res: { data: Schemas['UserModelWithRoles'] }) => res.data),
    enabled: !!user,
  });

  useEffect(() => {
    if (user) {
      refetchUserWithRoles();
    }
  }, [user, refetchUserWithRoles]);

  if (!user) {
    return (
      <Center h="$full" w="$full">
        <Text size="lg">Select user to view</Text>
      </Center>
    );
  }

  return (
    <VStack h="$full" space="lg">
      <HStack
        w="$full"
        h="$16"
        bg="$primary700"
        px="$6"
        justifyContent="space-between"
        alignItems="center"
      >
        <HStack flex={1}></HStack>
        <Center flex={1}>
          <Text bold size="xl" color="$white">
            {user.fullName}
          </Text>
        </Center>
        <HStack flex={1} justifyContent="flex-end">
          <Button
            onPress={() => setShowDisableUserDialog(true)}
            borderColor="$white"
            variant="outline"
            size="lg"
            w="$full"
            maxWidth="$40"
          >
            <ButtonText color="$white">Disable user</ButtonText>
          </Button>
        </HStack>
      </HStack>

      {/*<Center h="$16" bg="$primary700">*/}
      {/*</Center>*/}
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{ flex: 1 }}>
        <VStack flex={1} px="$12" pt="$10">
          {userWithRolesPending && (
            <Center flex={1} h="$full">
              <Spinner />
            </Center>
          )}
          {!!getUserWithRolesError &&
            !userWithRolesPending &&
            !userWithRoles(
              <Text size="lg" color="$error500">
                Error loading user details
              </Text>,
            )}
          {!!userWithRoles && (
            <UserForm
              onSuccess={() => {
                onUpdate();
                refetchUserWithRoles();
              }}
              user={userWithRoles}
              formProps={{
                containerProps: {
                  width: '$full',
                  maxWidth: 600,
                  alignSelf: 'center',
                },
              }}
            />
          )}
        </VStack>
      </ScrollView>

      <DisableUserDialog
        isOpen={showDisableUserDialog}
        userId={user.id}
        onClose={() => setShowDisableUserDialog(false)}
        onSuccess={onDisableSuccess}
      />
    </VStack>
  );
};

interface DisableUserDialog {
  isOpen: boolean;
  userId: string;
  onClose: () => void;
  onSuccess?: () => void;
}

const DisableUserDialog = ({
  isOpen,
  userId,
  onClose,
  onSuccess,
}: DisableUserDialog) => {
  const { alert } = useAlert();

  const { mutateAsync: disableUser, isPending: disableUserPending } =
    useMutation({
      mutationFn: (id: string) =>
        DB.POST('/users/{userId}/disabled-status', {
          params: {
            path: {
              userId: id,
            },
          },
          body: {
            isDisabled: true,
          },
        })
          .then(({ response }) => {
            if (response.ok) {
              alert({
                status: 'success',
                message: 'Success! User disabled.',
                dismissible: true,
                timeout: 5000,
              });
              onSuccess && onSuccess();
            } else {
              alert({
                status: 'error',
                message: 'Something went wrong, could not disable user',
                timeout: 10000,
              });
            }
            onClose();
          })
          .catch((err) => console.error(err)),
    });

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} size="sm">
      <AlertDialogBackdrop />
      <AlertDialogContent py="$6" gap="$8">
        <AlertDialogBody>
          <Center w="$full">
            <Box w="$48">
              <Text bold size="lg" textAlign="center">
                Are you sure you want to disable this user?
              </Text>
            </Box>
          </Center>
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="space-evenly" gap="$4">
          <Button size="lg" variant="outline" onPress={onClose} w={150}>
            <ButtonText>Cancel</ButtonText>
          </Button>
          <ButtonAsync
            size="lg"
            pending={disableUserPending}
            onPress={() => disableUser(userId)}
            w={150}
          >
            <ButtonText>Disable user</ButtonText>
          </ButtonAsync>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
