import { FormFieldData } from '../FormField';
import { useCallback, useEffect, useState } from 'react';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

export interface UseFormManagerArgs {
  fields: Record<string, FormFieldData>;
  formData: Record<string, any>;
  setFormData?: (data: Record<string, any>) => void;
  onSubmit?: (data: Record<string, any>) => void;
  checkCanSubmit?: (data: Record<string, any>) => boolean;
  onInvalid?: (
    data: Record<string, any>,
    errors: Record<string, string | null>,
  ) => void;
  isReadonly?: boolean;
}

export interface UseFormManagerData {
  formData: Record<string, any>;
  errors: Record<string, string | null>;
  // Return nothing if success, return a single string for a general error, or an object with field names as keys and error messages as values to target individual fields
  submit: (
    data: Record<string, any>,
  ) => Promise<void | string | Record<string, string>>;
  getValue: (field: FormFieldData) => any;
  onChange: (fieldName: string, field: FormFieldData) => (value: any) => void;
  canSubmit: boolean;
  hiddenFields: string[];
  disabledFields: string[];
  submitPending: boolean;
}

export const useFormManager = ({
  fields,
  formData: externalFormData,
  setFormData: externalSetFormData,
  onSubmit,
  checkCanSubmit = (_) => true,
  onInvalid,
  isReadonly,
}: UseFormManagerArgs): UseFormManagerData => {
  // if state is being held externally, refer to it, otherwise create it
  const [formData, setFormData] =
    externalFormData !== undefined && !!externalSetFormData
      ? [externalFormData, externalSetFormData]
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useState(externalFormData || {}); // violating this rule doesn't matter in this case, since it doesn't make sense for it to change on the same component, it provides 2 exclusive modes for different use-cases
  // an object with field names (NOT dataFieldKeys) as keys, and error messages as values
  const [errors, setErrors] = useState<Record<string, string | null>>({});
  // an array of field names (NOT dataFieldKeys) that are currently hidden
  const [hidden, setHidden] = useState<string[]>([]);
  // an array of field names (NOT dataFieldKeys) that are currently hidden
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const [isPending, setIsPending] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    setCanSubmit(checkCanSubmit(formData));
  }, [formData, checkCanSubmit]);

  // When form data or fields change, make sure hidden fields and disabled fields are updated based on that
  useEffect(() => {
    const hidden: string[] = [];
    for (const [key, field] of Object.entries(fields)) {
      if (field?.hidden && field.hidden(formData)) {
        hidden.push(key);
      }
    }
    setHidden(hidden);

    const disabled: string[] = [];
    for (const [key, field] of Object.entries(fields)) {
      // Marked as disabled if disabled is explicitly true, or if it is a function that evaluates to true when given the current form data
      if (
        field?.disabled &&
        (field.disabled === true ||
          (typeof field.disabled === 'function' && field.disabled(formData)))
      ) {
        disabled.push(key);
      }
    }
    setDisabledFields(disabled);
  }, [formData, fields]);

  // Make sure we don't show errors for disabled fields
  useEffect(() => {
    const filteredErrors = Object.fromEntries(
      Object.entries(errors).filter(([key]) => !disabledFields.includes(key)),
    );
    if (Object.keys(filteredErrors).length !== Object.keys(errors).length) {
      setErrors(filteredErrors);
    }
  }, [errors, disabledFields]);

  useEffect(() => {
    // Make sure any selection based fields are nullified if options change and the selected option(s) not included in options
    const dataFieldsWithInvalidOptions: Record<
      keyof typeof formData,
      string | []
    > = {};
    Object.values(fields).forEach((fieldData) => {
      // Make sure the field has options, is not pending, readonly, disabled, or hidden
      if (
        'options' in fieldData &&
        !fieldData.pending &&
        !fieldData.readonly &&
        !fieldData.disabled &&
        !fieldData.enableDynamicForm &&
        !fieldData.hidden
      ) {
        const currentValue = formData[fieldData.dataFieldKey];
        const options = Array.isArray(fieldData.options)
          ? fieldData.options
          : Object.values(fieldData.options);
        // if it's a multi-select type field (checkbox, multiselect, etc)
        if (Array.isArray(currentValue)) {
          const filtered = currentValue.filter((val) => {
            return options.includes(val);
          });
          if (filtered.length !== currentValue.length) {
            dataFieldsWithInvalidOptions[fieldData.dataFieldKey as string] =
              filtered;
          }
        }
        // If it's a single-select type field, set it to empty if it's no longer a valid option
        else if (!options.includes(currentValue)) {
          dataFieldsWithInvalidOptions[fieldData.dataFieldKey as string] = '';
        }
      }
    });
    if (Object.keys(dataFieldsWithInvalidOptions).length) {
      setFormData((prevData) => ({
        ...prevData,
        ...dataFieldsWithInvalidOptions,
      }));
    }
    // Leaving formData out of deps on purpose. This is the quick solution
  }, [fields]);

  const validate = () => {
    const newErrors: Record<string, string | null> = {};
    // For each field passed:
    Object.entries(fields).forEach(
      ([fieldName, field]: [string, FormFieldData]) => {
        const isMultiField = Array.isArray(field.dataFieldKey);
        // in case one field controls multiple data fields (i.e. Timeframe)
        const dataFieldKeys: string[] = isMultiField
          ? (field.dataFieldKey as string[])
          : [field.dataFieldKey];
        // check that all required fields (and all subfields of multi-fields) are full
        for (const dataFieldKey of dataFieldKeys) {
          // if a required field is nullish, indicate such
          if (
            field?.required &&
            !hidden.includes(fieldName) &&
            !disabledFields.includes(fieldName) &&
            !requiredFieldValid(formData[dataFieldKey])
          ) {
            newErrors[fieldName] = 'This field is required';
            return;
          }
        }
        const validator = field?.validator;
        // otherwise, if a specific validator is passed, validate based on it (assuming it's enabled and visible)
        if (
          validator &&
          !field?.readonly &&
          !hidden.includes(fieldName) &&
          !disabledFields.includes(fieldName)
        ) {
          let result;
          if (isMultiField) {
            const value = Object.fromEntries(
              dataFieldKeys.map((fieldKey) => [fieldKey, formData[fieldKey]]),
            );
            result = validator(value as any, formData);
          } else {
            result = validator(
              formData[field.dataFieldKey as string],
              formData,
            );
          }
          if (result) {
            newErrors[fieldName] = result;
          }
        }
      },
    );
    setErrors(newErrors);
    if (onInvalid) {
      onInvalid(formData, newErrors);
    }
    return newErrors;
  };

  const submit = async () => {
    // We don't have any validation to do if the form is readonly
    const newErrors = isReadonly ? [] : validate();
    if (onSubmit && Object.keys(newErrors).length === 0) {
      // Clear any submission error before submit
      const { SUBMIT: _SUBMIT, ...restErrors } = { ...errors, ...newErrors };
      setErrors(restErrors);
      setIsPending(true);
      const submitErrors = await onSubmit(formData);
      if (submitErrors) {
        if (typeof submitErrors === 'string') {
          setErrors({ ...restErrors, SUBMIT: submitErrors });
        } else {
          setErrors({ ...restErrors, ...submitErrors });
        }
      }
      setIsPending(false);
    }
  };

  const getValue = (field: FormFieldData) => {
    // If field is readonly that is populated by other fields:
    if ('readonly' in field && field.readonly === true && 'populate' in field) {
      return field.populate(formData);
    }

    if (
      'enableDynamicForm' in field &&
      field.enableDynamicForm === true &&
      'dynamicUpdate' in field
    ) {
      const value = field.dynamicUpdate(formData);
      if (value) {
        if (Array.isArray(field.dataFieldKey)) {
          formData[field.dataFieldKey[0] as keyof typeof formData] = value;
        } else {
          formData[field.dataFieldKey as keyof typeof formData] = value;
        }
        setFormData(formData);
        return value;
      }
    }
    // If field element controls multiple data fields:
    if (Array.isArray(field.dataFieldKey)) {
      return Object.fromEntries(
        field.dataFieldKey.map((name) => [
          name,
          formData[name as keyof typeof formData],
        ]),
      );
    }
    // Default, field points directly to data value
    return field.dataFieldKey in formData
      ? formData[field.dataFieldKey as keyof typeof formData]
      : '';
  };

  const onChange = useCallback(
    (fieldName: string, field: FormFieldData) => (val: any) => {
      console.log('onChange called with ', { fieldName, field, val, formData });
      // Handle cases where field controls multiple data values (i.e. Timeframe input)
      const newData =
        typeof val === 'object' && !Array.isArray(val)
          ? { ...formData, ...val }
          : {
              ...formData,
              [field.dataFieldKey as string]: val,
            };
      // clear the error state for the field being edited if it exists
      if (fieldName in errors) {
        const newErrors = { ...errors };
        delete newErrors[fieldName];
        setErrors(newErrors);
      }

      // Update the form data state and run any external change handlers for the specific field given
      setFormData(newData);
      if (field?.onChange) {
        field.onChange(val, newData, setFormData);
      }
    },
    [formData, errors],
  );

  // if job is selected, update lat and lng using the associated address
  useEffect(() => {
    const fetchLatLng = async (address) => {
      try {
        const results = await geocodeByAddress(address);
        const jobLatLng = await getLatLng(results[0]);
        setFetchError(false);
        return jobLatLng;
      } catch (error) {
        console.error(
          'Failed to fetch latitude and longitude after updating address from job selection:',
          error,
        );
        setFetchError(true);
        return null;
      }
    };
    if (
      formData?.address &&
      !formData?.latitude &&
      !formData?.longitude &&
      !fetchError
    ) {
      fetchLatLng(formData.address).then((latLng) => {
        const updatedFormData = {
          ...formData,
          latitude: latLng?.lat,
          longitude: latLng?.lng,
        };
        setFormData(updatedFormData);
      });
    }
  }, [
    formData,
    formData.address,
    formData?.latitude,
    formData?.longitude,
    setFormData,
    fetchError,
  ]);

  return {
    formData,
    errors,
    submit,
    getValue,
    onChange,
    canSubmit,
    hiddenFields: hidden,
    disabledFields,
    submitPending: isPending,
  };
};

const requiredFieldValid = (value: any): boolean => {
  if (Array.isArray(value)) {
    return !!value.length;
  }
  if (typeof value === 'object') {
    return Object.keys(value).length;
  }
  return !!value;
};
