import { Heading, HStack, VStack } from '@gluestack-ui/themed';
import { Center } from '@tonic/central-specialties-ui-themed';
import { DimensionValue } from 'react-native';

export interface BoxWithHeaderProps {
  title: string;
  width?: DimensionValue;
}

export const BoxWithHeader = ({
  title,
  width,
  children,
}: React.PropsWithChildren<BoxWithHeaderProps>) => (
  <VStack width={width} maxWidth="$full">
    <HStack
      bg="$primary700"
      borderTopLeftRadius="$lg"
      borderTopRightRadius="$lg"
      alignItems="center"
      justifyContent="center"
      py="$2"
    >
      <Heading color="$white" size="xl">
        {title}
      </Heading>
    </HStack>
    <Center
      sx={{
        // @ts-expect-error - native can't handle it, but we web, we don't care
        overflow: 'auto',
      }}
      borderWidth={1}
      borderBottomLeftRadius="$lg"
      borderBottomRightRadius="$lg"
    >
      {children}
    </Center>
  </VStack>
);
