import {
  Checkbox,
  CheckboxIcon,
  CheckboxIndicator,
  CheckboxLabel,
  CheckIcon,
} from '@tonic/central-specialties-ui-themed';
import { FormFieldData } from '../FormField.types.ts';

export interface CheckboxFormFieldData {}

export const CheckboxFormField = ({
  value,
  onChange,
  label,
  size = 'md',
  invalid,
  disabled,
}: FormFieldData<'Checkbox'>) => {
  return (
    <Checkbox
      value={label}
      isChecked={value}
      onChange={onChange}
      size={size}
      isInvalid={invalid}
      isDisabled={disabled}
      aria-label={label}
    >
      <CheckboxIndicator mr="$2" aria-label={label}>
        <CheckboxIcon as={CheckIcon} />
      </CheckboxIndicator>
      <CheckboxLabel>{label}</CheckboxLabel>
    </Checkbox>
  );
};
