import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

export const getLatLongFromAddress = async (
  address: string,
): Promise<{ latitude: number; longitude: number }> => {
  const { lat: latitude, lng: longitude } = await geocodeByAddress(
    address,
  ).then((results) => getLatLng(results[0]));
  return { latitude, longitude };
};
