import { Form, FormFieldsObject, FormProps } from '../../Form.tsx';
import {
  allRoles,
  DB,
  isValidEmail,
  Schemas,
} from '@tonic/central-specialties-utils';
import { useMutation } from '@tanstack/react-query';
import { toSentenceCase } from 'js-convert-case';
import { useCallback, useEffect, useState } from 'react';
import { useAlert } from '@tonic/central-specialties-ui-themed';

interface UserFormProps {
  /**
   * If not provided, the form will be in create mode, otherwise it will be in edit mode
   */
  user?: Schemas['UserModelWithRoles'];
  onSuccess?: () => void;
  formProps?: Partial<FormProps>;
}

export const UserForm = ({ user, onSuccess, formProps }: UserFormProps) => {
  const { alert } = useAlert();
  const [mode, setMode] = useState<'create' | 'edit'>(
    user && user?.id ? 'edit' : 'create',
  );

  const [defaults, setDefaults] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    employeeNumber: user?.employeeNumber || '',
    phoneNumber: user?.phoneNumber || '',
    email: user?.email || '',
    roles: user?.roles || [], // loaded on mount
  });

  useEffect(() => {
    setMode(user && user?.id ? 'edit' : 'create');

    setDefaults({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      employeeNumber: user?.employeeNumber || '',
      phoneNumber: user?.phoneNumber || '',
      email: user?.email || '',
      roles: user?.roles || [], // loaded on mount
    });
  }, [user]);

  const { mutateAsync: createUser } = useMutation({
    mutationFn: (data) => DB.POST('/users', { body: data }),
  });

  const { mutateAsync: updateUser } = useMutation({
    mutationFn: (data) =>
      DB.PUT('/users/{userId}', {
        params: {
          path: {
            userId: user?.id,
          },
        },
        body: data,
      }),
  });

  const { mutateAsync: reinstateUser } = useMutation({
    mutationFn: (userId: string) =>
      DB.POST('/users/{userId}/disabled-status', {
        params: { path: { userId } },
        body: { isDisabled: false },
      }),
  });

  const submit = useCallback(
    async (formData: typeof defaults) => {
      const handleError = (
        e: any,
        fallbackAlertMessage: string,
      ): Record<string, string> | void => {
        console.error(e);
        if ('' in e) {
          console.log('HERE');
          const errors: Record<string, string> = {};
          for (const eMessage of e['']) {
            if (eMessage.toLowerCase().includes('already taken')) {
              errors.employeeNumber = eMessage;
            }
          }
          if (Object.keys(errors).length === 0) {
            errors.SUBMIT = e[''][0];
          }
          return errors;
        }
        alert({
          status: 'error',
          message: fallbackAlertMessage,
          timeout: 10000,
        });
      };

      // API requires it be digits only
      formData.phoneNumber = formData.phoneNumber.replace(/\D/g, '');
      if (mode === 'create') {
        return createUser(formData)
          .then((res) => {
            if (res.error) {
              throw res.error;
            }
            alert({
              status: 'success',
              message: 'User created',
              timeout: 5000,
            });
            onSuccess && onSuccess();
            return res;
          })
          .catch((e) => {
            return handleError(
              e,
              'There was an error creating this user. Please try again later',
            );
          });
      } else {
        if (user?.isDisabled) {
          // TODO: Make sure this gets changed on the API side, currently always throws errors even though it works
          try {
            const { error } = await reinstateUser(user.id);
            if (error) {
              throw error;
            }
            alert({
              status: 'success',
              message: 'Success! User reinstated',
              timeout: 5000,
            });
            onSuccess && onSuccess();
          } catch (e) {
            return handleError(
              e,
              'There was an error reinstating this user. Please try again later',
            );
          }
          return;
        }
        return updateUser(formData)
          .then(({ error }) => {
            if (error) {
              throw error;
            }
            alert({
              status: 'success',
              message: 'Success! User updated.',
              timeout: 5000,
            });
            onSuccess && onSuccess();
            setDefaults(formData);
          })
          .catch((e) => {
            return handleError(
              e,
              'There was an error updating this user. Please try again later',
            );
          });
      }
    },
    [createUser, updateUser, mode, alert, reinstateUser, user?.isDisabled],
  );

  // TODO: designs show more roles that than are present in allRoles. Check whether they actually exist, or if some are just code for multiple other roles
  const createRoleOptions = () =>
    Object.fromEntries(allRoles.map((role) => [toSentenceCase(role), role]));

  const fields: FormFieldsObject = {
    firstName: {
      type: 'Text',
      label: 'First name',
      dataFieldKey: 'firstName',
      placeholder: 'Enter first name',
      required: true,
      readonly: user?.isDisabled,
    },
    lastName: {
      type: 'Text',
      label: 'Last name',
      dataFieldKey: 'lastName',
      placeholder: 'Enter last name',
      required: true,
      readonly: user?.isDisabled,
    },
    employeeNumber: {
      type: 'Text',
      label: 'Employee number',
      dataFieldKey: 'employeeNumber',
      readonly: mode === 'edit' || user?.isDisabled,
      validator: (value: string) => {
        // make sure only strings are allowed
        if (!/^\d+$/.test(value)) {
          return 'Employee number can only contain numbers';
        }
      },
      required: true,
    },
    phoneNumber: {
      type: 'Text',
      label: 'Phone number',
      dataFieldKey: 'phoneNumber',
      placeholder: 'Enter phone number',
      validator: (value: string) => {
        // Make sure it's either only digits, or standard format with max of 2 dashes
        if (!/^\d{10}$/.test(value) && !/^\d{3}-\d{3}-\d{4}$/.test(value)) {
          return 'Invalid phone number';
        }
      },
      readonly: user?.isDisabled,
      required: true,
    },
    email: {
      type: 'Text',
      label: 'Email',
      dataFieldKey: 'email',
      placeholder: 'Enter email',
      validator: (value: string) => {
        if (!!value && !isValidEmail(value)) {
          return 'Invalid email';
        }
      },
      readonly: user?.isDisabled,
    },
    roles: {
      type: 'CheckboxGroup',
      label: 'User type',
      dataFieldKey: 'roles',
      options: createRoleOptions(),
      required: true,
      wrap: true,
      checkboxGroupProps: {
        flexDirection: 'row',
        rowGap: '$6',
        gap: '$6',
      },
      readonly: user?.isDisabled,
    },
  };

  const checkCanSubmit = (formData: typeof defaults) => {
    // You can always re-instate a user
    if (user?.isDisabled) {
      return true;
    }
    if (formData.roles.length === 0) {
      return false;
    }
    // Make sure at least one of the values has changed from the initial
    return Object.entries(formData).some(([key, value]) => {
      if (key === 'roles') {
        return (
          value.length !== defaults[key].length ||
          !value.every((v) => defaults[key].includes(v))
        );
      }
      return value !== defaults[key];
    });
  };

  return (
    <Form
      fields={fields}
      checkCanSubmit={checkCanSubmit}
      readonly={user?.isDisabled}
      formData={defaults}
      onSubmit={submit}
      {...formProps}
    />
  );
};
