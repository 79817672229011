import React from 'react';
import { FormFieldData } from '../../FormField.types.ts';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionTrigger,
  Icons,
  Text,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { Schemas } from '@tonic/central-specialties-utils';
import { FormFieldControl } from '../../FormFieldControl.tsx';
import { HStack } from '@gluestack-ui/themed';

export interface PartsUpdatesFormFieldData {}

export const PartsUpdatesFormField = ({
  value,
  onChange,
  readonly,
}: FormFieldData<'PartsUpdates'>) => {
  return (
    <VStack>
      <Accordion type="multiple" variant="unfilled" isCollapsible>
        {value && value.length ? (
          value.map((part, index) => (
            <AccordionItem
              key={part?.id || index}
              value={index.toString()}
              borderColor="$gray400"
              borderBottomWidth={2}
            >
              <AccordionHeader px={0}>
                <AccordionTrigger p={0}>
                  {({ isExpanded }) => {
                    return (
                      <HStack
                        w="$full"
                        py="$3"
                        // TODO: Double check if we want this. It seems to make more sense without
                        // borderColor="$gray400"
                        // borderBottomWidth={isExpanded ? 2 : 0}
                        justifyContent="space-between"
                      >
                        <HStack flex={1}>
                          <Text bold>
                            Part {index + 1}
                            {part.partNumber ? ` - ${part.partNumber}` : ''}
                          </Text>
                        </HStack>
                        <HStack flex={1.2} justifyContent="space-between">
                          <Text subtle>Status: {part.status || 'Not set'}</Text>
                          <HStack>
                            <Text subtle underline>
                              {isExpanded ? 'Close details' : 'Open details'}
                            </Text>
                            <AccordionIcon
                              color="$textSubtle"
                              as={
                                isExpanded ? Icons.ChevronUp : Icons.ChevronDown
                              }
                              ml="$3"
                            />
                          </HStack>
                        </HStack>
                      </HStack>
                    );
                  }}
                </AccordionTrigger>
              </AccordionHeader>
              <AccordionContent px={0} pt="$3">
                <PartUpdatesFieldGroup
                  part={part}
                  index={index}
                  onChange={(part) => {
                    const newValue = [...value];
                    newValue[index] = part;
                    onChange(newValue);
                  }}
                  readonly={readonly}
                />
              </AccordionContent>
            </AccordionItem>
          ))
        ) : (
          <Text ml="$0.5">No parts to show</Text>
        )}
      </Accordion>
    </VStack>
  );
};

interface PartUpdatesFieldGroupProps {
  part: Schemas['PartRequestResponse'];
  index: number;
  onChange: (part: Schemas['PartRequestResponse']) => void;

  readonly: boolean;
}

const PartUpdatesFieldGroup = ({
  part,
  readonly = false,
  onChange,
}: PartUpdatesFieldGroupProps) => {
  return (
    <VStack gap="$4">
      <FormFieldControl
        type="Select"
        label="Status"
        value={part.status}
        onChange={(status) => onChange({ ...part, status })}
        options={['New', 'Ordered', 'Delivered']}
        readonly={readonly}
        placeholder="Select status"
        required
      />
      <FormFieldControl
        type="Text"
        label="Purchase order"
        value={part?.purchaseOrderNumber || ''}
        onChange={(purchaseOrderNumber) =>
          onChange({ ...part, purchaseOrderNumber })
        }
        placeholder="Enter purchase order"
        readonly={readonly}
      />
      <FormFieldControl
        type="Text"
        label="Delivery notes"
        value={part?.deliveryNotes || ''}
        onChange={(deliveryNotes) => onChange({ ...part, deliveryNotes })}
        placeholder="Enter delivery notes"
        readonly={readonly}
        multiline
      />
    </VStack>
  );
};
