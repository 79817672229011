import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import { Form, FormFieldsObject, FormProps } from '../../Form.tsx';
import { Divider, HStack, Icon, Text } from '@gluestack-ui/themed';
import { useFormJobOptions } from '../../hooks/useFormJobOptions.ts';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Center,
  Icons,
  Spinner,
  useAlert,
} from '@tonic/central-specialties-ui-themed';
import { getLatLongFromAddress } from '../../../../../utils/getLatLongFromAddress.ts';

interface TruckRequestFormProps {
  truckRequestId?: string;
  isReadonlyMode?: boolean;
  /**
   * If true, the form will show an additional form group to the right of the main form group,
   * allowing assignment and a few other fields.
   */
  isAdminMode?: boolean;
  onSuccess?: () => void;
  formProps?: Partial<FormProps>;
}

export const TruckRequestForm = ({
  truckRequestId,
  ...restProps
}: TruckRequestFormProps) => {
  const { alert } = useAlert();
  const {
    data: truckRequest,
    isPending,
    error,
  } = useQuery({
    queryKey: ['truckRequest', truckRequestId],
    queryFn: async () =>
      DB.GET('/trucking-requests/{id}', {
        params: { path: { id: truckRequestId } },
      }).then((res: { data: Schemas['TruckingRequestResponse'] }) => res.data),
    enabled: !!truckRequestId,
  });

  if (isPending && truckRequestId !== undefined) {
    return (
      <Center h="$full" minHeight="$96" flex={1}>
        <Spinner />
      </Center>
    );
  }

  if (error) {
    alert({
      message: 'There was an error fetching the trucking request.',
      status: 'error',
      timeout: 10000,
    });
    return (
      <Center w="$full" h="$full" gap="$6">
        <Icon as={Icons.AlertCircle} color="$error500" w="$16" h="$16" />
        <Text size="lg" color="$error500">
          There was an error retrieving trucking request {truckRequestId}
        </Text>
      </Center>
    );
  }

  return <TruckRequestFormInner truckRequest={truckRequest} {...restProps} />;
};

interface TruckRequestFormInnerProps extends TruckRequestFormProps {
  truckRequest?: Schemas['TruckingRequestResponse'];
}

export const TruckRequestFormInner = ({
  truckRequest,
  isReadonlyMode,
  isAdminMode = false,
  onSuccess,
  formProps,
}: TruckRequestFormInnerProps) => {
  const { alert } = useAlert();
  const [defaults, setDefaults] = useState<
    Schemas['TimeCardEntryRequest'] | null
  >(null);
  const [enableDynamicFormAddress, setEnableDynamicFormAddress] =
    useState(true);

  const mode = useMemo<'create' | 'edit' | 'archive'>(() => {
    switch (true) {
      case truckRequest && truckRequest.status === 'Archived':
        return 'archive';
      case truckRequest && 'status' in truckRequest:
        return 'edit';
      default:
        return 'create';
    }
  }, [truckRequest]);

  const { allJobs, jobOptions } = useFormJobOptions();

  const { mutateAsync: unarchiveRequest } = useMutation({
    mutationFn: (id: string) => {
      return DB.POST('/trucking-requests/{id}/status', {
        params: {
          path: {
            id,
          },
        },
        body: {
          status: 'New',
        },
      });
    },
  });

  const { mutateAsync: updateRequest } = useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: string;
      data: Schemas['TruckingRequestUpdateRequest'];
    }) => {
      return DB.PUT('/trucking-requests/{id}', {
        params: {
          path: {
            id,
          },
        },
        body: data,
      });
    },
  });

  const { mutateAsync: createRequest } = useMutation({
    mutationFn: (data: Schemas['TruckingRequestCreateRequest']) => {
      return DB.POST('/trucking-requests', {
        body: data,
      }).then(
        (response: {
          data: Schemas['TruckingRequestResponse'];
          error: any;
        }) => {
          if (response.error) {
            throw new Error(response.error);
          }
          return response.data as Schemas['TruckingRequestResponse'];
        },
      );
    },
  });

  const { mutateAsync: updateRequestStatus } = useMutation({
    mutationFn: async ({ id, status }: { id: string; status: string }) => {
      return DB.POST('/trucking-requests/{id}/status', {
        params: {
          path: {
            id,
          },
        },
        body: {
          status,
        },
      });
    },
  });

  ///-----------------------------------------------------------------------
  // SET DEFAULTS
  ///-----------------------------------------------------------------------
  useEffect(() => {
    const primaryFieldDefaults = {
      jobId: truckRequest?.job?.id || '',
      trucks: truckRequest?.trucks || [],
      address: truckRequest?.address || '',
      latitude: truckRequest?.latitude || 0,
      longitude: truckRequest?.longitude || 0,
      startDate: truckRequest?.startDate || '',
      endDate: truckRequest?.endDate || '',
      startTime: truckRequest?.startTime || '',
      staggerNotes: truckRequest?.staggerNotes || '',
      additionalNotes: truckRequest?.additionalNotes || '',
    };

    const adminFieldDefaults = {
      status: truckRequest?.status || 'New',
      // Also includes extra truck controls, but those data are already present in primary fields
    };

    setDefaults(
      isAdminMode
        ? { ...primaryFieldDefaults, ...adminFieldDefaults }
        : primaryFieldDefaults,
    );
  }, [truckRequest, isAdminMode]);

  const formFields = useMemo(() => {
    const primaryFields: FormFieldsObject = {
      jobNumber: {
        type: 'Select',
        dataFieldKey: 'jobId',
        label: 'Job number',
        placeholder: 'Select job number',
        options: jobOptions,
        required: true,
        onChange: () => {
          setEnableDynamicFormAddress(true);
        },
      },

      jobName: {
        type: 'Text',
        dataFieldKey: 'jobName',
        readonly: true,
        populate: (data: any) => {
          if (!allJobs) {
            return 'No description';
          }
          return (
            allJobs.find((j: Schemas['JobCodeModel']) => j.id === data.jobId)
              ?.description || 'No description'
          );
        },
        label: 'Job name',
      },

      destinationLocation: {
        type: 'Address',
        dataFieldKey: ['address', 'latitude', 'longitude'],
        label: 'Delivery location',
        dynamicUpdate: (data: any) => {
          if (!allJobs || isReadonlyMode) {
            return null;
          }

          if (
            data &&
            data?.createdAt &&
            data?.jobId === truckRequest?.job?.id
          ) {
            setEnableDynamicFormAddress(false);
            return null;
          }

          const jobAddress =
            allJobs.find((j: Schemas['JobCodeModel']) => j.id === data.jobId)
              ?.address || '';
          setEnableDynamicFormAddress(false);
          return jobAddress;
        },
        enableDynamicForm: enableDynamicFormAddress,
        required: true,
      },

      dateRange: {
        type: 'DateRange',
        dataFieldKey: ['startDate', 'endDate'],
        label: 'Date range',
        required: true,
      },

      startTime: {
        type: 'Text',
        dataFieldKey: 'startTime',
        label: 'Start time',
        required: true,
      },

      staggerNotes: {
        type: 'Text',
        dataFieldKey: 'staggerNotes',
        label: 'Stagger notes',
      },

      trucks: {
        type: 'TruckRequests',
        dataFieldKey: 'trucks',
        // label: 'Trucks',
        required: true,
        // size: 'lg',
        validator: (value) => {
          if (value.some((truck) => !truck.type)) {
            return 'All groups must have a type';
          }
          if (value.some((truck) => !truck.materialType)) {
            return 'All groups must have a material type';
          }
          if (
            value.some(
              (truck) =>
                !truck.numberOfTrucks ||
                Number.isNaN(Number.parseInt(truck.numberOfTrucks)),
            )
          ) {
            return 'All groups must have a valid quantity';
          }
        },
      },

      additionalNotes: {
        type: 'Text',
        label: 'Additional notes',
        multiline: true,
        dataFieldKey: 'additionalNotes',
      },
    };

    const adminFields: FormFieldsObject = {
      status: {
        type: 'Select',
        label: 'Ticket Status',
        dataFieldKey: 'status',
        options: ['New', 'Assigned', 'Completed', 'Archived'],
        placeholder: 'Status',
        isSuperSelect: true,
      },

      truckUpdates: {
        type: 'TruckUpdates',
        // label: 'Requested trucks',
        dataFieldKey: 'trucks',
        size: 'lg',
        formControlProps: {
          mt: '$10',
        },
      },
    };
    return isAdminMode ? [primaryFields, adminFields] : primaryFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaults, allJobs, isAdminMode, jobOptions, enableDynamicFormAddress]);

  const submit = useCallback(
    async (data: typeof defaults) => {
      const {
        jobId,
        address,
        startDate,
        endDate,
        startTime,
        staggerNotes,
        additionalNotes,
      } = data;
      let trucks: Schemas['TruckResponse'] = data.trucks;
      let { latitude, longitude } = data;

      // If lat and long aren't specified directly, try to get them from the address
      if (latitude === 0 && longitude === 0) {
        const { latitude: latFromAddress, longitude: longFromAddress } =
          await getLatLongFromAddress(address);
        latitude = latFromAddress;
        longitude = longFromAddress;
      }

      // make sure the phone numbers and hourly rate is formatted correctly on trucking companies
      trucks = trucks.map((truck) => {
        const assignedCompanies = truck.assignedCompanies.map((company) => ({
          ...company,
          phoneNumber: company.phoneNumber.replace(/\D/g, ''),
          hourlyRate: parseFloat(company.hourlyRate),
        }));
        return {
          ...truck,
          numberOfTrucks: Number(truck.numberOfTrucks),
          assignedCompanies,
        };
      });

      const payload: Schemas['TruckingRequestCreateRequest'] = {
        jobId,
        // enseure phone numbers are digits only for the API
        trucks,
        address,
        longitude,
        latitude,
        startDate,
        endDate,
        startTime,
        staggerNotes,
        additionalNotes,
      };
      let truckingRequestId = truckRequest?.id;
      console.log('trucks: ', trucks);

      switch (mode) {
        case 'create':
          try {
            const newTruckingRequestResponse: Schemas['TruckingRequestResponse'] =
              await createRequest(
                payload as Schemas['TruckingRequestCreateRequest'],
              );
            truckingRequestId = newTruckingRequestResponse.id;

            // Break conditionally: do not need to update after create if no admin fields were specified
            if (
              'status' in defaults &&
              defaults.status === data.status &&
              data.trucks.every((truck) => {
                // i.e. every admin field of every truck is nullish
                return [
                  'companyName',
                  'driverName',
                  'phoneNumber',
                  'tonCapacity',
                  'dbe',
                  'hourlyRate',
                ].every((fieldName) => {
                  return !truck[fieldName];
                });
              })
            ) {
              alert({
                status: 'success',
                message: `Trucking request created successfully`,
                timeout: 5000,
              });
              if (onSuccess) {
                onSuccess();
              }
              return;
            }
          } catch (err) {
            alert({
              status: 'error',
              message: 'failed to create trucking request',
              timeout: 10000,
            });
            console.error('error message: ', err);
            return;
          }

        // WARNING: FALLTHROUGH IS INTENTIONAL!! if admin fields are specified upon creation, it will need to send those updates in the 'edit' case after creation
        case 'edit':
          if (data.status !== '' && data.status !== defaults?.status) {
            await updateRequestStatus({
              id: truckingRequestId,
              status: data.status,
            });
          }
          // Same payload as before because create does not accept many of the properties present on trucks, but update does
          return updateRequest({ id: truckingRequestId, data: payload })
            .then(({ error }) => {
              if (error) {
                throw new Error(error);
              }
              alert({
                status: 'success',
                message: `Trucking request ${mode === 'create' ? 'created' : 'updated'} successfully`,
                timeout: 5000,
              });
              if (onSuccess) {
                onSuccess();
              }
            })
            .catch((err) => {
              alert({
                status: 'error',
                message: `failed to ${mode} trucking request`,
                timeout: 10000,
              });
              console.error('error message: ', err);
              return;
            });
        case 'archive':
          return unarchiveRequest(truckingRequestId)
            .then(({ error }) => {
              if (error) {
                throw new Error(error);
              }
              alert({
                status: 'success',
                message: 'Trucking request unarchived successfully',
                timeout: 5000,
              });
              if (onSuccess) {
                onSuccess();
              }
            })
            .catch((err) => {
              alert({
                status: 'error',
                message: 'failed to unarchive trucking request',
                timeout: 10000,
              });
              console.error('Unarchive error: ', err);
              return;
            });
        default:
          break;
      }
    },
    [
      alert,
      defaults,
      onSuccess,
      mode,
      createRequest,
      updateRequest,
      updateRequestStatus,
      unarchiveRequest,
    ],
  );

  if (!defaults) return null;

  return (
    <Form
      formData={defaults}
      fields={formFields}
      readonly={!!isReadonlyMode}
      onSubmit={submit}
      Container={({ children }) => (
        <HStack flex={1} h="$full" gap="$8">
          {children}
        </HStack>
      )}
      Divider={() => <Divider orientation="vertical" subtle />}
      {...formProps}
    />
  );
};
