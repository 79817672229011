import { Schemas } from '@tonic/central-specialties-utils';
import React from 'react';
import {
  Card,
  ColorToken,
  Icons,
  IndicatorDot,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { HStack, Icon, Text } from '@gluestack-ui/themed';
import moment from 'moment';

interface RepairRequestCardProps {
  repairRequest: Schemas['RepairRequestResponse'];
  onPress: (data: Schemas['RepairRequestResponse']) => void;
  cardProps?: React.ComponentProps<typeof Card>;
}

export const RepairRequestCard = ({
  repairRequest,
  onPress,
  cardProps,
}: RepairRequestCardProps) => {
  return (
    <Card onPress={() => onPress(repairRequest)} minWidth="$64" {...cardProps}>
      <HStack alignItems="center" justifyContent="center" flex={1} gap="$4">
        <VStack flex={1} gap="$0.5" justifyContent="space-between">
          <VStack gap="$0.5">
            <Text bold>
              Request {repairRequest.requestNumber || '(number pending)'}
            </Text>
            {!!repairRequest?.workOrderNumber && (
              <Text>Repair order: {repairRequest.workOrderNumber}</Text>
            )}
            {!!repairRequest?.assignedTo?.fullName && (
              <Text>
                Mechanic: {repairRequest.assignedTo.fullName} -{' '}
                {repairRequest.assignedTo.employeeNumber}
              </Text>
            )}
            <Text>
              Date submitted:{' '}
              {moment(repairRequest.createdAt)
                .utc(true)
                .tz('America/Chicago')
                .format('M/D/YY')}
            </Text>
            <Text>Issue: {repairRequest.issueNumber}</Text>
            <Text>Description: {repairRequest.description}</Text>
            <Text>Equipment number: {repairRequest.equipment.number}</Text>
            <Text>Submitter: {repairRequest?.createdBy?.fullName}</Text>
          </VStack>
          <HStack gap="$1" alignItems="center">
            <IndicatorDot
              color={`$priority${repairRequest.priority}` as ColorToken}
              size="sm"
            />
            <Text>{repairRequest.priority}</Text>
          </HStack>
        </VStack>
        {repairRequest.hasMessages && (
          <Icon as={Icons.MessageCircle} size="xl" color="$textSubtle" />
        )}
      </HStack>
    </Card>
  );
};
