import React, { useMemo } from 'react';
import {
  FormField,
  FormFieldControl,
  FormFieldData,
  isFormFieldDeclaration,
} from './FormField';
import {
  ButtonAsync,
  Center,
  SpaceToken,
  Text,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { ButtonText, ScrollView } from '@gluestack-ui/themed';
import { useFormManager } from './hooks/useFormManager.ts';

export interface FormButtonProps {
  /**
   * Reference to the internal submit function, so you can control when it fires
   */
  submit: () => Promise<void>;
  /**
   * Determined by the checkCanSubmit passed to the form
   */
  disabled?: boolean;
  /**
   * True if the form is currently submitting
   */
  pending: boolean;
  /**
   * Reference to the internal validate function, so you can control when it fires
   */
  // validate: () => void
  /**
   * Submit button text (defaults to 'Submit')
   */
  submitButtonText?: string;
  /**
   * Additional props for the button component
   */
  buttonProps: React.ComponentProps<typeof ButtonAsync>;
}

const DefaultSubmitButton = ({
  submit,
  disabled,
  pending,
  submitButtonText = 'Submit',
  ...buttonProps
}: FormButtonProps) => (
  <Center pt="$6">
    <ButtonAsync
      size="lg"
      w="$40"
      px="$5"
      onPress={submit}
      isDisabled={disabled}
      {...buttonProps}
      pending={pending}
    >
      <ButtonText>{submitButtonText}</ButtonText>
    </ButtonAsync>
  </Center>
);

const DefaultDivider = () => <></>;

const DefaultFormContainer = ({
  children,
  ...containerProps
}: React.PropsWithChildren) => (
  <VStack space="xl" {...containerProps}>
    {children}
  </VStack>
);

export type FormFieldsObject = Record<string, FormFieldData>;

export interface FormProps {
  fields: Record<string, FormFieldData>;
  formData?: Record<string, any>;
  setFormData?: (data: Record<string, any>) => void;
  /**
   * If true, nothing can be edited and will be rendered in a readonly style
   */
  readonly?: boolean;
  /**
   * Submission function. Needed if not managing form state externally, but can be used either way
   */
  onSubmit?: (data: Record<string, any>) => void;
  /**
   * Function to determine if the form can be submitted. If not provided, the form can always be submitted
   */
  checkCanSubmit?: (data: Record<string, any>) => boolean;
  /**
   * Callback for when the form is marked invalid. Use to show alerts, etc
   */
  onInvalid?: (
    data: Record<string, any>,
    errors: Record<string, string | null>,
  ) => void;
  submitButtonText?: string;
  /**
   * Size passed to each field, can be overridden by field props
   */
  size?: 'sm' | 'md' | 'lg';
  /**
   * Space between fields. Defaults to '$6'
   */
  gap?: SpaceToken;
  containerProps?: React.ComponentProps<typeof VStack>;
  buttonProps?: React.ComponentProps<typeof ButtonAsync>;
  SubmitButton?: typeof DefaultSubmitButton;
  Container?: typeof DefaultFormContainer;
  Divider?: typeof DefaultDivider;
}

export function Form({
  fields,
  formData: externalFormData,
  setFormData: externalSetFormData,
  readonly = false,
  onSubmit,
  checkCanSubmit = (_) => true,
  onInvalid,
  submitButtonText = 'Submit',
  size = 'md',
  gap = '$6',
  containerProps,
  buttonProps,
  SubmitButton,
  Container = DefaultFormContainer,
  Divider = DefaultDivider,
}: FormProps) {
  const combinedFormFields = useMemo(
    () =>
      Array.isArray(fields)
        ? fields.reduce((acc, group) => {
            return { ...acc, ...group };
          }, {})
        : fields,
    [fields],
  );

  const {
    errors,
    submit,
    getValue,
    onChange,
    canSubmit,
    hiddenFields,
    disabledFields,
    submitPending,
  } = useFormManager({
    fields: combinedFormFields,
    formData: externalFormData || {},
    setFormData: externalSetFormData,
    onSubmit,
    checkCanSubmit,
    onInvalid,
    isReadonly: readonly,
  });

  return (
    <>
      <Container {...containerProps}>
        {(Array.isArray(fields) ? fields : [fields]).map((fieldGroup, i) => (
          <React.Fragment key={'formGroup' + i}>
            {i > 0 && <Divider subtle />}
            <VStack gap={gap} flex={1} pb="$6">
              {i === 0 ? (
                <ScrollView maxHeight={800} height={'$full'}>
                  <FormGroup
                    fields={fieldGroup}
                    errors={errors}
                    onChange={readonly ? (_) => {} : onChange}
                    getValue={getValue}
                    readonly={readonly}
                    size={size}
                    hiddenFields={hiddenFields}
                    disabledFields={disabledFields}
                  />
                </ScrollView>
              ) : (
                <FormGroup
                  fields={fieldGroup}
                  errors={errors}
                  onChange={readonly ? (_) => {} : onChange}
                  getValue={getValue}
                  readonly={readonly}
                  size={size}
                  hiddenFields={hiddenFields}
                  disabledFields={disabledFields}
                />
              )}
            </VStack>
          </React.Fragment>
        ))}
        {'SUBMIT' in errors && <Text color="$errorText">{errors.SUBMIT}</Text>}
        {/* If onSubmit is passed but no SubmitButton, render the default within the containing VStack*/}
        {!!onSubmit && !SubmitButton && !readonly && (
          <DefaultSubmitButton
            submit={submit}
            disabled={!canSubmit}
            pending={submitPending}
            submitButtonText={submitButtonText}
            {...buttonProps}
          />
        )}
      </Container>
      {/* If onSubmit and SubmitButton are both passed, render the given submit button outside the form container */}
      {!!onSubmit && !!SubmitButton && (
        <SubmitButton
          submit={submit}
          disabled={!canSubmit}
          pending={submitPending}
          submitButtonText={submitButtonText}
          {...buttonProps}
        />
      )}
    </>
  );
}

export interface FormGroupProps {
  fields: FormFieldsObject;
  errors: Record<string, string | null>;
  onChange: (fieldName: string, value: any) => void;
  getValue: (field: FormFieldData) => any;
  readonly?: boolean;
  size?: 'sm' | 'md' | 'lg';
  hiddenFields?: string[];
  disabledFields?: string[];
}

export const FormGroup = ({
  fields,
  errors,
  readonly = false,
  size = 'md',
  hiddenFields,
  disabledFields,
  onChange,
  getValue,
}: FormGroupProps) => {
  return Object.entries(fields).map(([fieldName, field]) => (
    <FormFieldControl
      key={`FormField:${fieldName}`}
      readonly={readonly || field?.readonly}
      {...field}
      value={getValue(field)}
      errorText={errors[fieldName]}
      invalid={!!errors[fieldName]}
      hidden={hiddenFields.includes(fieldName)}
      disabled={
        (!!disabledFields && disabledFields.includes(fieldName)) ||
        (typeof field?.disabled === 'boolean' && field.disabled)
      }
      onChange={readonly ? (_) => {} : onChange(fieldName, field)}
      size={field?.size || size}
      // Nested sub-elements, like the "no-work" checkbox on timecard timeframe input
      labelRightElement={
        field?.labelRightElement ? (
          isFormFieldDeclaration(field.labelRightElement) ? (
            <FormField
              {...field.labelRightElement}
              value={getValue(field.labelRightElement)}
              onChange={onChange(fieldName, field.labelRightElement)}
            />
          ) : (
            field.labelRightElement
          )
        ) : null
      }
    />
  ));
};
