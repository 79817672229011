import React from 'react';
import { FormFieldData } from '../FormField.types.ts';
import { HStack, Text, useToken } from '@gluestack-ui/themed';
import moment from 'moment';

export interface DateRangeData {
  startDate: string;
  endDate: string;
}

export interface DateRangeFormFieldData {}

export const DateRangeFormField = ({
  value,
  onChange,
  readonly,
}: FormFieldData<'DateRange'>) => {
  const grayBorder = useToken('colors', 'gray300');
  const handleChange =
    (key: keyof DateRangeData) => (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...value,
        [key]: e.target.value,
      });
    };

  return (
    <HStack gap="$4" alignItems="center">
      {readonly ? (
        <Text>{moment(value.startDate).format('MM/DD/YYYY')}</Text>
      ) : (
        <input
          style={{
            flex: 1,
            padding: 8,
            border: `1px solid ${grayBorder}`,
            borderRadius: 3,
            outline: 'none',
          }}
          type="date"
          value={moment(value.startDate).format('YYYY-MM-DD')}
          onChange={handleChange('startDate')}
        />
      )}
      <Text>To</Text>
      {readonly ? (
        <Text>{moment(value.endDate).format('MM/DD/YYYY')}</Text>
      ) : (
        <input
          style={{
            flex: 1,
            padding: 8,
            border: `1px solid ${grayBorder}`,
            borderRadius: 3,
            outline: 'none',
          }}
          type="date"
          value={moment(value.endDate).format('YYYY-MM-DD')}
          onChange={handleChange('endDate')}
        />
      )}
    </HStack>
  );
};
