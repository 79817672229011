import { flattenObject } from './flattenObject.ts';

export const downloadObjectsAsCSV = (
  data: Record<string, any>[],
  name: string,
) => {
  if (!data.length) {
    console.error('No data to download');
    return null;
  }

  if (!name.endsWith('.csv')) {
    name += '.csv';
  }

  // ensure we get all relevant columns, even if the first object is missing some
  const cols = Array.from(
    new Set(data.flatMap((row) => Object.keys(flattenObject(row)))),
  );

  let csv = cols + '\n';
  data.forEach((row) => {
    const flattened = flattenObject(row);
    const rowData = cols.map((colName) => {
      const val = flattened[colName];
      if (!val) {
        return '';
      }
      // make sure that if the value has commas or newlines, it's wrapped in quotes
      if (
        typeof val === 'string' &&
        (val.includes(',') || val.includes('\n'))
      ) {
        return `"${val}"`;
      }
      return val;
    });
    csv += rowData.join(',') + '\n';
  });

  downloadCSVFile(csv, name);
};

export const downloadCSVFile = (csv: string, name: string) => {
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
