import { PageWrapper } from '../../../components/general/PageWrapper.tsx';
import React, { useCallback, useMemo, useState } from 'react';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import { Center, Icons } from '@tonic/central-specialties-ui-themed';
import { LoadingLogoAndSpinner } from '../../../components/general/LoadingLogoAndSpinner.tsx';
import {
  Button,
  ButtonIcon,
  ButtonText,
  HStack,
  Icon,
  ScrollView,
  Text,
} from '@gluestack-ui/themed';
import { EntityCardColumnsView } from '../../../components/general/EntityCardExplorer/EntityCardColumnsView/EntityCardColumnsView.tsx';
import { TruckRequestCard } from '../../../components/general/cards/TruckRequestCard.tsx';
import { TruckRequestSummaryModal } from './TruckRequestSummaryModal/TruckRequestSummaryModal.tsx';
import { TruckRequestFormModal } from '../../../components/general/Form/specific/TruckRequestForm/TruckRequestFormModal.tsx';
import { ArchivedTruckRequestsModal } from './ArchivedTruckRequestsModal/ArchivedTruckRequestsModal.tsx';
import { useQuery } from '@tanstack/react-query';
import {
  FilterButton,
  FiltersData,
} from '../../../components/general/filtering/FilterButton.tsx';
import { downloadObjectsAsCSV } from '../../../utils/downloadObjectsAsCSV.ts';

const filtersData: FiltersData<Schemas['TruckingRequestResponse']> = {
  'Truck company': {
    path: ['trucks', 'companyName'],
    formatOptions: 'noNull',
  },
  'Job number': {
    path: ['job', 'number'],
    formatOptions: 'noNull',
  },
  'Date submitted': {
    path: ['createdAt'],
    formatOptions: 'date',
    matcher: 'date',
    sortOptions: 'date',
  },
  // TODO: Design shows priority, but there is no priority on these. Check if we want something else
};

export const TrucksPage = () => {
  const [selectedTruckRequest, setSelectedTruckRequest] = useState<
    Schemas['PartRequestResponse'] | null
  >(null);
  const [showTruckRequestFormModal, setShowTruckRequestFormModal] =
    useState(false);
  const [showArchivedTicketsModal, setShowArchivedTicketsModal] =
    useState(false);
  const [filteredTruckRequests, setFilteredTruckRequests] = useState<
    Schemas['TruckingRequestResponse'][]
  >([]);

  const {
    data: truckRequestsResponse,
    error: truckRequestsError,
    isPending: truckRequestsPending,
    refetch: refetchTruckRequests,
  } = useQuery({
    queryKey: ['truckRequests'],
    queryFn: () =>
      DB.GET('/trucking-requests').then(
        (res: { data: Schemas['TruckingRequestListResponse'] }) =>
          res.data.truckingRequests,
      ),
  });

  const { Archived: archivedTruckRequests, Active: truckRequests } =
    useMemo(() => {
      if (truckRequestsResponse) {
        return truckRequestsResponse.reduce(
          (acc, val) => {
            if (val.status === 'Archived') {
              acc.Archived.push(val);
            } else {
              acc.Active.push(val);
            }
            return acc;
          },
          { Archived: [], Active: [] },
        );
      }
      return { Archived: [], Active: [] };
    }, [truckRequestsResponse]);

  const exportToJSON = useCallback(() => {
    const name = 'TruckingRequests_' + new Date().toISOString();
    downloadObjectsAsCSV(truckRequests, name);
  }, [truckRequests]);

  if (truckRequestsPending) {
    return (
      <Center w="$full" h="$full">
        <LoadingLogoAndSpinner />
      </Center>
    );
  }

  if (truckRequestsError) {
    return (
      <Center w="$full" h="$full" gap="$6">
        <Icon as={Icons.AlertCircle} color="$error500" w="$16" h="$16" />
        <Text size="lg" color="$error500">
          There was an error retrieving truck requests.
        </Text>
      </Center>
    );
  }

  return (
    <PageWrapper
      ToolbarContent={
        <HStack flex={1} justifyContent="space-between">
          <HStack space="xl" alignItems="center">
            <Button
              size="lg"
              onPress={() => setShowTruckRequestFormModal(true)}
            >
              <ButtonIcon as={Icons.Plus} />
              <ButtonText>Create Ticket</ButtonText>
            </Button>
            <Button size="lg" variant="outline" onPress={exportToJSON}>
              <ButtonIcon as={Icons.Download} />
              <ButtonText>Export</ButtonText>
            </Button>
          </HStack>

          <HStack space="xl" alignItems="center">
            <Button
              size="lg"
              variant="ghost"
              onPress={() => setShowArchivedTicketsModal(true)}
            >
              <ButtonIcon as={Icons.Archive} />
              <ButtonText>Archived Tickets</ButtonText>
            </Button>

            <FilterButton
              data={truckRequests}
              setFilteredData={setFilteredTruckRequests}
              filtersData={filtersData}
            />
          </HStack>
        </HStack>
      }
    >
      <HStack w="$full" h="$full" px="$5" pt="$4">
        <ScrollView
          horizontal
          flex={1}
          h="$full"
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'space-between',
          }}
          pr="$3"
        >
          <EntityCardColumnsView
            data={filteredTruckRequests}
            groupByPath="status"
            columnGroupings={[
              { value: 'New', indicatorDotVariant: 'neutral' },
              { value: 'Assigned', label: 'Assigned/In Progress' },
              { value: 'Completed' },
            ]}
            CardComponent={({
              data,
            }: {
              data: Schemas['PartRequestResponse'];
            }) => (
              <TruckRequestCard
                truckRequest={data}
                onPress={(pr) => setSelectedTruckRequest(pr)}
              />
            )}
            columnContainerProps={{ minWidth: '$64' }}
          />
        </ScrollView>
      </HStack>

      {!selectedTruckRequest && showArchivedTicketsModal && (
        <ArchivedTruckRequestsModal
          isOpen={showArchivedTicketsModal}
          onClose={() => setShowArchivedTicketsModal(false)}
          onSelectTicket={(ticket) => {
            setSelectedTruckRequest(ticket);
            setShowTruckRequestFormModal(true);
          }}
          // TODO: Filter only archived once we have some
          truckRequests={archivedTruckRequests}
        />
      )}

      {showTruckRequestFormModal && (
        <TruckRequestFormModal
          isOpen={showTruckRequestFormModal}
          onClose={() => {
            setShowTruckRequestFormModal(false);
            setShowTruckRequestFormModal(false);
            setSelectedTruckRequest(null);
          }}
          truckRequest={selectedTruckRequest}
          refetch={refetchTruckRequests}
        />
      )}

      {!!selectedTruckRequest && !showTruckRequestFormModal && (
        <TruckRequestSummaryModal
          truckRequest={selectedTruckRequest}
          isOpen={true}
          onClose={() => setSelectedTruckRequest(null)}
          onPressEdit={() => setShowTruckRequestFormModal(true)}
          refetch={refetchTruckRequests}
        />
      )}
    </PageWrapper>
  );
};
