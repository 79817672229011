import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormFieldsObject, FormProps } from '../../Form.tsx';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import {
  Center,
  Icons,
  Spinner,
  useAlert,
} from '@tonic/central-specialties-ui-themed';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Divider, HStack, Icon, Text } from '@gluestack-ui/themed';
import { extractImageDataFromUrl } from '../../../../../utils/extractImageDataFromUrl.ts';
import { getLatLongFromAddress } from '../../../../../utils/getLatLongFromAddress.ts';
import moment from 'moment';

interface RepairRequestFormProps {
  repairRequestId?: string;
  isReadonlyMode?: boolean;
  /**
   * If true, the form will show an additional form group to the right of the main form group,
   * allowing assignment and a few other fields.
   */
  isAdminMode?: boolean;
  onSuccess?: () => void;
  formProps?: Partial<FormProps>;
}

// Simple rapper to fetch a passed item by ID, since entity details in list responses are being removed
export const RepairRequestForm = ({
  repairRequestId,
  ...restProps
}: RepairRequestFormProps) => {
  const { alert } = useAlert();
  const {
    data: repairRequest,
    isPending,
    error,
  } = useQuery({
    queryKey: ['repairRequest', repairRequestId],
    queryFn: async () =>
      DB.GET('/repair-requests/{id}', {
        params: { path: { id: repairRequestId } },
      }).then((res: { data: Schemas['RepairRequestModel'] }) => res.data),
    enabled: !!repairRequestId,
  });

  if (isPending && repairRequestId !== undefined) {
    return (
      <Center h="$full" minHeight="$96" flex={1}>
        <Spinner />
      </Center>
    );
  }

  if (error) {
    alert({
      message: 'There was an error fetching the repair request.',
      status: 'error',
      timeout: 10000,
    });
    return (
      <Center w="$full" h="$full" gap="$6">
        <Icon as={Icons.AlertCircle} color="$error500" w="$16" h="$16" />
        <Text size="lg" color="$error500">
          There was an error retrieving repair request {repairRequestId}
        </Text>
      </Center>
    );
  }

  return (
    <RepairRequestFormInner repairRequest={repairRequest} {...restProps} />
  );
};

interface RepairRequestFormInnerProps extends RepairRequestFormProps {
  repairRequest?: Schemas['RepairRequestModel'];
}

export const RepairRequestFormInner = ({
  repairRequest,
  isReadonlyMode,
  isAdminMode = false,
  onSuccess,
  formProps,
}: RepairRequestFormInnerProps) => {
  const { alert } = useAlert();

  const mode = useMemo<'create' | 'edit' | 'archive'>(() => {
    switch (true) {
      case repairRequest &&
        (repairRequest.status === 'Archived' ||
          repairRequest.status === 'Cancelled'):
        return 'archive';
      case repairRequest && 'status' in repairRequest:
        return 'edit';
      default:
        return 'create';
    }
  }, [repairRequest]);

  const [defaults, setDefaults] = useState<Record<string, any> | null>(null);
  const [enableDynamicFormAddress, setEnableDynamicFormAddress] =
    useState(true);
  ///-----------------------------------------------------------------------
  // FORM OPTIONS FETCHING
  ///-----------------------------------------------------------------------
  const {
    data: allJobs,
    isPending: jobsPending,
    error: jobsFetchError,
  } = useQuery({
    queryKey: ['allJobs'],
    queryFn: async () =>
      DB.GET('/jobs').then(
        (res: { data: Schemas['JobResponse'][] }) => res.data,
      ),
  });

  const jobOptions = useMemo(() => {
    if (!allJobs) {
      return {};
    }
    return Object.fromEntries(
      allJobs
        .filter((j: Schemas['JobCodeModel']) => j?.id && j?.number)
        .map((jcObj: Schemas['JobCodeModel']) => {
          return [jcObj.number, jcObj.id];
        }),
    );
  }, [allJobs]);

  const {
    data: equipmentOptions,
    isPending: equipmentPending,
    error: equipmentFetchError,
  } = useQuery({
    queryKey: ['equipmentOptions'],
    queryFn: async () =>
      DB.GET('/equipment').then(
        (res: { data: Schemas['EquipmentListResponse'] }) => res.data.equipment,
      ),
  });

  const {
    data: potentialAssignees,
    isPending: potentialAssigneesPending,
    error: potentialAssigneesFetchError,
  } = useQuery({
    queryKey: ['repairRequestAssignees'],
    queryFn: async () =>
      DB.GET('/users/by-role/{roleName}', {
        params: {
          path: {
            roleName: 'Mechanic',
          },
        },
      }).then((res: { data: Schemas['UserModel'][] }) => res.data),
    enabled: isAdminMode,
  });

  ///-----------------------------------------------------------------------
  // MUTATIONS
  ///-----------------------------------------------------------------------
  const { mutateAsync: createRepairRequest } = useMutation({
    mutationFn: (data: Schemas['RepairRequestRequest']) =>
      DB.POST('/repair-requests', {
        body: data,
      }),
  });

  const { mutateAsync: updateRepairRequest } = useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: string;
      data: Schemas['RepairRequestRequest'];
    }) =>
      DB.PUT('/repair-requests/{id}', {
        params: { path: { id } },
        body: data,
      }),
  });

  const { mutateAsync: updateRepairRequestStatus } = useMutation({
    mutationFn: ({
      id,
      status,
    }: {
      id: string;
      status: Schemas['RepairRequestStatus'];
    }) =>
      DB.POST('/repair-requests/{id}/status', {
        params: { path: { id } },
        body: { status },
      }),
  });

  const { mutateAsync: uploadImages } = useMutation({
    mutationFn: async (images: Schemas['ImageModel'][]): Promise<string[]> => {
      const newOnly = images.filter((img) => img?.isNew);
      const existing = images.filter((img) => !img?.isNew);
      for (const img of newOnly) {
        const imageData = await extractImageDataFromUrl(img.url);
        const { data: newImgData, response: newImgResponse } = await DB.POST(
          '/repair-requests/image',
          {
            body: { imageData },
          },
        );
        if (newImgResponse.ok) {
          existing.push(newImgData);
        }
      }
      return existing.map((img) => img.id);
    },
  });

  const { mutateAsync: assignRepairRequest } = useMutation({
    mutationFn: ({ id, userId }: { id: string; userId: string }) =>
      DB.POST('/repair-requests/{id}/assign', {
        params: {
          path: {
            id,
          },
        },
        body: {
          userId,
        },
      }),
  });

  const submit = useCallback(
    async (data: Record<string, any>) => {
      // If images were added, upload them and replace the image URLs with the new IDs
      if (
        data.images.filter((img: Schemas['ImageModel']) => img?.isNew).length
      ) {
        data.imageIds = await uploadImages(data.images);
      } else {
        data.imageIds = data.images.map((img: Schemas['ImageModel']) => img.id);
      }

      const {
        jobId,
        equipmentNumber,
        priority,
        address,
        description,
        notes,
        workOrderNumber,
        hoursMiles,
        rootCause,
      } = data;

      let { latitude, longitude } = data;

      // If lat and long aren't specified directly, try to get them from the address
      if (latitude === 0 && longitude === 0) {
        const { latitude: latFromAddress, longitude: longFromAddress } =
          await getLatLongFromAddress(address);
        latitude = latFromAddress;
        longitude = longFromAddress;
      }

      const equipmentId =
        equipmentOptions.find((eq) => eq.number === equipmentNumber)?.id ||
        null;

      const payload: Schemas['RepairRequestRequest'] = {
        jobId,
        equipmentId,
        priority,
        latitude,
        longitude,
        address,
        description,
        notes,
        workOrderNumber,
        hoursMiles,
        rootCause: rootCause ? rootCause : null,
        imageIds: data.imageIds,
      };
      let errorAssigning = false;
      switch (mode) {
        case 'create':
          try {
            const { data: createResponseData, error } =
              await createRepairRequest(payload);
            if (error) {
              throw error;
            }
            if (data.assignedTo) {
              const { error: assignError } = await assignRepairRequest({
                id: createResponseData?.id,
                userId: data.assignedTo,
              });
              if (assignError) {
                errorAssigning = true;
                alert({
                  status: 'warning',
                  message:
                    'The request was created but there was an error assigning. Please assign manually.',
                  timeout: 10000,
                });
              }
            }
            if (!errorAssigning) {
              alert({
                status: 'success',
                message: 'Success! Ticket created.',
                timeout: 5000,
              });
            }
            onSuccess && onSuccess();
          } catch (e) {
            alert({
              message: 'There was an error creating the repair request.',
              status: 'error',
              timeout: 10000,
            });
          }
          break;
        case 'edit':
          try {
            const { error } = await updateRepairRequest({
              id: repairRequest.id,
              data: payload,
            });
            if (error) {
              throw error;
            }
            if (
              !!data.assignedTo &&
              data.assignedTo !== repairRequest.assignedTo?.id
            ) {
              const { error: assignError } = await assignRepairRequest({
                id: repairRequest.id,
                userId: data.assignedTo,
              });
              if (assignError) {
                errorAssigning = true;
                alert({
                  status: 'error',
                  message: 'There was an error assigning the repair request.',
                  timeout: 10000,
                });
              }
            }
            if (!errorAssigning) {
              alert({
                status: 'success',
                message: 'Success! changes saved.',
                timeout: 5000,
              });
            }
            onSuccess && onSuccess();
          } catch (e) {
            alert({
              message: 'There was an error updating the repair request.',
              status: 'error',
              timeout: 10000,
            });
          }
          break;
        case 'archive':
          // Update the status to 'New' (unarchive)
          await updateRepairRequestStatus({
            id: repairRequest.id,
            status: 'New',
          })
            .then((res) => {
              if (res.error) {
                throw res.error;
              }
              alert({
                message: 'Success! Ticket unarchived.',
                status: 'success',
                timeout: 5000,
              });
              onSuccess && onSuccess();
              return res;
            })
            .catch((err) => {
              alert({
                message: 'There was an error unarchiving the repair request.',
                status: 'error',
                timeout: 10000,
              });
              return err;
            });
          break;
        default:
          console.error(
            'Something is wrong with the "mode" set in the RepairRequestForm',
          );
          break;
      }
    },
    [
      mode,
      alert,
      equipmentOptions,
      assignRepairRequest,
      createRepairRequest,
      updateRepairRequest,
      onSuccess,
      uploadImages,
      updateRepairRequestStatus,
      repairRequest?.id,
      repairRequest?.assignedTo?.id,
    ],
  );

  ///-----------------------------------------------------------------------
  // SET DEFAULTS
  ///-----------------------------------------------------------------------
  useEffect(() => {
    const primaryFieldDefaults = {
      jobId: repairRequest?.job?.id || '',
      equipmentNumber: repairRequest?.equipment?.number || '',
      equipmentId: repairRequest?.equipment?.id || '',
      priority: repairRequest?.priority || '',
      latitude: repairRequest?.latitude || '',
      longitude: repairRequest?.longitude || '',
      address: repairRequest?.address || '',
      description: repairRequest?.description || '',
      images: repairRequest?.images || [],
      hoursMiles: repairRequest?.hoursMiles || '',
      rootCause: repairRequest?.rootCause || '',
    };

    const adminFieldDefaults = {
      assignedTo: repairRequest?.assignedTo?.id || '',
      createdAt: repairRequest?.createdAt
        ? moment(repairRequest.createdAt)
            .utc(true)
            .tz('America/Chicago')
            .format('h:mma M/D/YY')
        : '',
      reporter: repairRequest?.createdBy
        ? `${repairRequest.createdBy?.fullName} - ${repairRequest.createdBy?.employeeNumber}`
        : '',
      workOrderNumber: repairRequest?.workOrderNumber || '',
      notes: repairRequest?.notes || '',
    };

    setDefaults(
      isAdminMode
        ? { ...primaryFieldDefaults, ...adminFieldDefaults }
        : primaryFieldDefaults,
    );
  }, [repairRequest, isAdminMode]);

  ///-----------------------------------------------------------------------
  // HANDLE OPTION FETCH ERRORS
  ///-----------------------------------------------------------------------
  useEffect(() => {
    if (
      !isReadonlyMode &&
      (jobsFetchError ||
        equipmentFetchError ||
        (isAdminMode && potentialAssigneesFetchError))
    ) {
      alert({
        message:
          'There was an error fetching the job, equipment, or assignee options.',
        status: 'error',
        timeout: 10000,
      });
    }
  }, [
    jobsFetchError,
    equipmentFetchError,
    potentialAssigneesFetchError,
    isAdminMode,
    isReadonlyMode,
    alert,
  ]);

  ///-----------------------------------------------------------------------
  // CREATE FIELDS
  ///-----------------------------------------------------------------------
  // It's an array in admin mode, since the admin fields are split into their own column
  const formFields: FormFieldsObject | FormFieldsObject[] = useMemo(() => {
    const primaryFields: FormFieldsObject = {
      jobNumber: {
        type: 'Select',
        dataFieldKey: 'jobId',
        label: 'Job number',
        placeholder: 'Select job number',
        onChange: () => {
          setEnableDynamicFormAddress(true);
        },
        options: jobOptions,
        required: true,
      },

      jobName: {
        type: 'Text',
        dataFieldKey: 'jobName',
        readonly: true,
        populate: (data: any) => {
          if (!allJobs) {
            return 'No description';
          }
          return (
            allJobs.find((j: Schemas['JobCodeModel']) => j.id === data.jobId)
              ?.description || 'No description'
          );
        },
        label: 'Job name',
      },

      equipmentNumber: {
        type: 'Text',
        dataFieldKey: 'equipmentNumber',
        label: 'Equipment number',
        placeholder: 'Enter equipment number',
        required: true,
      },

      equipmentDescription: {
        type: 'Text',
        dataFieldKey: 'equipmentDescription',
        label: 'Equipment description',
        populate: (formData: any) =>
          (equipmentOptions &&
            equipmentOptions.find(
              (opt: Schemas['JobCodeModel']) =>
                opt.number === formData.equipmentNumber,
            )?.description) ||
          'No description',
        readonly: true,
      },

      priority: {
        type: 'Select',
        dataFieldKey: 'priority',
        label: 'Priority rating',
        options: {
          'Critical - Job is halted': 'Critical',
          'High - Issue significantly hinders progress': 'High',
          'Medium - Issue hinders progress': 'Medium',
          'Low - Low priority': 'Low',
          'Winter - Winter maintenance': 'Winter',
        },
        required: true,
      },

      rootCause: {
        type: 'Select',
        dataFieldKey: 'rootCause',
        label: 'Root Cause',
        options: {
          'General Maintenance': 'GeneralMaintenance',
          'Lack Maintenance': 'LackMaintenance',
          'Operator Error': 'OperatorError',
          'Operator Abuse': 'OperatorAbuse',
          'Manufacturer Defect': 'ManufacturerDefect',
        },
      },

      issueDescription: {
        type: 'Text',
        label: 'Issue description',
        multiline: true,
        dataFieldKey: 'description',
        required: true,
      },

      images: {
        type: 'Images',
        label: 'Images',
        dataFieldKey: 'images',
        placeholder: 'Option to add photo of equipment or issue',
      },

      equipmentLocation: {
        type: 'Address',
        label: 'Equipment location',
        dataFieldKey: ['address', 'latitude', 'longitude'],
        dynamicUpdate: (data: any) => {
          if (!allJobs || isReadonlyMode) {
            setEnableDynamicFormAddress(false);
            return null;
          }

          if (
            data &&
            data?.createdAt &&
            data?.jobId === repairRequest?.job?.id
          ) {
            setEnableDynamicFormAddress(false);
            return null;
          }
          const jobAddress =
            allJobs.find((j: Schemas['JobCodeModel']) => j.id === data.jobId)
              ?.address || '';
          setEnableDynamicFormAddress(false);
          return jobAddress;
        },
        enableDynamicForm: enableDynamicFormAddress,
        required: true,
      },
      hoursMiles: {
        type: 'Text',
        dataFieldKey: 'hoursMiles',
        label: 'Hours/Miles',
        placeholder: 'Hours/Miles',
        hidden: (data: typeof defaults) => data?.isNoWorkEntry === true,
      },
    };

    const adminFields: FormFieldsObject = {
      mechanic: {
        type: 'Select',
        dataFieldKey: 'assignedTo',
        label: 'Mechanic',
        placeholder: 'Assign Ticket',
        options: Object.fromEntries(
          (potentialAssignees || []).map((mechanic) => [
            `${mechanic.fullName} - ${mechanic.employeeNumber}`,
            mechanic.id,
          ]),
        ),
        isSuperSelect: true,
      },
      timeSubmitted: {
        type: 'Text',
        label: 'Time submitted',
        dataFieldKey: 'createdAt',
        readonly: true,
      },
      reporter: {
        type: 'Text',
        label: 'Reporter',
        dataFieldKey: 'reporter',
        readonly: true,
      },
      workOrderNumber: {
        type: 'Text',
        label: 'Repair order',
        dataFieldKey: 'workOrderNumber',
      },
      repairAdminNotes: {
        type: 'Text',
        multiline: true,
        label: 'Repair admin notes',
        dataFieldKey: 'notes',
      },
    };
    return isAdminMode ? [primaryFields, adminFields] : primaryFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaults,
    allJobs,
    jobOptions,
    equipmentOptions,
    isAdminMode,
    potentialAssignees,
    enableDynamicFormAddress,
  ]);

  // If any of the required options are pending or either formFields or defaults haven't populated based on them yet, show loading spinner
  if (
    jobsPending ||
    equipmentPending ||
    (isAdminMode && potentialAssigneesPending) ||
    !formFields ||
    !defaults
  ) {
    return (
      <Center h="$full" minHeight="$96" flex={1}>
        <Spinner />
      </Center>
    );
  }
  return (
    <Form
      formData={defaults}
      fields={formFields}
      readonly={!!isReadonlyMode}
      onSubmit={submit}
      Container={({ children }) => (
        <HStack flex={1} h="$full" space="xl">
          {children}
        </HStack>
      )}
      Divider={() => <Divider orientation="vertical" subtle />}
      {...formProps}
    />
  );
};
