import React from 'react';
import {
  Box,
  Icons,
  Modal,
  ModalBackdrop,
  ModalContent,
  ModalHeader,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { Heading, Icon, Pressable } from '@gluestack-ui/themed';

export interface SidebarModalProps
  extends React.ComponentProps<typeof ModalContent> {
  isOpen: boolean;
  onClose: () => void;
}

export const SidebarModal = ({
  isOpen,
  onClose,
  children,
  ...modalContentProps
}: React.PropsWithChildren<SidebarModalProps>) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      useRNModal
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'flex-end',
      }}
    >
      <ModalBackdrop />
      <ModalContent
        borderTopLeftRadius="$md"
        borderBottomLeftRadius="$md"
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
        h="$full"
        w="$80"
        {...modalContentProps}
      >
        <ModalHeader w="$full" alignItems="center">
          <Box w="$7" />
          <Heading>Filter By</Heading>
          <Pressable width="$7" onPress={onClose}>
            <Icon as={Icons.X} size="xl" />
          </Pressable>
        </ModalHeader>
        <VStack w="$full" flex={1} p="$3">
          {children}
        </VStack>
      </ModalContent>
    </Modal>
  );
};
