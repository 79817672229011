import React, { useEffect, useState } from 'react';
import { useAlert, VStack } from '@tonic/central-specialties-ui-themed';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { MapView } from '../../../Map/MapView.tsx';
import { FormFieldData } from '../FormField.types.ts';
import { Text } from '@gluestack-ui/themed';

export interface LocationData {
  address: string;
  latitude: number;
  longitude: number;
}

export interface AddressFormFieldData {
  hideMap?: boolean;
  mapZoom?: number;
}

export const AddressFormField = ({
  value,
  onChange,
  readonly,
  size,
  invalid,
  hideMap = false,
  mapZoom = 50,
}: FormFieldData<'Address'>) => {
  const { alert } = useAlert();
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [placeValue, setPlaceValue] = useState(null);
  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    if (value) {
      const address = typeof value === 'string' ? value : value.address;
      if (address) {
        // handle case where address string is just latLong string
        const parsedLatLong = splitLatLongString(address);
        const areCoordinatesEqual = parsedLatLong
          ? parsedLatLong.latitude === mapCenter.lat &&
            parsedLatLong.longitude === mapCenter.lng
          : false;

        if (parsedLatLong !== null) {
          if (!areCoordinatesEqual) {
            setPlaceValue({
              label: address,
              value: [
                {
                  address,
                  ...parsedLatLong,
                },
              ],
            });
            setMapCenter({
              lat: parsedLatLong.latitude,
              lng: parsedLatLong.longitude,
            });
          }
        }
        // if address is not a latLong string, query the latLong based on it
        else {
          if (address !== placeValue?.label && !fetchError) {
            try {
              geocodeByAddress(address)
                .then((results) => {
                  // Check if we get a 200 response with an error message
                  if (
                    results?.error_message ||
                    results?.status === 'OVER_QUERY_LIMIT'
                  ) {
                    alert({
                      status: 'error',
                      message:
                        'Failed to access Google Maps Places API. Please try again later.',
                    });
                    return null;
                  }

                  setPlaceValue({
                    label: address,
                    value: results,
                  });
                  return getLatLng(results[0]);
                })
                .then(setMapCenter);
              setFetchError(false);
            } catch (error) {
              console.error(
                'Failed to fetch latitude and longitude after updating address from job selection:',
                error,
              );
              alert({
                status: 'error',
                message:
                  'Failed to access Google Maps Places API. Please try again later.',
              });
              setFetchError(true);
            }
          }
        }
      }
    }
  }, [value, mapCenter, placeValue, fetchError, alert]);

  const setLocation = (
    latLongOrAddress: { lat: number; lng: number } | string,
  ) => {
    if (!readonly) {
      // if an address string is passed, get latLong associated and set state with it
      if (typeof latLongOrAddress === 'string') {
        const address = latLongOrAddress;
        if (address !== placeValue?.label) {
          try {
            geocodeByAddress(address)
              .then((results) => {
                // Check if we get a 200 response with an error message
                if (
                  results?.error_message ||
                  results?.status === 'OVER_QUERY_LIMIT'
                ) {
                  alert({
                    status: 'error',
                    message:
                      'Failed to access Google Maps Places API. Please try again later.',
                  });
                  return null;
                }

                setPlaceValue({
                  label: address,
                  value: results,
                });
                return getLatLng(results[0]);
              })
              .then((center) => {
                setMapCenter(center);
                onChange({
                  address,
                  latitude: center.lat,
                  longitude: center.lng,
                });
              });
          } catch (error) {
            console.error(
              'Failed to fetch latitude and longitude after updating address from job selection:',
              error,
            );
          }
        }
      }
      // if latLong is passed, use it to set state directly
      else {
        const latLong = latLongOrAddress;
        setMapCenter(latLong);
        onChange({
          address: `${latLong.lat},${latLong.lng}`,
          latitude: latLong.lat,
          longitude: latLong.lng,
        });
      }
    }
  };

  const getDisplayText = () => {
    if (typeof value === 'string') {
      return value;
    } else if (typeof value?.address === 'string') {
      return value.address;
    }
    return 'Not specified';
  };

  return (
    <VStack flex={1} gap="$3">
      {readonly ? (
        <Text size={size} pl="$0.5">
          {getDisplayText()}
        </Text>
      ) : (
        <GooglePlacesAutocomplete
          invalid={invalid}
          placeholder=""
          styles={{
            background: 'blue',
            borderWidth: 10,
            borderColor: 'green',
          }}
          selectProps={{
            value: placeValue,
            onChange: (v) => {
              if (v?.label) {
                setLocation(v.label);
              } else {
                alert({
                  status: 'error',
                  message:
                    'Failed to access Google Maps Places API. Please try again later.',
                });
              }
            },
          }}
          onLoadFailed={() => {
            alert({
              status: 'error',
              message:
                'Failed to load address lookup api. Please try again later.',
            });
          }}
        />
      )}
      {!hideMap && (
        <MapView
          center={mapCenter}
          markers={[mapCenter]}
          zoom={mapZoom}
          setLatLong={setLocation}
          readonly={readonly}
        />
      )}
    </VStack>
  );
};

export const splitLatLongString = (
  value: string,
): null | Omit<LocationData, 'address'> => {
  const split = value.split(',');
  const latitude = parseFloat(split[0]);
  const longitude = parseFloat(split[1]);
  if (split.length !== 2 || isNaN(latitude) || isNaN(longitude)) {
    return null;
  }
  return {
    latitude,
    longitude,
  };
};
