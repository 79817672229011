import {
  Checkbox,
  CheckboxGroup,
  CheckboxIcon,
  CheckboxIndicator,
  CheckboxLabel,
  CheckIcon,
} from '@tonic/central-specialties-ui-themed';
import { FormFieldData } from '../FormField.types.ts';
import React from 'react';

export interface CheckboxGroupFormFieldData {
  options: string[] | Record<string, string | boolean | number>;
  wrap?: boolean;
  checkboxGroupProps?: Partial<React.ComponentProps<typeof CheckboxGroup>>;
}

export const CheckboxGroupFormField = ({
  label,
  options,
  value,
  onChange,
  checkboxGroupProps,
  readonly,
  wrap,
}: FormFieldData<'CheckboxGroup'>) => {
  if (Array.isArray(options)) {
    options = Object.fromEntries(options.map((val) => [val, val]));
  }

  return (
    <CheckboxGroup
      onChange={readonly ? undefined : onChange}
      isDisabled={readonly}
      defaultValue={value}
      aria-label={label + ' checkbox group'}
      w="$full"
      gap="$2"
      flexWrap={wrap ? 'wrap' : 'nowrap'}
      {...checkboxGroupProps}
    >
      {Object.entries(options).map(([optionLabel, optionValue]) => (
        <Checkbox
          key={`FORM:${label}_checkbox_option:${optionLabel}`}
          isChecked={value.includes(optionValue)}
          defaultIsChecked={value.includes(optionValue)}
          value={optionValue}
          size="lg"
          aria-label={optionLabel + ' checkbox'}
        >
          <CheckboxIndicator mr="$2">
            <CheckboxIcon as={CheckIcon} />
          </CheckboxIndicator>
          <CheckboxLabel>{optionLabel}</CheckboxLabel>
        </Checkbox>
      ))}
    </CheckboxGroup>
  );
};
