import { Schemas } from '@tonic/central-specialties-utils';
import React from 'react';
import { Card, VStack } from '@tonic/central-specialties-ui-themed';
import { HStack, Text } from '@gluestack-ui/themed';
import moment from 'moment';

interface ArchiveJobSummaryCardProps {
  jobRequest: Schemas['JobRequestResponse'];
  cardProps?: React.ComponentProps<typeof Card>;
  onPress?: () => unknown;
}

export const ArchiveJobSummaryCard = ({
  jobRequest,
  cardProps,
  onPress,
}: ArchiveJobSummaryCardProps) => {
  return (
    <Card minWidth="$64" {...cardProps} onPress={onPress}>
      <HStack alignItems="center" justifyContent="center" flex={1} gap="$4">
        <VStack flex={1} gap="$0.5" justifyContent="space-between">
          <VStack gap="$0.5">
            <Text bold>Job {jobRequest?.number || '(number pending)'}</Text>
            <Text>{jobRequest?.name}</Text>
            <Text>Description: {jobRequest?.description}</Text>
            <Text>
              Date submitted:{' '}
              {moment(jobRequest.createdAt)
                .utc(true)
                .tz('America/Chicago')
                .format('M/D/YY')}
            </Text>
            <Text>PM 1: {jobRequest?.pm1}</Text>
            <Text>PM 2: {jobRequest?.pm2}</Text>
            <Text>Location: {jobRequest?.address}</Text>
          </VStack>
        </VStack>
      </HStack>
    </Card>
  );
};
