import './App.css';
import React, { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { AdminPanel } from './routes/root/AdminPanel.tsx';
import {
  AuthRoutes,
  Icons,
  useAuth,
  Text,
} from '@tonic/central-specialties-ui-themed';
import { AuthPage } from './routes/auth/AuthPage.tsx';
import { FeatureCategory } from '@tonic/central-specialties-utils';
import { RepairsPage } from './routes/root/RepairsPage/RepairsPage.tsx';
import { ProfilePage } from './routes/root/ProfilePage/ProfilePage.tsx';
import { LoadingLogoAndSpinner } from './components/general/LoadingLogoAndSpinner.tsx';
import { TimeCardsPage } from './routes/root/TimeCardsPage/TimeCardsPage.tsx';
import { ManagePage } from './routes/root/ManagePage/ManagePage.tsx';
import { UsersPage } from './routes/root/UsersPage/UsersPage.tsx';
import { Loader as GoogleMapsAPILoader } from '@googlemaps/js-api-loader';
import { PartsPage } from './routes/root/PartsPage/PartsPage.tsx';
import { TrucksPage } from './routes/root/TrucksPage/TrucksPage.tsx';
import { SchedulePage } from './routes/root/SchedulePage/SchedulePage.tsx';
import {
  Center,
  Heading,
  HStack,
  Icon,
  Pressable,
  VStack,
} from '@gluestack-ui/themed';

interface AppContainerProps {}

const AppContainer = (_props: AppContainerProps) => (
  <div className="root">
    <Outlet />
  </div>
);

const sectionComponentMap: { [Key in FeatureCategory]: React.ReactElement } = {
  Repair: <RepairsPage />,
  Parts: <PartsPage />,
  Time: <TimeCardsPage />,
  Manage: <ManagePage />,
  Users: <UsersPage />,
  Truck: <TrucksPage />,
  Schedule: <SchedulePage />,
};

// Load Google Maps APIs we need (maps and places)
const loader = new GoogleMapsAPILoader({
  apiKey: import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places', 'maps'],
});

function App() {
  const { pending, sectionsAvailable, isAdmin } = useAuth();

  // On first mount, load necessary maps libs
  useEffect(() => {
    loader.importLibrary('maps');
    loader.importLibrary('places');
    loader.importLibrary('core');
  }, []);

  return (
    <Routes>
      <Route path="/" element={<AppContainer />}>
        <Route path="auth" element={<AuthPage />}>
          {AuthRoutes({ Route })}
        </Route>
        <Route
          element={
            pending ? (
              <LoadingLogoAndSpinner />
            ) : isAdmin ? (
              <AdminPanel />
            ) : (
              <NonAdminView />
            )
          }
        >
          <Route index element={<Navigate to={sectionsAvailable[0]} />} />
          {sectionsAvailable.map((section) => (
            <Route
              key={section + 'Route'}
              path={section}
              element={sectionComponentMap[section]}
            />
          ))}
          <Route path="Profile" element={<ProfilePage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;

const NonAdminView = () => {
  const { logout } = useAuth();
  return (
    <Center w="100vw" h="$full">
      <VStack gap="$8" alignItems="center">
        <Icon as={Icons.AlertCircle} w="$12" h="$12" color="$error500" />
        <Text size="lg" color="$errorText">
          You must be an admin to use this application
        </Text>
        <Pressable
          w="$full"
          mb="$5"
          onPress={logout}
          sx={{ ':hover': { bg: '$gray200' } }}
          justifyContent="center"
        >
          <HStack
            w="$full"
            h="$16"
            space="md"
            px="$10"
            justifyContent="center"
            alignItems="center"
          >
            <Icon as={Icons.LogOut} size="xl" />
            <Heading size="xl">Back To Login</Heading>
          </HStack>
        </Pressable>
      </VStack>
    </Center>
  );
};
