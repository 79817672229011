import { PageWrapper } from '../../../components/general/PageWrapper.tsx';
import {
  Button,
  ButtonIcon,
  ButtonText,
  HStack,
  Icon,
  ScrollView,
  Text,
} from '@gluestack-ui/themed';
import { Center, Icons, VStack } from '@tonic/central-specialties-ui-themed';
import React, { useEffect, useMemo, useState } from 'react';
import { ArchivedTruckRequestsModal } from '../TrucksPage/ArchivedTruckRequestsModal/ArchivedTruckRequestsModal.tsx';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import { LoadingLogoAndSpinner } from '../../../components/general/LoadingLogoAndSpinner.tsx';
import { DateSelectStrip } from '../../../components/general/EntityCardExplorer/DateSelect/DateSelectStrip.tsx';
import moment, { Moment } from 'moment';
import { useQuery } from '@tanstack/react-query';
import { EntityCardColumnsView } from '../../../components/general/EntityCardExplorer/EntityCardColumnsView/EntityCardColumnsView.tsx';
import { TruckRequestCard } from '../../../components/general/cards/TruckRequestCard.tsx';
import { TruckRequestFormModal } from '../../../components/general/Form/specific/TruckRequestForm/TruckRequestFormModal.tsx';
import { TruckRequestSummaryModal } from '../TrucksPage/TruckRequestSummaryModal/TruckRequestSummaryModal.tsx';
import {
  FilterButton,
  FiltersData,
} from '../../../components/general/filtering/FilterButton.tsx';

const startDay = moment()
  .subtract(1, 'month')
  .startOf('month')
  .utc()
  .toDate()
  .toISOString();
const endDay = moment()
  .endOf('month')
  .add(1, 'month')
  .utc()
  .toDate()
  .toISOString();

const filtersData: FiltersData<Schemas['TruckingRequestResponse']> = {
  'Truck company': {
    path: ['trucks', 'companyName'],
    formatOptions: 'noNull',
  },
  'Job number': {
    path: ['job', 'number'],
    formatOptions: 'noNull',
  },
  'Date submitted': {
    path: ['createdAt'],
    formatOptions: 'date',
    matcher: 'date',
    sortOptions: 'date',
  },
};

export const SchedulePage = () => {
  const [timeframe, setTimeframe] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: startDay,
    endDate: endDay,
  });

  const [dateSelected, setDateSelected] = useState(moment());
  const [selectedTruckRequest, setSelectedTruckRequest] = useState<
    Schemas['PartRequestResponse'] | null
  >(null);
  const [showTruckRequestFormModal, setShowTruckRequestFormModal] =
    useState(false);
  const [showArchivedTicketsModal, setShowArchivedTicketsModal] =
    useState(false);
  const [filteredTruckRequests, setFilteredTruckRequests] = useState<
    Schemas['TruckingRequestResponse'][]
  >([]);

  const {
    data: truckRequestsResponse,
    error: truckRequestsError,
    isPending: truckRequestsPending,
    refetch: refetchTruckRequests,
  } = useQuery({
    queryKey: ['truckRequests'],
    queryFn: () =>
      DB.GET('/trucking-requests').then(
        (res: { data: Schemas['TruckingRequestListResponse'] }) =>
          res.data.truckingRequests,
      ),
  });

  const { Archived: archivedTruckRequests, Active: truckRequests } =
    useMemo(() => {
      if (truckRequestsResponse) {
        return truckRequestsResponse.reduce(
          (acc, val) => {
            if (val.status === 'Archived') {
              acc.Archived.push(val);
            } else if (val.status !== 'New') {
              // Since schedule page only shows assigned requests
              acc.Active.push(val);
            }
            return acc;
          },
          { Archived: [], Active: [] },
        );
      }
      return { Archived: [], Active: [] };
    }, [truckRequestsResponse]);

  const dateFilteredTruckRequests = useMemo(() => {
    if (!filteredTruckRequests) return [];
    return filteredTruckRequests.filter((truckRequest) => {
      return moment(dateSelected).isBetween(
        moment(truckRequest.startDate),
        moment(truckRequest.endDate),
        'day',
        '[]',
      );
    });
  }, [dateSelected, filteredTruckRequests]);

  useEffect(() => {
    refetchTruckRequests();
  }, [timeframe]);

  const dateCounts = useMemo(() => {
    const startDate = moment(timeframe.startDate);
    const endDate = moment(timeframe.endDate);
    const newCounts = {};

    if (filteredTruckRequests) {
      for (let date = startDate; date <= endDate; date.add(1, 'day')) {
        const dateStr = date.format('YYYY-MM-DD');
        newCounts[dateStr] = filteredTruckRequests.filter((truckRequest) => {
          // check that dateStr is between the truck request's start and end dates
          return date.isBetween(
            moment(truckRequest.startDate),
            moment(truckRequest.endDate),
            'day',
            '[]',
          );
        }).length;
      }
    }
    return newCounts;
  }, [filteredTruckRequests, timeframe.startDate, timeframe.endDate]);

  // Updates range to fetch within when user scrolls past the default timeframe
  const onChangeWeekView = (start: Moment, end: Moment) => {
    // Set the selected date to the start of the week if selected date is not in the week
    if (!dateSelected.isBetween(start, end, 'day', '[]')) {
      setDateSelected(start);
    }

    // Make sure timeframe for fetch is updated if the user scrolls past the current timeframe
    const tempTimeframe = {
      startDate: moment(timeframe.startDate),
      endDate: moment(timeframe.endDate),
    };
    let needsToChange = false;
    if (start.isBefore(tempTimeframe.startDate, 'day')) {
      tempTimeframe.startDate = start;
      needsToChange = true;
    }
    if (end.isAfter(tempTimeframe.endDate, 'day')) {
      tempTimeframe.endDate = end;
      needsToChange = true;
    }
    if (needsToChange) {
      setTimeframe({
        startDate: tempTimeframe.startDate.format('YYYY-MM-DD'),
        endDate: tempTimeframe.endDate.format('YYYY-MM-DD'),
      });
    }
  };

  if (truckRequestsPending) {
    return (
      <Center w="$full" h="$full">
        <LoadingLogoAndSpinner />
      </Center>
    );
  }

  if (truckRequestsError) {
    return (
      <Center w="$full" h="$full" gap="$6">
        <Icon as={Icons.AlertCircle} color="$error500" w="$16" h="$16" />
        <Text size="lg" color="$error500">
          There was an error retrieving truck requests.
        </Text>
      </Center>
    );
  }

  return (
    <PageWrapper
      ToolbarContent={
        <HStack flex={1} justifyContent="space-between">
          <HStack space="xl"></HStack>

          <HStack space="xl">
            <Button
              size="lg"
              variant="ghost"
              onPress={() => setShowArchivedTicketsModal(true)}
            >
              <ButtonIcon as={Icons.Archive} />
              <ButtonText>Archived Tickets</ButtonText>
            </Button>

            <FilterButton
              data={truckRequests}
              setFilteredData={setFilteredTruckRequests}
              filtersData={filtersData}
            />
          </HStack>
        </HStack>
      }
    >
      <VStack w="$full" space="md" px="$5">
        <DateSelectStrip
          date={dateSelected}
          dateCounts={dateCounts}
          onDateSelected={setDateSelected}
          updateDateRange={onChangeWeekView}
        />
      </VStack>

      <VStack w="$full" flex={1} px="$5" pt="$4">
        <ScrollView
          horizontal
          flex={1}
          h="$full"
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'space-between',
          }}
          space="lg"
          pr="$3"
        >
          <EntityCardColumnsView
            data={dateFilteredTruckRequests}
            groupByPath={['job', 'number']}
            columnGroupings={generateColumnGroupings(dateFilteredTruckRequests)}
            CardComponent={({
              data,
            }: {
              data: Schemas['TruckingRequestResponse'];
            }) => (
              <TruckRequestCard
                truckRequest={data}
                onPress={() => setSelectedTruckRequest(data)}
              />
            )}
            columnContainerProps={{
              maxWidth: '$96',
            }}
          />
        </ScrollView>
      </VStack>

      {!!selectedTruckRequest && showTruckRequestFormModal && (
        <TruckRequestFormModal
          isOpen={showTruckRequestFormModal}
          onClose={() => {
            setShowArchivedTicketsModal(false);
            setSelectedTruckRequest(null);
            setShowTruckRequestFormModal(false);
          }}
          truckRequest={selectedTruckRequest}
          refetch={refetchTruckRequests}
        />
      )}

      {!selectedTruckRequest && showArchivedTicketsModal && (
        <ArchivedTruckRequestsModal
          isOpen={showArchivedTicketsModal}
          onClose={() => setShowArchivedTicketsModal(false)}
          onSelectTicket={(ticket) => {
            setSelectedTruckRequest(ticket);
            setShowTruckRequestFormModal(true);
          }}
          // TODO: Filter only archived once we have some
          truckRequests={archivedTruckRequests}
        />
      )}

      {!!selectedTruckRequest && !showTruckRequestFormModal && (
        <TruckRequestSummaryModal
          truckRequest={selectedTruckRequest}
          isOpen={true}
          onClose={() => setSelectedTruckRequest(null)}
          onPressEdit={() => setShowTruckRequestFormModal(true)}
          refetch={refetchTruckRequests}
        />
      )}
    </PageWrapper>
  );
};

const generateColumnGroupings = (
  requests: Schemas['TruckingRequestResponse'][],
) => {
  const groupings = requests
    .reduce((acc, req) => {
      if (!acc.includes(req.job.number)) {
        acc.push(req.job.number);
      }
      return acc;
    }, [])
    .map((jobNumber) => ({
      value: jobNumber,
      label: `Job ${jobNumber}`,
    }));
  console.log('groupings: ', groupings);
  return groupings;
};
