import {
  CircleIcon,
  Radio,
  RadioGroup,
  RadioIcon,
  RadioIndicator,
  RadioLabel,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { FormFieldData } from '../FormField.types.ts';
import { Pressable } from '@gluestack-ui/themed';

export interface RadioFormFieldData {
  options: Record<string, string | boolean>;
  radioContainerProps?: React.ComponentProps<typeof VStack>;
}

// TODO: BUG!! For some reason, changing a radio field reverts other fields to their initial state. Select does not do this, despite having identical onChange logic.
export const RadioFormField = ({
  label,
  options,
  onChange,
  readonly,
  size,
  value,
  radioContainerProps,
}: FormFieldData<'Radio'>) => {
  if (Array.isArray(options)) {
    options = Object.fromEntries(options.map((val) => [val, val]));
  }

  // This workaround is necessary because the Gluestack-UI Radio has some side-effect bug where it clears other fields on change.
  // Since we don't get access to the event itself, we can't modify or stop it. Instead we wrap with a Pressable and call the onChange handler manually.
  const createRadioPressHandler = (value) => (_e) => {
    if (!readonly) {
      onChange(value);
    }
  };

  return (
    <RadioGroup value={value} aria-label={label}>
      <VStack space="sm" {...radioContainerProps}>
        {Object.entries(options).map(([optionLabel, optionValue]) => (
          <Pressable
            key={optionLabel}
            onPress={createRadioPressHandler(optionValue)}
          >
            <Radio value={optionValue} size={size}>
              <RadioIndicator mr="$2">
                <RadioIcon as={CircleIcon} />
              </RadioIndicator>
              <RadioLabel size={size}>{optionLabel}</RadioLabel>
            </Radio>
          </Pressable>
        ))}
      </VStack>
    </RadioGroup>
  );
};
