import React from 'react';
import { TimeCardEntry } from './TimeCard.types.ts';
import {
  Card,
  ColorToken,
  hoursBetweenTimestamps,
  Icons,
  Text,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { HStack, Icon } from '@gluestack-ui/themed';
import { decimalHoursToTimeString } from '@tonic/central-specialties-utils';
import moment from 'moment';

interface TimeCardEntrySummaryCardProps
  extends React.ComponentProps<typeof Card> {
  timeCardEntry: TimeCardEntry;
  onPress: (timeCard: TimeCardEntry) => void;
  selected?: boolean;
}

export const timecardStatusIconMap = {
  Denied: {
    icon: Icons.XCircle,
    color: '$red500',
  },
  Approved: {
    icon: Icons.CheckCircle,
    color: '$green500',
  },
  Pending: {
    icon: Icons.CheckCircle,
    color: '$blue500',
  },
  Added: {
    icon: Icons.PlusSquare,
    color: '$orange500',
  },
  Missing: {
    icon: Icons.AlertCircle,
    color: '$orange500',
  },
  Submitted: {
    icon: Icons.CheckCircle,
    color: '$blue500',
  },
} satisfies Record<
  TimeCardEntry['status'],
  { icon: (typeof Icons)[keyof typeof Icons]; color: ColorToken }
>;

export const TimeCardEntrySummaryCard = ({
  timeCardEntry,
  onPress,
  selected = false,
  ...cardProps
}: TimeCardEntrySummaryCardProps) => {
  const IconData = timecardStatusIconMap[timeCardEntry.status] || {
    icon: Icons.MinusCircle, // Unknown status
    color: 'black',
  };
  return (
    <Card
      onPress={() => onPress(timeCardEntry)}
      $hover={{ bg: '$gray200' }}
      borderWidth={1}
      borderColor={selected ? '$black' : 'transparent'}
      hardShadow={selected ? 1 : 0}
      bg={selected ? '$gray200' : 'transparent'}
      hideChevron
      py="$3"
      px="$6"
      {...cardProps}
    >
      <HStack flex={1} gap="$4">
        <VStack flex={1} justifyContent="space-between">
          <VStack gap="$0.5">
            <HStack gap="$2">
              <Text bold size="lg" color="$textNormal">
                {decimalHoursToTimeString(
                  hoursBetweenTimestamps(
                    timeCardEntry.startTime,
                    timeCardEntry?.endTime || timeCardEntry.startTime,
                  ),
                  false,
                )}{' '}
                hours
              </Text>
              {timeCardEntry.hasMessages && (
                <Icon as={Icons.MessageCircle} color="$textSubtle" />
              )}
            </HStack>
            <Text>
              {moment(timeCardEntry.startTime)
                .utc(true)
                .tz('America/Chicago')
                .format('h:mma') + ' '}
              {timeCardEntry?.endTime &&
                `- ${moment(timeCardEntry.endTime).utc(true).tz('America/Chicago').format('h:mma')} `}
              CST
            </Text>
          </VStack>

          <VStack gap="$0.5">
            <HStack alignItems="center" gap="$2">
              <Text>Status: {timeCardEntry.status}</Text>
              <Icon as={IconData.icon} color={IconData.color} size="sm" />
            </HStack>
            <Text>
              Supervisor: {timeCardEntry.supervisor.fullName} -{' '}
              {timeCardEntry.supervisor.employeeNumber}
            </Text>
          </VStack>
        </VStack>
        <VStack gap="$0.5">
          <Text>Job number: {timeCardEntry.job?.number || 'unknown'}</Text>
          <Text>Labor code: {timeCardEntry.laborCode?.code || 'unknown'}</Text>
          <Text>
            Division code: {timeCardEntry.divisionCode?.code || 'unknown'}
          </Text>
          <Text>Cost code: {timeCardEntry.costCode?.code || 'unknown'}</Text>
          <Text>Repair?: {timeCardEntry.isRepair ? 'Yes' : 'No'}</Text>
        </VStack>
      </HStack>
    </Card>
  );
};
