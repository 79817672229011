import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonAsync,
  CircleIcon,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  HStack,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  RadioIcon,
  RadioIndicator,
  RadioLabel,
  Schemas,
  Text,
  useAlert,
  useTimeEntry,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { Heading } from '@gluestack-ui/themed';
import { X } from 'react-native-feather';

export interface SubmitDayModalProps {
  date: string;
  isOpen: boolean;
  employeeId: string;
  disabled?: boolean;
  onClose: () => void;
  onSuccess?: () => any;
  isRainDay?: boolean;
  job?: Schemas['JobResponse'];
  refetch?: () => void;
}

interface QuestionProp {
  question: string;
  answer: boolean;
}

export const SubmitDayModal = ({
  date,
  isOpen,
  disabled,
  onClose,
  onSuccess,
  job,
  isRainDay,
  employeeId,
  refetch,
}: React.PropsWithChildren<SubmitDayModalProps>) => {
  const { alert } = useAlert();
  const { submitTimeCardEntryByDay, loading } = useTimeEntry();
  const [questions, setQuestions] = useState<QuestionProp[]>([]);

  const handleSubmitDay = async () => {
    const convertedDate = new Date(date);
    const convertedQuestions = Object.fromEntries(
      questions.map((e) => [e.question, e.answer]),
    );
    try {
      if (!employeeId)
        throw new Error(
          'Invalid employeeId; Unable to submit questions and time card',
        );
      const submitDayPayload = {
        employeeId: employeeId,
        month: convertedDate.getMonth() + 1,
        day: convertedDate.getDate(),
        year: convertedDate.getFullYear(),
        questionsResponses: convertedQuestions,
      };
      // need to fire the payroll entry before submitting for the day
      onSuccess && (await onSuccess());
      await submitTimeCardEntryByDay(submitDayPayload);
      refetch && refetch();

      onClose();
    } catch (error) {
      console.error('There was an error submitting the time card', error);
      alert({
        status: 'error',
        message: error?.message || 'There was an error submitting this day',
        timeout: 10000,
      });
    }
  };

  const handleQuestionAnswer = useCallback(
    (index: number, value: string) => {
      const updatedQuestions = [...questions];
      updatedQuestions[index].answer = value === 'true';
      setQuestions(updatedQuestions);
    },
    [questions],
  );

  useEffect(() => {
    if (job && job.allQuestions) {
      const parsedQuestions = job.allQuestions
        .map((q) => {
          return { question: q, answer: false };
        })
        ?.filter((q) => q.question);
      setQuestions(parsedQuestions);
      return;
    } else {
      return;
    }
  }, [job, isRainDay]);

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      // Without this, the modal is rendered outside our context providers for some reason, so we can't access any app context
      useRNModal
    >
      <ModalBackdrop bg="$gray700" />
      <ModalContent borderRadius={0} minHeight={'$2/3'}>
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
        >
          <Box w="$8" />
          <Heading size="xl" color="$white">
            Additional Questions
          </Heading>
          <ModalCloseButton w="$8" justifyContent="center" alignItems="center">
            <X stroke="#FFF" width={20} height={20} />
          </ModalCloseButton>
        </ModalHeader>
        <ModalBody>
          <VStack flex={1}>
            <Text m={'$4'}>
              Please answer these questions before submitting your time for the
              day.
            </Text>
            {questions.map((q, index) => {
              return (
                <FormControl ml="$4" isRequired key={index}>
                  <FormControlLabel>
                    <FormControlLabelText>{q.question}</FormControlLabelText>
                  </FormControlLabel>
                  <RadioGroup
                    my="$2"
                    ml="$4"
                    onChange={(e) => {
                      handleQuestionAnswer(index, e);
                    }}
                    value={q.answer.toString()}
                    aria-label={`Additional question ${index + 1}`}
                  >
                    <HStack space="sm">
                      <Radio size="md" value={'true'}>
                        <RadioIndicator mr="$2">
                          <RadioIcon>
                            <CircleIcon />
                          </RadioIcon>
                        </RadioIndicator>
                        <RadioLabel>Yes</RadioLabel>
                      </Radio>
                      <Radio size="md" value={'false'}>
                        <RadioIndicator mr="$2">
                          <RadioIcon>
                            <CircleIcon />
                          </RadioIcon>
                        </RadioIndicator>
                        <RadioLabel>No</RadioLabel>
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
              );
            })}
          </VStack>
        </ModalBody>
        <ModalFooter bg="$white" justifyContent="center">
          <VStack
            w="$full"
            space="2xl"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              size="lg"
              w="$40"
              variant="link"
              top={'$4'}
              onPress={onClose}
            >
              <Text color="$primary700">Back</Text>
            </Button>
            <ButtonAsync
              size="lg"
              w="$40"
              variant="solid"
              onPress={handleSubmitDay}
              pending={loading}
              disabled={disabled}
            >
              <Text color="$white">Submit Day</Text>
            </ButtonAsync>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
