import {
  GestureResponderEvent,
  StyleProp,
  TextStyle,
  ViewStyle,
} from 'react-native';
import styles from './Calendar.style.js';
import { Duration, Moment } from 'moment/moment.js';
import { Icon, Pressable, Text } from '@gluestack-ui/themed';
import { Icons, Spinner, VStack } from '@tonic/central-specialties-ui-themed';

export const CustomCalendarStripDay = ({
  numUncompleted,
  loading,
  ...origDayComponentProps
}: CustomCalendarStripDayProps) => {
  const {
    date,
    enabled,
    key,
    selected,
    showDayName,
    onDateSelected,
    showDayNumber,
    upperCaseDays,
    dayContainerStyle = {},
    highlightDateContainerStyle,
  } = origDayComponentProps;

  const containerStyle = selected
    ? // @ts-expect-error - coppied from original repo, not going to waste time on this
      { ...dayContainerStyle, ...highlightDateContainerStyle }
    : dayContainerStyle;
  const isFutureDate = date.isAfter(new Date(), 'day');
  return (
    <Pressable
      mx="$2"
      p={0}
      h="$full"
      flex={1}
      // @ts-expect-error - moment types aren't working
      onPress={onDateSelected!.bind(this, date)}
      disabled={!enabled}
      key={key ?? date.toISOString()}
    >
      <VStack
        flex={1}
        h="$full"
        w="$full"
        py="$2"
        space="sm"
        style={[
          styles.dateContainer,
          // responsiveDateContainerStyle,
          // _dateViewStyle,
          containerStyle,
        ]}
      >
        {showDayName && (
          <Text size="xl" bold color={selected ? '$textNormal' : '$textSubtle'}>
            {upperCaseDays
              ? // @ts-expect-error - moment types aren't working properly
                date.format('ddd').toUpperCase()
              : // @ts-expect-error - moment types aren't working properly
                date.format('ddd')}
          </Text>
        )}
        {showDayNumber && (
          <Text size="xl" bold color={selected ? '$textNormal' : '$textSubtle'}>
            {/* @ts-expect-error - moment types aren't behaving */}
            {date.date()}
          </Text>
        )}
        {loading ? (
          <Spinner />
        ) : numUncompleted === 0 ? (
          <Icon
            size="xl"
            as={isFutureDate ? Icons.Minus : Icons.CheckCircle}
            color={isFutureDate ? '$textSubtle' : '$success500'}
          />
        ) : (
          <Text size="xl" color={selected ? '$textNormal' : '$textSubtle'}>
            {numUncompleted}
          </Text>
        )}
      </VStack>
    </Pressable>
  );
};

export interface IDayComponentProps {
  date: Duration;
  marking?: any;
  selected?: boolean;
  enabled: boolean;
  showDayName?: boolean;
  showDayNumber?: boolean;
  onDateSelected?: (event: GestureResponderEvent) => void;
  calendarColor?: string;
  dateNameStyle?: string;
  dateNumberStyle?: string;
  dayContainerStyle?: StyleProp<ViewStyle>;
  weekendDateNameStyle?: TextStyle;
  weekendDateNumberStyle?: TextStyle;
  highlightDateContainerStyle?: StyleProp<ViewStyle>;
  highlightDateNameStyle?: TextStyle;
  highlightDateNumberStyle?: TextStyle;
  disabledDateNameStyle?: TextStyle;
  disabledDateNumberStyle?: TextStyle;
  styleWeekend?: boolean;
  daySelectionAnimation?: TDaySelectionAnimation;
  customStyle?: ViewStyle;
  size: number;
  allowDayTextScaling?: boolean;
  markedDatesStyle?: TextStyle;
  markedDates?: any[] | ((date: Moment) => void);
  upperCaseDays?: boolean;
  key?: string;
}

interface CustomCalendarStripDayProps extends IDayComponentProps {
  numUncompleted: number;
  loading?: boolean;
}

type TDaySelectionAnimation = unknown;
// | IDaySelectionAnimationBorder
// | IDaySelectionAnimationBackground;
