import React from 'react';
import {
  Box,
  Icons,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from '@tonic/central-specialties-ui-themed';
import { Heading, Icon } from '@gluestack-ui/themed';

export interface SimpleRedModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

export const SimpleRedModal = ({
  title,
  isOpen,
  onClose,
  children,
}: React.PropsWithChildren<SimpleRedModalProps>) => (
  <Modal
    size="lg"
    isOpen={isOpen}
    onClose={onClose}
    // Without this, the modal is rendered outside our context providers for some reason, so we can't access any app context
    useRNModal
  >
    <ModalBackdrop bg="$gray700" />
    <ModalContent borderRadius={0} bg="$gray100">
      <ModalHeader
        bg="$primary700"
        alignItems="center"
        justifyContent="space-between"
        py="$2"
      >
        <Box w="$8" />
        <Heading size="xl" color="$white">
          {title}
        </Heading>
        <ModalCloseButton w="$8" justifyContent="center" alignItems="center">
          <Icon color="$white" size="xl" as={Icons.X} />
        </ModalCloseButton>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </ModalContent>
  </Modal>
);
