import { HStack } from '@gluestack-ui/themed';
import { FormFieldData, FormFieldType } from './FormField.types.ts';
import {
  CheckboxFormField,
  CheckboxGroupFormField,
  CustomStringsFormField,
  RadioFormField,
  SelectFormField,
  TextFormField,
  TimeframeFormField,
  AddressFormField,
  ImagesFormField,
  PartsFormField,
  PartsUpdatesFormField,
  TrucksFormField,
  TruckUpdatesFormField,
  DateRangeFormField,
  MultiSelectFormField,
} from './specific';
import { Spinner } from '@tonic/central-specialties-ui-themed';

export function FormField<T extends FormFieldType>({
  ...fieldProps
}: FormFieldData<T>) {
  const { type } = fieldProps;

  if (fieldProps?.pending === true) {
    return (
      <HStack w="$full" py="$2">
        <Spinner />
      </HStack>
    );
  }

  switch (type) {
    case 'Text':
      return <TextFormField {...(fieldProps as FormFieldData<'Text'>)} />;
    case 'Checkbox':
      return (
        <CheckboxFormField {...(fieldProps as FormFieldData<'Checkbox'>)} />
      );
    case 'CheckboxGroup':
      return (
        <CheckboxGroupFormField
          {...(fieldProps as FormFieldData<'CheckboxGroup'>)}
        />
      );
    case 'Select':
      return <SelectFormField {...(fieldProps as FormFieldData<'Select'>)} />;
    case 'MultiSelect':
      return (
        <MultiSelectFormField
          {...(fieldProps as FormFieldData<'MultiSelect'>)}
        />
      );
    case 'Radio':
      return <RadioFormField {...(fieldProps as FormFieldData<'Radio'>)} />;
    case 'Timeframe':
      return (
        <TimeframeFormField {...(fieldProps as FormFieldData<'Timeframe'>)} />
      );
    case 'DateRange':
      return (
        <DateRangeFormField {...(fieldProps as FormFieldData<'DateRange'>)} />
      );
    case 'Address':
      return <AddressFormField {...(fieldProps as FormFieldData<'Address'>)} />;
    case 'CustomStrings':
      return (
        <CustomStringsFormField
          {...(fieldProps as FormFieldData<'CustomStrings'>)}
        />
      );
    case 'Images':
      return <ImagesFormField {...(fieldProps as FormFieldData<'Images'>)} />;
    case 'PartRequests':
      return (
        <PartsFormField {...(fieldProps as FormFieldData<'PartRequests'>)} />
      );
    case 'PartsUpdates':
      return (
        <PartsUpdatesFormField
          {...(fieldProps as FormFieldData<'PartsUpdates'>)}
        />
      );
    case 'TruckRequests':
      return (
        <TrucksFormField {...(fieldProps as FormFieldData<'TruckRequests'>)} />
      );
    case 'TruckUpdates':
      return (
        <TruckUpdatesFormField
          {...(fieldProps as FormFieldData<'TruckUpdates'>)}
        />
      );
    case 'SectionLabel':
      return null;
    default:
      console.error(
        'FormField of type ' + type + ' has not been implemented yet',
      );
      return null;
  }
}
