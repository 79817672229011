import { useState } from 'react';
import { PageWrapper } from '../../../components/general/PageWrapper.tsx';
import {
  Box,
  ButtonAsync,
  Card,
  Center,
  Divider,
  Icons,
  useAlert,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { EntityCardColumn } from '../../../components/general/EntityCardExplorer/EntityCardColumnsView/EntityCardColumn/EntityCardColumn.tsx';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import {
  AlertDialog,
  AlertDialogBackdrop,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  Button,
  ButtonIcon,
  ButtonText,
  HStack,
  ScrollView,
  Text,
} from '@gluestack-ui/themed';
import { EquipmentNumberForm } from '../../../components/general/Form/specific/EquipmentNumberForm/EquipmentNumberForm.tsx';
import { NewEquipmentNumberModal } from '../../../components/general/Form/specific/EquipmentNumberForm/NewEquipmentNumberModal.tsx';
import { ImportNewEquipmentModal } from '../../../components/general/Form/specific/EquipmentNumberForm/ImportNewEquipmentModal.tsx';
import { JobNumberForm } from '../../../components/general/Form/specific/JobNumberForm/JobNumberForm.tsx';
import { NewJobNumberFormModal } from '../../../components/general/Form/specific/JobNumberForm/NewJobNumberFormModal.tsx';
import { ArchivedJobsModal } from './ArchivedJobsModal/ArchivedJobsModal.tsx';
import { ArchiveJobNumbersDialog } from './ArchivedJobsModal/ArchivedJobNumbersDialog.tsx';

export type JobModel = Schemas['JobModel'];
export type EquipmentModel = Schemas['EquipmentModel'];

interface ManagePageProps {}

const MODALS = {
  NONE: null,
  NEW_EQUIPMENT: 'NEW_EQUIPMENT',
  NEW_JOB: 'NEW_JOB',
  IMPORT_EQUIPMENT: 'IMPORT_EQUIPMENT',
  ARCHIVED_TICKETS: 'ARCHIVED_TICKETS',
};

export const ManagePage = (_props: ManagePageProps) => {
  const [focusedItem, setFocusedItem] = useState<
    EquipmentModel | JobModel | null
  >(null);
  const [activeModal, setActiveModal] = useState<string | null>(MODALS.NONE);

  const showNewEquipmentNumberModal = () =>
    setActiveModal(MODALS.NEW_EQUIPMENT);
  const showNewJobNumberModal = () => setActiveModal(MODALS.NEW_JOB);
  const showImportNewEquipmentModal = () =>
    setActiveModal(MODALS.IMPORT_EQUIPMENT);
  const showArchivedTicketsModal = () =>
    setActiveModal(MODALS.ARCHIVED_TICKETS);
  const closeAllModals = () => setActiveModal(MODALS.NONE);

  const {
    data: jobs,
    isLoading: isLoadingJobs,
    refetch: refetchJobs,
  } = useQuery({
    queryKey: ['jobs'],
    queryFn: async () =>
      await DB.GET('/jobs').then(
        (res: { data: Schemas['JobResponse'][] }) => res.data,
      ),
  });

  const {
    data: equipment,
    isLoading: isLoadingEquipment,
    refetch: refetchEquipment,
  } = useQuery({
    queryKey: ['equipment'],
    queryFn: async () =>
      await DB.GET('/equipment').then(
        (res: { data: Schemas['EquipmentListResponse'] }) => res.data.equipment,
      ),
  });

  const {
    data: archivedJobsRequests,
    isLoading: isArchivedJobsLoading,
    refetch: refetchArchivedJobs,
  } = useQuery({
    queryKey: ['archivedJobsRequests'],
    queryFn: async () =>
      await DB.GET('/jobs/archived').then((res) => {
        return res.data;
      }),
  });

  return (
    <PageWrapper
      ToolbarContent={
        <HStack
          w="$full"
          space="lg"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button size="lg" variant="ghost" onPress={showArchivedTicketsModal}>
            <ButtonIcon as={Icons.Archive} />
            <ButtonText>Archived Tickets</ButtonText>
          </Button>
          <Button size="lg" onPress={showNewEquipmentNumberModal} gap="$2">
            <ButtonIcon as={Icons.Plus} />
            <ButtonText>New Equipment Number</ButtonText>
          </Button>
          <Button size="lg" onPress={showNewJobNumberModal}>
            <ButtonIcon as={Icons.Plus} />
            <ButtonText>New Job Number</ButtonText>
          </Button>
          <Button size="lg" onPress={showImportNewEquipmentModal} gap="$2">
            <ButtonIcon as={Icons.Upload} />
            <ButtonText>Import Equipment Data</ButtonText>
          </Button>
        </HStack>
      }
    >
      <HStack h="$full" w="$full" pt="$4" pl="$4" space="lg">
        <EntityCardColumn
          items={jobs}
          pending={isLoadingJobs}
          categoryName="Job Numbers"
          searchByPaths={['address', 'description', 'number']}
          CardComponent={({ data }: { data: JobModel }) => (
            <ItemNumberCard
              number={data.number}
              description={data.description}
              onPress={() => setFocusedItem(data)}
              minWidth="$48"
              isSelected={focusedItem?.id === data.id}
            />
          )}
          containerProps={{
            maxWidth: '$80',
            minWidth: '$56',
          }}
        />
        <Divider subtle orientation="vertical" />
        <EntityCardColumn
          items={equipment}
          pending={isLoadingEquipment}
          categoryName="Equipment Numbers"
          searchByPaths={['number', 'description', 'notes']}
          CardComponent={({ data }: { data: EquipmentModel }) => (
            <ItemNumberCard
              number={data.number}
              description={data.description}
              onPress={() => setFocusedItem(data)}
              minWidth="$48"
              isSelected={focusedItem?.id === data.id}
            />
          )}
          containerProps={{
            maxWidth: '$80',
            minWidth: '$56',
          }}
        />
        <HStack h="$full" flex={focusedItem ? 2 : 1}>
          <Divider subtle orientation="vertical" />
          <VStack h="$full" space="lg" w="$full">
            <ViewItemDetails
              key={focusedItem?.id || 'empty'}
              item={focusedItem}
              refetchJobs={() => {
                refetchJobs();
                refetchArchivedJobs();
              }}
              refetchEquipment={refetchEquipment}
              setFocusedItem={setFocusedItem}
            />
          </VStack>
        </HStack>
      </HStack>

      {/* New Entitity Modals */}
      <NewEquipmentNumberModal
        isOpen={activeModal === MODALS.NEW_EQUIPMENT}
        onClose={closeAllModals}
        onSuccess={refetchEquipment}
      />
      <NewJobNumberFormModal
        isOpen={activeModal === MODALS.NEW_JOB}
        onClose={closeAllModals}
        onSuccess={refetchJobs}
      />
      <ImportNewEquipmentModal
        isOpen={activeModal === MODALS.IMPORT_EQUIPMENT}
        onClose={closeAllModals}
        onSuccess={refetchEquipment}
      />
      {!isArchivedJobsLoading && (
        <ArchivedJobsModal
          isOpen={activeModal === MODALS.ARCHIVED_TICKETS}
          onClose={closeAllModals}
          jobRequests={archivedJobsRequests}
          refetchJobs={refetchJobs}
          refetchArchivedJobs={refetchArchivedJobs}
        />
      )}
    </PageWrapper>
  );
};

export interface ItemNumberCardProps {
  number: string | number;
  description: string;
  onPress: () => void;
  isSelected?: boolean;
}

export const ItemNumberCard = ({
  number,
  description,
  onPress,
  isSelected,
}: ItemNumberCardProps) => {
  return (
    <Card
      onPress={onPress}
      hardShadow="1"
      borderWidth={2}
      borderColor={isSelected ? '$primary600' : 'transparent'}
    >
      <VStack gap="$0.5" flex={1}>
        <Text bold>{number}</Text>
        <Text>{description}</Text>
      </VStack>
    </Card>
  );
};

const ViewItemDetails = ({
  item,
  refetchJobs,
  refetchEquipment,
  setFocusedItem,
}: {
  item: JobModel | EquipmentModel | null;
  refetchJobs?: () => void;
  refetchEquipment?: () => void;
  setFocusedItem?: React.Dispatch<
    React.SetStateAction<EquipmentModel | JobModel | null>
  >;
}) => {
  const [showDeleteEquipmentDialog, setShowDeleteEquipmentDialog] =
    useState(false);
  const [showArchiveJobNumberDialog, setShowArchiveJobNumberDialog] =
    useState(false);

  if (item === null) {
    return (
      <Center flex={1}>
        <Text size="lg">Select code to view</Text>
      </Center>
    );
  }

  const isJob = 'address' in item;

  return (
    <VStack space="lg" h="$full">
      <Center h="$16" bg="$primary700">
        <HStack space="xl" alignItems="center" justifyContent="space-between">
          <Text bold size="xl" color="$white">
            {`${isJob ? 'Job' : 'Equipment'} #${item.code || item.number}`}
          </Text>
          <Button
            size="lg"
            variant="ghost"
            onPress={() =>
              isJob
                ? setShowArchiveJobNumberDialog(true)
                : setShowDeleteEquipmentDialog(true)
            }
            minWidth={'$8'}
          >
            <ButtonIcon
              color="$white"
              as={isJob ? Icons.Archive : Icons.Delete}
              size="xl"
            />
          </Button>
        </HStack>
      </Center>
      <Box flex={1} w="$full" h="$full" px="$12">
        <ScrollView py="$10" px="$0.5" h="$full">
          {isJob ? (
            <JobNumberForm partialJobNumber={item} onSuccess={refetchJobs} />
          ) : (
            <EquipmentNumberForm
              equipmentNumberData={item}
              onSuccess={refetchEquipment}
            />
          )}
        </ScrollView>
      </Box>
      {showDeleteEquipmentDialog && !isJob && (
        <DeleteEquipmentDialog
          equipmentId={item?.id}
          isOpen={true}
          onClose={() => setShowDeleteEquipmentDialog(false)}
          onSuccess={() => {
            refetchEquipment && refetchEquipment();
            setShowDeleteEquipmentDialog(false);
            setFocusedItem && setFocusedItem(null);
          }}
        />
      )}
      {showArchiveJobNumberDialog && isJob && (
        <ArchiveJobNumbersDialog
          jobNumber={item?.id}
          isOpen={true}
          onClose={() => setShowArchiveJobNumberDialog(false)}
          onSuccess={() => {
            refetchJobs && refetchJobs();
            setShowArchiveJobNumberDialog(false);
            setFocusedItem && setFocusedItem(null);
          }}
          toggleArchive={true}
        />
      )}
    </VStack>
  );
};

interface DeleteEquipmentDialogProps {
  isOpen: boolean;
  onClose: () => void;
  equipmentId: string;
  onSuccess?: () => void;
}

const DeleteEquipmentDialog = ({
  isOpen,
  onClose,
  equipmentId,
  onSuccess,
}: DeleteEquipmentDialogProps) => {
  const { alert } = useAlert();

  const {
    mutateAsync: deleteEquipmentRequest,
    isPending: deleteEquipmentRequestPending,
  } = useMutation({
    mutationFn: (equipmentId: string) =>
      DB.DELETE(`/equipment/${equipmentId}`).then(({ response }) => {
        if (response.ok) {
          alert({
            status: 'success',
            message: 'Success! Equipment Delete',
            dismissible: true,
            timeout: 5000,
          });
          onSuccess && onSuccess();
        } else {
          alert({
            status: 'error',
            message: 'Something went wrong, could not delete this ticket',
            timeout: 10000,
          });
        }
        onClose();
      }),
  });

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} size="sm">
      <AlertDialogBackdrop />
      <AlertDialogContent py="$6" gap="$8">
        <AlertDialogBody>
          <Center w="$full">
            <Box w="$64">
              <Text bold size="lg" textAlign="center">
                Are you sure you want to delete this equipment number?
              </Text>
            </Box>
          </Center>
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="space-evenly" gap="$4">
          <Button size="lg" variant="outline" onPress={onClose} w={150}>
            <ButtonText>Cancel</ButtonText>
          </Button>
          <ButtonAsync
            size="lg"
            pending={deleteEquipmentRequestPending}
            onPress={() => deleteEquipmentRequest(equipmentId)}
            w={150}
          >
            <ButtonText>Delete</ButtonText>
          </ButtonAsync>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
