import React, { useMemo } from 'react';
import { Schemas } from '@tonic/central-specialties-utils';
import {
  ButtonAsync,
  Center,
  Icons,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@tonic/central-specialties-ui-themed';
import { Button, ButtonText, HStack, Icon, Text } from '@gluestack-ui/themed';
import { TruckRequestForm } from '../TruckRequestForm/TruckRequestForm.tsx';

interface TruckRequestFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  truckRequest?: Schemas['TruckingRequestResponse'];
  refetch?: () => void;
}

export const TruckRequestFormModal = ({
  isOpen,
  onClose,
  truckRequest,
  refetch,
}: TruckRequestFormModalProps) => {
  const mode = useMemo<'create' | 'edit' | 'archive'>(() => {
    switch (true) {
      case truckRequest && truckRequest.status === 'Archived':
        return 'archive';
      case truckRequest && 'status' in truckRequest:
        return 'edit';
      default:
        return 'create';
    }
  }, [truckRequest]);

  const secondaryButtonText = useMemo(() => {
    switch (mode) {
      case 'archive':
        return 'Back';
      default:
        return 'Cancel';
    }
  }, [mode]);

  const primaryButtonText = useMemo(() => {
    switch (mode) {
      case 'create':
        return 'Create Ticket';
      case 'archive':
        return 'Unarchive';
      default:
        return 'Save Changes';
    }
  }, [mode]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBackdrop />
      <ModalContent
        borderRadius={0}
        maxHeight="80%"
        // w="$96"
        maxWidth={1000}
        minWidth={700}
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
          pl="$10"
          pr="$6"
        >
          <Text color="$white" size="lg">
            {mode === 'create'
              ? 'New Trucking Request'
              : `Edit Trucking Request - ${truckRequest?.requestNumber || '(number pending)'}`}
          </Text>
          <ModalCloseButton w="$8" justifyContent="center" alignItems="center">
            <Icon color="$white" size="xl" as={Icons.X} />
          </ModalCloseButton>
        </ModalHeader>
        <TruckRequestForm
          truckRequestId={truckRequest?.id}
          isAdminMode
          isReadonlyMode={truckRequest && truckRequest.status === 'Archived'}
          onSuccess={() => {
            refetch && refetch();
            onClose();
          }}
          formProps={{
            Container: ({ children }) => (
              <ModalBody w="$full">
                <Center w="$full" flex={1} px="$12" pt="$10">
                  <HStack w="$full" minWidth={600} space="xl">
                    {children}
                  </HStack>
                </Center>
              </ModalBody>
            ),
            SubmitButton: ({ submit, pending, disabled }) => (
              <ModalFooter bg="$white" hardShadow="2">
                <HStack w="$full" space="2xl" justifyContent="flex-end">
                  <Button size="lg" w="$32" variant="outline" onPress={onClose}>
                    <ButtonText>{secondaryButtonText}</ButtonText>
                  </Button>
                  <ButtonAsync
                    size="lg"
                    w="$32"
                    variant="solid"
                    onPress={submit}
                    pending={pending}
                    isDisabled={disabled}
                  >
                    <ButtonText>{primaryButtonText}</ButtonText>
                  </ButtonAsync>
                </HStack>
              </ModalFooter>
            ),
          }}
        />
      </ModalContent>
    </Modal>
  );
};
