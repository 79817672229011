import {
  Center,
  ChangePasswordPrompt,
  useAlert,
  useUser,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { BoxWithHeader } from '../../../components/general/BoxWithHeader';
import { InfoItemProps } from '../../../components/general/InfoList/InfoItem.tsx';
import { InfoList } from '../../../components/general/InfoList/InfoList.tsx';
import { useState } from 'react';
import { SimpleRedModal } from '../../../components/general/SimpleRedModal.tsx';

export const ProfilePage = () => {
  const { alert } = useAlert();
  const user = useUser();
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  if (!user) {
    return null;
  }

  const infoItems: InfoItemProps[] = [
    {
      label: 'Employee ID',
      value: user.employeeNumber,
    },
    {
      label: 'Email',
      value: user.email,
    },
    {
      label: 'Phone',
      value: user.phoneNumber,
    },
    {
      label: 'Password',
      value: '********',
      onEditClick: () => {
        setChangePasswordModalOpen(true);
      },
    },
  ];

  return (
    <VStack h="$full" w="$full">
      <Center w="$full" flex={1}>
        <BoxWithHeader
          title={user?.fullName || `${user.firstName} ${user.lastName}`}
        >
          <InfoList items={infoItems} mx="$24" my="$12" />
        </BoxWithHeader>
        <SimpleRedModal
          isOpen={changePasswordModalOpen}
          title="Reset Your Password"
          onClose={() => setChangePasswordModalOpen(false)}
        >
          <Center my="$16">
            <ChangePasswordPrompt
              onSuccess={() => {
                setChangePasswordModalOpen(false);
                alert({
                  status: 'success',
                  message: 'Success! Password changed.',
                  timeout: 5000,
                });
              }}
            />
          </Center>
        </SimpleRedModal>
      </Center>
    </VStack>
  );
};
