import React from 'react';
import {
  useAlert,
  Alert,
  AlertIcon,
  AlertText,
  IconButton,
  Icons,
} from '@tonic/central-specialties-ui-themed';

export const AlertWrapper = ({ children }: React.PropsWithChildren) => {
  const { activeAlert, closeAlert } = useAlert();

  const getAlertIcon = () => {
    if (activeAlert?.icon) {
      return typeof activeAlert.icon === 'string'
        ? Icons[activeAlert.icon]
        : activeAlert.icon;
    }
    switch (activeAlert?.status) {
      case 'error':
        return Icons.XCircle;
      case 'success':
        return Icons.CheckCircle;
      case 'warning':
        return Icons.AlertTriangle;
      default:
        return Icons.Info;
    }
  };

  return (
    <>
      {children}
      {!!activeAlert && (
        <Alert
          action={activeAlert.status}
          position={activeAlert?.noOverlay ? 'relative' : 'absolute'}
          variant="outline"
          sx={{
            p: '$4',
            gap: '$2',
            top: 0,
            w: '$full',
            zIndex: 99999,
          }}
        >
          <AlertIcon as={getAlertIcon()} />
          <AlertText>{activeAlert.message}</AlertText>
          {!(activeAlert?.dismissible === false) && (
            <IconButton onPress={closeAlert}>
              <AlertIcon as={Icons.X} color="$black" />
            </IconButton>
          )}
        </Alert>
      )}
    </>
  );
};
